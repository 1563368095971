.home-container {
    background-image: url('../../images/HomePage/header-background-image.png');
    background-color: #11076B;
    background-size: cover;
}

.header-section {
    margin-top: 15%;
    margin-left: 15px;
    padding: 0px 20px 0px 0px;
}

.header-sub-title h1 {
    font-size: 35px;
    color: white;
    font-style: normal;
    margin-bottom: 40px;
}

.header-sub-title p {
    color: white;
    font-size: 16px;
    margin: 10px 0px 100px;
}

/* FEATURES SECTION */
.features-container {
    background-color: rgba(#11076b, 0);
}

.features-section {
    background-image: linear-gradient(to top, rgba(33, 122, 255, 0.2), rgba(28, 184, 247, 0.5));
    color: white;
    padding: 40px 0px 40px;
}

.features-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.features-section h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.features-section .images-row ul {
    margin: 0;
    padding: 0;
}

.features-section .images-row li {
    width: 556px;
    height: 259px;
    list-style: none;
    float: left;
    position: relative;
    display: flex;
}

.feature-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.features-contents {
    margin-right: 10px;
}

.features-contents ul>li {
    margin: 0px 40px 20px 40px;
    font-size: 14px;
}

.features-interior {
    display: flex;
}

.center-aligned-button {
    display: flex;
    justify-content: center;
}

.apply-now {
    background: transparent;
    color: #81b7ff;
    font-size: 16px;
    border: 1px solid #81b7ff;
    width: 160px;
    height: 35px;
    margin-top: 40px;
}

/* INFO SECTION */
.info-container {
    background-color: #0B0347;
}

.info-section {
    color: white;
    flex-direction: column;
    padding: 40px 0px 40px;
}

.info-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.info-container h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.info-section .images-row {
    display: flex;
}

.info-section .column {
    text-align: center;
    margin: 0;
    padding: 30px 30px 0px 30px;
}

.info-section .images-row .image-block {
    width: 151px;
    height: 151px;
    float: left;
}

.info-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.info-section p {
    display: inline-block;
    text-align: left;
    font-size: 14px;
}

/* OPPORTUNITY SECTION */
.opportunity-container {
    background-color: #11076B;
}

.opportunity-section {
    background-image: linear-gradient(to top, rgba(33, 122, 255, 0.2), rgba(28, 184, 247, 0.5));
    color: white;
    padding: 40px 0px 40px;
}

.opportunity-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.opportunity-section h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.opportunity-section .images-row {
    display: flex;
}

.opportunity-section .column {
    text-align: center;
    margin: 0;
    padding: 0;
}

.opportunity-section .images-row .image-block {
    width: 556px;
    height: 259px;
    float: left;
}

.opportunity-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.opportunity-contents h2 {
    margin-left: 40px;
    font-size: 24px;
}

.opportunity-contents ul>li {
    padding-bottom: 20px;
    margin: 0px 40px 0px 40px;
    font-size: 14px;
}

.features-interior {
    display: flex;
}

/* TIMELINE SECTION */
.timeline-container {
    width: 100%;
    background-color: #0B0347;
    background-image: url('../../images/HomePage/timeline-background-copy.png');
    color: white;
    height: 600px;
    width: 100%;
    background-size: cover;
    padding-top: 40px;
}

.timeline-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.timeline-section h1 {
    text-align: center;
    font-size: 35px;
}

.timeline-section .images-row {
    margin-top: 100px;
    display: flex;
}

.timeline-section .image-block {
    height: 340px;
    float: left;
}

.timeline-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
}

/* TEAM SECTION */
.team-container {
    background-color: #0B0347;
    color: white;
}

/* PARTNERS SECTION */
.partners-container {
    background-color: #11076B;
    background-image: linear-gradient(to top, rgba(33, 122, 255, 0.4), rgba(17, 7, 107, 1));
    padding: 40px 0px 40px;
}

.partners-section {
    color: white;
}

.partners-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right
    position when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.partners-container h1 {
    padding-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.partners-section .images-row {
    display: flex;
}

.partners-section .column {
    text-align: center;
    margin: 0;
    padding: 0;
}

.partners-section .images-row .image-block {
    width: 144px;
    height: 144px;
    float: left;
}

.partners-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.partners-section p {
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
}

/* SIDENAV SECTION */
.sidenav-container-homepage {
    z-index: 100000;
    display: table;
    right: 0px;
    position: fixed;
    font-weight: normal;
    margin-right: 5px;
    top: 50%;
}

.sidenav-container-homepage .sidenav-section {
    transition-duration: 0.3s;
    display: table-cell;
    vertical-align: middle;
    margin: 0px;
    color: white;
    font-size: 10pt;
    text-align: right;
}

.sidenav-container-homepage .sidenav-icon {
    margin: 0px 0px 0px 10px;
    background: white;
    display: inline-block;
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

.sidenav-container-homepage .sidenav-text {
    font-size: 9pt;
}

.sidenav-container-homepage .navigation-button {
    margin-bottom: 5px;
}

.sidenav-container-homepage .navigation-button:hover {
    transition: color 1.5s visibility 1s;
    background: #81b7ff;
    cursor: default;
    color: black;
}
