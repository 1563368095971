.suggestion-item {
  border-bottom: 2px solid #3e88c5;
  margin-top: 30px;
  padding-bottom: 20px;
  width: 100%;
}

.suggestion-sub-info {
  margin-left: 70%;
}

.back-news-items {
  text-align: center;
  width: 4rem;
  color: white;
  background: red;
  margin-left: 90%;
  border: 1px solid red;
}

.suggestion-details {
  border: 1px solid black;
  font-size: 20px;
}

.delete-suggestion {
  font-size: 30px;
}

.delete-suggestion:hover {
  color: red;
}
