.token-templates-container {
  margin: 0;
  padding: 0;
  /* background: rgba(0, 0, 0, 0.3); */
  margin-bottom: 40px;
}

.token-templates-header {
  justify-content: center;
  /* font-weight: bold; */
  font-size: 22px;
}

.token-templates-section {
  width: 100%;
}

.individual-token-template {
  align-items: center;
  padding: 0;
  margin: 0;
  padding: 5px;
}

.individual-token-template:nth-child(odd) {
  background: rgba(0, 0, 0, 0.2);
}

.token-template-edit-form {
  padding: 20px;
}

.token-template-asset-type {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.token-template-render-section {
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
  margin: 0;
  padding: 20px 0;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.token-template-render-section-header {
  margin-left: 15px;
  margin-bottom: 15px;
  font-weight: bold;
}

.token-template-individual-field {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  padding: 10px;
}

.token-template-individual-field:not(:last-child) {
  /* margin-right: 10px; */
}

.token-template-individual-field:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.create-new-template-button-row {
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.template-individual-field-column {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.template-individual-field-column:not(:last-child) {
  margin-right: 5px;
}

.template-add-dropdown-row {
  margin: 0;
  padding: 0;
  width: 100%;
}

.template-dropdown-option {
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.template-dropdown-option:not(:last-child) {
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.template-dropdown-option .form-control {
  width: 90%;
}

.token-template-section-buttons-row {
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  padding: 10px;
}

.token-template-add-section-row {
  justify-content: center;
}

.token-edit-icon {
  margin-right: 10px;
}

.token-edit-icon:hover {
  cursor: pointer;
  color: #1586eb;
}

.token-delete-icon:hover {
  cursor: pointer;
  color: fireBrick;
}

.template-form-label-row {
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  width: 100%;
}

.token-template-delete-section-button {
  background: fireBrick;
  border: 1px solid fireBrick;
  margin-left: 5px;
}

.token-template-delete-section-button:hover {
  background: darkRed;
  border: 1px solid darkRed;
}

.template-optional-icon:hover {
  cursor: pointer;
  color: #1586eb;
}

.template-optional-icon-delete:hover {
  cursor: pointer;
  color: fireBrick;
}

.token-template-button-row {
  padding: 0;
  margin: 0;
  justify-content: flex-end;

}

.token-template-section-name-row {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.template-individual-field-delete-column {
  align-items: center;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  display: flex;
}

.template-optional-field-column {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.back-to-templates {
  width: max-content;
  padding: 0 20px;
}

.back-to-templates:hover {
  color: #1586eb;
  cursor: pointer;
  text-decoration: underline;
}

.template-currently-saving {
  padding: 0 20px;
  font-style: italic;
  color: #1586eb;
  align-items: center;
}

.template-currently-saving-spinner {
  margin-right: 5px;
}

.token-templates-section-header {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  font-weight: bold;
}

.token-templates-pagination-row {
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.token-templates-delete-modal {
  margin-top: -93px;
  background: rgba(0, 0, 0, 0.3);
}

.token-templates-delete-modal .modal-content {
  padding: 10px;
}

.tt-delete-modal-button-row {
  justify-content: center;
  margin-top: 10px;
}

.ap-confirm-delete-button {
  margin-right: 10px;
  background: fireBrick;
  border: 1px solid fireBrick;
}

.ap-confirm-delete-button:hover {
  background: darkRed;
  border: 1px solid darkRed;
}

.tt-delete-modal-text-row {
  justify-content: center;
}

.individual-template-param#published {
  color: green;
}

.individual-template-param#not-published {
  color: red;
}

.token-template-create-row {
  justify-content: flex-end;
  margin-top: 18px;
  align-items: center;
}

.token-template-create-row .individual-form-icon {
  margin-left: 8px;
}

.token-template-create-row button {
  margin-left: 8px;
}

.configure-preview-row {
  /* justify-content: center; */
  margin-top: 8px;
}

.configure-preview-row .form-check:not(:last-child) {
  margin-bottom: 8px;
}
