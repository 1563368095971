.contributor-profile-container {
  margin: 0;
  padding: 0;
  padding-top: 93px;
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
  max-width: 100%;
  height: 100%;
  position: relative;
}

.cp-profile-container-column {
  /* background-color: #073966; */
  background: transparent;
}

.cp-user-banner {
  background: #073966;
  border-radius: 0;
  height: 240px;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  position: relative;
}

.cp-change-banner-image-edit-icon {
  position: absolute;
  top: 5px;
  border-radius: 100px;
  right: 5px;
  color: #0c5ebe;
  background: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  padding: 2px;
  z-index: 100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cp-change-banner-image-edit-icon:hover {
  color: white;
  background: #0c5ebe;
  cursor: pointer;
}


.cp-user-banner-column {
  position: absolute;
  top: 0;
  left: 0;
}

.cp-user-banner-info-row {
  color: white;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.cp-user-banner-info-name-row {
  background: rgba(0, 0, 0, 0.4);
  /* bottom: 10px; */
  /* position: absolute; */
  width: 100%;
  padding: 5px 10px 5px 60px;
  align-items: center;
  z-index: 99;
}

.cp-user-banner-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cp-user-banner-image-uploader .fileContainer {
  border-radius: 0;
  background: #042038;
  height: 240px;
  color: white;
  justify-content: center;
  vertical-align: top;
  display: flex;
  padding: 0;
  margin: 0;
  z-index: 90;
}

.cp-user-banner-image-uploader .chooseFileButton {
  background: transparent;
  border: 1px solid white;
  margin-bottom: 60px;
  font-size: 12px;
}

.cp-user-banner-image-uploader .chooseFileButton:hover {
  background: #0c5ebe;
  border: 1px solid #0c5ebe;
  color: white;

}

.cp-user-banner-info-column {
}

.cp-user-banner-display-name-row {
  font-size: 28px;
}

.cp-user-banner-coverage-row {
  font-size: 14px;
}

.cp-user-banner-share-column {
  justify-content: center;
  display: flex;
}

.cp-user-banner-share-button {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.8);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 100px;
}

.cp-user-banner-share-button:hover {
  background-color: #0c5ebe;
  border: 1px solid #0c5ebe;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cp-user-content-section {
  /* background: #073966; */
  background: transparent;
  position: relative;
  margin-bottom: 20px;
}

.cp-user-content-about-column {
  height: 580px;
  margin-top: -200px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 92;
  margin-left: 40px;
}

.cp-user-content-user-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.cp-user-content-follow-button-row {
  margin-top: -50px;
  justify-content: center;
}

.cp-user-follow-button {
  background: rgba(255, 255, 255, 0.8);
  color: #073863;
  border: 1px solid #073863;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 12px;
  padding: 4px 30px;
  border-radius: 100px;
}

.cp-user-follow-button:hover {
  background: #073863;
  color: white;
  border: 1px solid #073863;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cp-user-content-about-section {
  margin-top: 15px;
  padding: 15px;
  padding-bottom: 0;
  /* margin-bottom: 20px; */
  /* padding-top: 0; */
}

.cp-user-content-about-text-row {
  height: 140px;
  max-height: 140px;
  overflow-y: scroll;

}

.cp-user-content-about-text-row::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 2px;
}

.cp-user-content-about-text-row::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 10px;
}

.cp-user-content-about-section .col {
  padding-bottom: 10px;
  border-bottom: 1px solid #0b5699;
}

.cp-user-content-about-section h5 {
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
}

.cp-user-content-about-section p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.cp-user-content-stats-section {
  padding: 15px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  /* position: absolute; */
  width: 100%;
  /* bottom: 15px; */

}

.cp-user-stat {
  /* padding-top: 10px; */
  font-size: 14px;
}

.cp-user-stat:not(:last-child) {
  margin-bottom: 5px;
}

.cp-user-content-research-column {
  /* margin-top: 10px;
  margin-left: 50px; */
}

.cp-user-content-research-column h3 {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.cp-published-research-column {
  margin-left: 60px;
}

.cp-individual-article {
  margin-bottom: 20px;
  /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  padding: 10px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 120px;
}

.cp-individual-article .col-md-3 {
  justify-content: center;
  display: flex;
  align-items: center;
}

.cp-individual-article-title a {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 5px;
}

.cp-individual-article-summary {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  overflow-y: scroll;
}

.cp-individual-article-summary::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 2px;
}

.cp-individual-article-summary::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 5px;
}

.cp-individual-article-date-published {
  font-size: 12px;
  color: #158df9;
  /* margin-top: 5px; */
  position: absolute;
  bottom: 0;
}

.cp-individual-article-image {
  width: 100%;
  object-fit: contain;
}

.cp-individual-article-image-no-image {
  background: rgba(0, 0, 0, 0.3);
}

.cp-no-articles-published {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-bottom: 100px;
}


.modal-open {
  overflow-y: inherit !important;
  padding-right: 0 !important;
}

body.modal-open .contributor-profile-container .row {
  filter: blur(2px);
}

.cp-share-profile-modal {
  margin-top: -93px;
  background: rgba(0, 0, 0, 0.3);
}

.cp-share-profile-modal .modal-content {
  border: 1px solid #1588ee;
  border-radius: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  color: white;
}

.cp-modal-header-text {
  margin-bottom: 20px;
  justify-content: center;
}

.cp-copy-link-form-control {
  border-radius: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #1588ee;
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

.cp-copy-clipboard-icon {
  color: #1588ee;
}

.cp-copy-clipboard-icon:hover {
  color: white;
  cursor: pointer;
}









.contributor-profile-top-row {
}

.contributor-profile-col-a {
  background: white;
  padding: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.contributor-profile-col-a .row {
  margin: 0;
  padding: 0;
}

.contributor-profile-avatar-row {
  justify-content: center;
}

.contributor-profile-avatar {
  width: 100%;
  min-height: 200px;
  object-fit: contain;
}

.contributor-profile-col-b {
  background: white;
  padding: 10px;
}

.contributor-profile-col-b .row {
  padding: 0;
  margin: 0;
}

.contributor-profile-tabs-col {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.contributor-user-info {
  font-weight: bold;
  font-size: 24px;
  padding: 0;
  margin: 0;
  height: 35%;
}

.contributor-social-row a:not(:last-child) {
  margin-right: 10px;
}

.contributor-social-icon {
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.1s;
}

.contributor-social-icon:hover {
  color: rgba(33, 122, 255, 1);
  cursor: pointer;
}

.contributor-social-icon:not(:last-child) {
  margin-right: 10px;
}

.contributor-user-name-div {
  height: 100%;
  width: 100%;
}

.contributor-user-info .row {
  align-items: center;
}

.contributor-about-section {
  height: 65%;
}

.contributor-about {
  margin: 0;
  height: 50%;
  color: rgba(0, 0, 0, 0.5);
}

.contributor-followers-section {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.border-container {
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 5px;
  border-radius: 2px;
}

.contributor-focus {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.contributor-focus .col {
  margin: 0;
  padding: 0;
}

.contributor-about-label {
  color: rgba(0, 0, 0, 0.5);
}

.contributor-about-stat {
  text-align: right;
}

.payout-tabs .test-tab {
  background: red;
}

.contributor-profile-card {
  background: white;
  margin: 0;
  padding: 0;
  padding: 15px;
  margin-bottom: 15px;
  /* margin-top: 30px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
}

.tab-column {
  margin-top: 15px;
}

.profile-title-text {
  font-size: 32px;
  /* background: red; */
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  /* border-bottom: 1px solid black; */
  /* margin-bottom: 10px; */
}

.top-section {
  margin-top: 15px;
}

.research-section-header {
  text-align: center;
  /* color: rgba(0, 0, 0, 0.5); */
  /* border-bottom: 1px solid rgba(33, 122, 255, 1); */
}

.about-and-research-section-container {
  margin: 0;
  padding: 0;
}

.tab-row {
  margin-top: 10px;
}

.research-tab-column {
  columns: 2 auto;
}

.contributor-profile-article {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  padding: 5px;
  width: 45%;
}

.contributor-profile-article .date-published {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.contributor-profile-article .article-title {
  font-size: 14px;
  font-weight: bold;
}

.contributor-profile-article .article-summary {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.contributor-profile-article .article-image {
  width: 100%;
  height: 60px;
}

.contributor-article-col {
  margin-bottom: 40px;
}

.cp-user-content-user-image-edit-icon {
  position: absolute;
  top: 5;
  right: 5;
}

.cp-user-avatar-image-uploader .fileContainer {
  border-radius: 0;
  background: #042038;
  height: 300px;
  color: white;
  justify-content: center;
  vertical-align: top;
  display: flex;
  padding: 0;
  margin: 0;
}

.cp-user-avatar-image-uploader .chooseFileButton {
  background: transparent;
  border: 1px solid white;
  margin-bottom: 60px;
  font-size: 12px;
}

.cp-user-avatar-image-uploader .chooseFileButton:hover {
  background: #0c5ebe;
  border: 1px solid #0c5ebe;
  color: white;

}
