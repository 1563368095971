.suggestion-form {
  color: #3e88c5;
}
.form-submitt-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.suggestion-list {
  margin-left: 80%;
}
.back-suggestion-list {
  font-size: 20px;
}
