.terms-of-service {
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
  /* width: 100vw; */
  height: 100%;
  color: white !important;
  font-weight: normal !important;
}

.terms-of-service h1 {
  color: white;
}

.terms-of-service h4 {
  color: white;
}

.terms-of-service h6 {
  color: white !important;
}

.mainbody {
  color: white;
}

.termsb{
    margin-top: 70px;
    color: white;
    }

.termsb h1{

    text-align:center;
    height: 70px;
    color: white !important;
}
.termsb p{
    font-size: 16px;
    font-weight:bold;
    text-align: justify;
    color: white;
}

.mainbody{

    height: auto;
    float: left;
    overflow: hidden;
    margin-top: 10px;
}
.mainbody h6{
    font-size: 18px;
    font-weight:bold;
}

.leftsite{

    width: 400px;
    overflow: hidden;
    float: left;
}
.rightsite{
    width: 700px;
    float: right;
    overflow: hidden;
    text-align: justify;
}
.ol{
padding: 20px;

}
