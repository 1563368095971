.account-form {
    display: inline-block;
}

.image-uploader-row {
    justify-content: center;
    /* margin-top: 15px !important; */
}

.avatar-container {
    margin-top: 15px;
}

.image-uploader-container {
    position: relative;
}

.image-overlay {
    position: absolute;
    width: 300px;
    height: 300px;
    margin-top: 15px;
    border-radius: 2px;
    top: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: #0182E7;
}

.image-overlay-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.image-uploader-container:hover .image-overlay {
  opacity: 0.8;
  cursor: pointer;
}


.konvajs-content:nth-child(3) {
    border-radius: 5px;
}

.save-image-button-row {
    justify-content: center;
    margin-top: 20px !important;
}

.account-info {
    /* width: 100%; */
  }

  .account-info-form-column {
  }

  .account-info-label {
      text-align: right;
  }

  .my-account-edit-info {
    margin-left: -140px;
    border-bottom: 1px solid;
    padding: 8px;
    width: 75%;
    /* color: slategray; */

  }

  .account-form-button-row {
      display: flex;
      justify-content: flex-end;
  }

  .my-account-edit-form-control-col {

  }

  .uploaded-image {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .account-info {
  }

  .my-account-info-top {
      height: 70px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0;

  }

  .my-account-info-inner {
      height: 70px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0;

  }

  /* .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-bottom: 0px solid #495057;
    border-radius: 0rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline-width: 0px !important;
}
.form-control:focus {
    color: #495057;
    background-color: transparent;
    border-color: transparent;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
    border-bottom: 0px solid #0b4d8f;
}
.modal-input {
    display: block;
    width: 100%;
    height: 0px;
    padding: 1.375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-bottom: 1px solid #495057;
    border-radius: 0rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline-width: 0px !important;
}

.modal-input:focus {
    color: #495057;
    background-color: transparent;
    border-color: transparent;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
    border-bottom: 2px solid #0b4d8f;
} */

.modal-button {
    width: 105px;
    background: cornflowerblue;
    border: cornflowerblue;
    border-radius: 0rem;
}

.account-form-modal {
    border-radius: 0rem;
    box-shadow: 1px 1px 3px 3px rgb(231, 230, 230);
}

.modal-backdrop {
    /* bug fix - no overlay */
    display: none;
}
