.detail-page-section {
  margin-top: 30px;
}
.company-logo {
  width: 150px;
  height: 150px;
}
.company-header h5, h3 {
  color: #3e88c5;
}
.description-header {
  color: #3e88c5;
  font-weight: bold;
  font-size: 14px;
}
.token-details-info {
  width: 100%;
}

.detail-tabs {
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  bottom: 0;
  position: absolute;
  list-style: none;
  z-index: 99999;
}

.detail-tabs a {
  position: relative;
  background: #ddd;
  background-image: linear-gradient(to bottom, #fff, #ddd);
  padding: 0.7em 3.5em;
  float: left;
  text-decoration: none;
  color: #444;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  border-radius: 5px 0 0 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.detail-tabs a:hover,
.detail-tabs a:hover::after,
.detail-tabs a:focus,
.detail-tabs a:focus::after {
  background: #fff;
}

.detail-tabs a:focus {
  outline: 0;
}

.detail-tabs a::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: -0.5em;
  bottom: 0;
  width: 1em;
  background: #ddd;
  background-image: linear-gradient(to bottom, #fff, #ddd);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  transform: skew(10deg);
  border-radius: 0 5px 0 0;
}

a[aria-selected='true'],
a[aria-selected='true']::after {
  background: #fff;
  z-index: 3;
}

.details-tabs {
  margin-top: 50px;
  margin-bottom: 50px;
}

#tab-what-is-asset,
#tab-deal-detail,
#tab-market-data,
#tab-sentiment-data,
#tab-news-data {
  padding-top: 50px;
  border: 1px solid #ddd;
}
.sub-token-details-data {
  padding-right: 0;
  padding-left: 0;
}
.link-form {
  width: 100%;
}
.link-form-content {
  width: 100%;
  height: 330px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.link-paragraph {
  border: 1px solid silver;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.link-header-paragraph {
  border: 1px solid silver;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a4278;
  color: white;
  font-weight: bold;
}
.sentiment-tab-content {
  height: 400px;
  overflow-y: scroll;
}
.comment-input {
  box-shadow: none;
}
.see-comments,
.add-comments {
  cursor: pointer;
}
.news-comment {
  margin-top: 20px;
  border-bottom: 1px solid black;
}
.news-header {
  cursor: pointer;
}

.avatar-image {
  width: 100px;
  border-radius: 100%;
}
.reply-comment-avatar-image {
  width: 35px;
  border-radius: 100%;
}

.financials {
    border-style: solid;
    padding: 5px;
    margin-bottom: 5px;
    border-color: #3e88c5;
}

.teams {
    border-style: solid;
    padding: 5px;
    margin-bottom: 5px;
    border-color: #3e88c5;
}

.relevant-links {
    border-style: solid;
    padding: 5px;
    margin-bottom: 5px;
    border-color: #3e88c5;
}

.company-header .description-header {
    display: inline-block;
}

.teams .description-header {
    display: inline-block;
}

.teams .description-header #link {
    background: red;
}

.single-team {
    background: lightgrey;
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;
}

.financials .description-header {
    display: inline-block;
}

.relevant-links .description-header {
    display: inline-block;
}

.description-container {
}

.description-box {
    border-style: solid;
    padding: 5px;
    border-color: #3e88c5;
    width: 600px;
    height: 250px;
}
