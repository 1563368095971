.research-app-info-container {
  margin-top: 90px;
  max-width: 100vw;
  padding: 0;
  /* margin-bottom: 30px; */
}

.research-app-info-top-row {
  padding: 40px 0 !important;
}

.research-app-info-top-row .col-md-6 {
}

.splash-image-col {
  justify-content: flex-end;
  display: flex;
  background-image: url('../../../images/ResearchApplication/splash-image.png');
  background-size: cover;
}

.splash-image {
  /* max-width: 80%; */
  object-fit: cover;
}

.splash-text {
  /* background: red; */
}

.splash-text-div {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.splash-text h4 {
  color: #75c2de;
  font-size: 32px;
  font-weight: bold;
}

.research-app-info-text-row {
  margin: 0;
  padding: 0;
  margin-top: 30px;
}

.research-app-info-button-row {
  margin: 0;
  padding: 0;
  /* justify-content: center; */
  margin-top: 60px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 100%;
}

.token-listed-link {
  width: 50%;
  justify-content: center;
  display: flex;
}

.token-listed-link:hover {
  text-decoration: none;
}

.research-app-info-button {
  width: 200px;
  font-size: 14px;
  background: transparent;
  color: #1588ee;
  border: 1px solid #1588ee;
  border-radius: 200px;
  font-weight: bold;
}

.research-app-info-button:hover {
  background: #1588ee;
  border: 1px solid #1588ee;
}

.research-app-info-button-row a {
  width: 50%;
  justify-content: center;
  display: flex;
}

.research-app-info-button-row a:hover {
  text-decoration: none;
}

.research-app-text-col h3 {
  text-align: center;
  color: #1963a3;
  /* color: white; */
  margin-bottom: 20px;
  font-weight: bold;
}

.research-app-text-col p {
  color: white;
}

@function set-text-color($color) {
    @if (lightness( $color ) > 50) {
      @return $black;
    }
    @else {
      @return $white;
    }
}
