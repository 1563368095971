.research-application-container {
    max-width: 100% !important;
    height: 100%;
    border-radius: 0;
    display: table;
    padding: 0;
    margin: 0;
}

.research-application-section {
  max-width: 70% !important;
  vertical-align: center;
  display: table-cell;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(to top, rgba(33, 122, 255, 0.85), rgb(10, 47, 180, 0.9));
}

.research-application-background {
    margin-top: 90px;
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-image: url('../../../images/HomePage/header-background-image.png');
    background-color: #11076B;
    background-size: cover;
}
/* #0A2FB4
rgb(10,47,180) */

.research-application-header-row {
    justify-content: center;
    margin: 0px 0px 60px 0px;
}

.research-application-header-row h3 {
    color: white;
}

.research-application-form, .register-or-login-section {
    color: white;
    font-size: 14px;
}

.research-application-form .form-control, {
    width: 50%;
    margin-left: 25%;
}

.research-application-form .form-label {
  padding: 15px 0px 0px 15px;
  margin: 0;
}

.research-application-form .form-control {
  margin: 0;
  width: 100%;
}

.research-application-form .col-sm-3, .research-application-form .col-md-3 {
  padding: 15px 15px 0px 0px;
}

.research-application-buttons-row {
    display: flex;
    justify-content: center;
}

.research-application-button {
    margin-left: 15px;
    margin-right: 20px;
    width: 200px;
    margin-bottom: 40px;
}

.research-application-form-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.research-application-form .form-control, .register-or-login-section .form-control {
  font-size: 14px;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-bottom: 1px solid white;
  border-radius: 0rem;
}

/* .research-application-form .form-control:hover {
  cursor: pointer;
} */

.research-application-form .form-control::placeholder, .register-or-login-section .form-control::placeholder {
  color: white;
  font-size: 14px;
}

.research-application-form-control {
  transition: border 0.15s, border-radius 0.15s, background 0.15s, box-shadow 0.15s;
}

.research-application-form-control:hover {
  /* border: 1px solid #3590FC; */
  /* border-radius: 5px; */
  /* background: #3590FC;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* cursor: pointer; */
}

.research-application-form-control:focus {
  background: transparent;
  color: white;
}

.research-application-form-control option {
  background-color: #0A2FB4;
}

.research-application-form:focus .option {
  color: black;
}

.research-application-form-typeahead  ul {
  background-color: #0A2FB4;
  border: none;
  border-radius: 0;
}

.research-application-form-typeahead .rbt-input-main {
  padding: 6px 12px;
}

.research-application-form-typeahead:hover {
  cursor: pointer !important;
}

.research-application-form-typeahead .dropdown-item:hover {
    color: white;
    background: #3590FC;
    border-radius: 0;
    cursor: pointer;
}

.research-application-form-typeahead a {
    color: white;
    font-size: 14px;
}

.research-application-submit {
    background: transparent;
    color: white;
    font-size: 16px;
    border: 1px solid white;
    width: 160px;
    height: 35px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.research-application-submit:hover {
  background: #3590FC;
  border: 1px solid #3590FC;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.research-status {
  color: white;
  justify-content: center;
  display: flex;
  padding-top: 15px;
}

.research-status a {
  color: orange;
}

#select-files {
  border: 1px solid white;
  border-radius: 5px;
  transition: border 0.15s, background 0.15s, box-shadow 0.15s;
}

#select-files:hover {
  /* border: 1px solid white !important; */
  border: 1px solid #3590FC;
  background: #3590FC;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.clear-field-button-col {
  justify-content: center;
  /* align-items: top; */
  vertical-align: top;
  display: flex;
}

.selected-document-row {
  justify-content: center;
  /* display: inline; */
}

.selected-document-row .col {
  height: 38px;
  /* margin: 0;
  padding: 0; */
}

.selected-document-row .col p {
  padding-left: 15px;
}

#research-clear-col {
  /* padding: 0; */
  margin: 0;
}

#clear-selected-field {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
  /* padding-right: 15px; */
  margin-right: 15px;
}

#clear-selected-field:hover {
  /* background: darkred; */
}

.register-or-login-section {
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(to top, rgba(33, 122, 255, 0.85), rgb(10, 47, 180, 0.9));
  position: relative;
}

.register-login-top-label {
  justify-content: center;
  display: flex;
  font-size: 18px;
  margin-bottom: 20px !important;
}

.rol-bottom-field {
  padding-bottom: 30px !important;
}

.rol-submit-button {
  background: transparent;
  border: 1px solid white;
  margin-bottom: 15px;
}

.rol-submit-button:hover {
  background: #3590FC;
  border: 1px solid #3590FC;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.rol-already-have-an-account:hover {
  text-decoration: underline;
  cursor: pointer;
}

.rol-empty-row {
  height: 38px;
  margin-bottom: 15px;
}

.login-header-text {
  color: white;
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
}
