.privacyb{
    margin-top: 70px;
    }

.privacyb h1{
    text-align:center;
    height: 70px;
}
.privacyb p{
    font-size: 18px;
    font-weight:bold;
    text-align: justify;
}

.mainbody{

    height: auto;
    float: left;
    overflow: hidden;
    margin-top: 10px;
}
.mainbody h6{
    font-size: 18px;
    font-weight:bold;
}

.leftsite{

    width: 400px;
    overflow: hidden;
    float: left;
}
.rightsite{
    width: 700px;
    float: right;
    overflow: hidden;
    text-align: justify;
}
.ol{
padding: 20px;

}
.pfooter{

    background-color: #073966 ;
    text-align:center;
    clear: both;
    margin-bottom:25px;
    padding: 5px;
}
.pfooter a{
    color:#3498DB;
}
