.pw-reset-row {
  background-image: url('../../images/HomePage/header-background-image.png');
  background-color: #0d064d;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
}

.pw-reset-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pw-reset-box {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  backdrop-filter: blur(2px);
  padding: 30px;
}

.pw-reset-box h5 {
  color: white;
}

.pw-reset-box h5 a {
  color: #ff6b00;
}

.pw-reset-success {
  justify-content: center;
}

.pw-reset-form-control {
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-bottom: 1px solid #008fff;
  border-radius: 0rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline-width: 0px !important;
}

.pw-reset-form-control:focus {
    color: white;
    background-color: transparent;
    border-color: transparent;
    outline: 0 !important;
    border-bottom: 1px solid #008fff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
}

.pw-reset-form-control::placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
}

.pw-confirm-button {
  width: 200px;
  margin-bottom: 40px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #1586eb;
  color: #45a7ff;
}

.pw-confirm-button:hover {
  color: white;
  background: linear-gradient(to top, rgb(13, 6, 77, 0.8) 10%, rgb(42, 25, 205, 1) 100%);
  border: 1px solid #008fff;
}

.pw-button-row {
  margin: 0;
  padding: 0;
  justify-content: center;
}
