.fi-logo {
  /* width: 100%; */
  width: 170px;
}

.middle-button-nav {
  width: 100%;
  justify-content: flex-end;
  font-size: 16px;
  align-items: center;
}

.navigation-bar {
  background: #5A5C69;
  margin: 0;
  padding: 16px 0 16px 70px;
  height: 90px;
  width: 100%;
  justify-content: space-between;
}

.navigation-bar-links-container {
  min-width: 50%;
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.login-register-button-container {
  padding: 0;
  margin: 0;
}

.end-button-nav {
  width: 30%;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  font-size: 16px;
}

.navigation-bar-item {
  color: white;
  margin: 0 5%;
}

.navigation-bar-item:hover {
  text-decoration: none !important;
  color: white;
}

.navigation-bar-item:after {
  display:block;
  content: '';
  border-bottom: solid 1px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navigation-bar-item:hover:after {
  transform: scaleX(1);
}

.navigation-bar-item {
}

.navigation-bar-dropdown {
  margin: 0;
  margin: 0 5%;
}

.navigation-bar-dropdown ul {
  margin: 0;
  padding: 0;
  padding: 10px;
}

.navigation-bar-dropdown a {
  color: white !important;
}

.navigation-bar-dropdown ul a:hover {
  text-decoration: none;
}

.navigation-bar-dropdown ul {
  color: white !important;
}

.navigation-bar-dropdown a:after {
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navigation-bar-dropdown a:hover:after {
  transform: scaleX(1);
}

.navigation-bar-dropdown ul:hover {
  cursor: pointer;
}

.navigation-bar-dropdown ul:after {
  display: block;
  content: '';
  border-bottom: solid 1px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navigation-bar-dropdown ul:hover:after {
  transform: scaleX(1);
}

.navigation-bar-dropdown .dropdown-menu {
  background: #5A5C69;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 3px;
  font-size: 14px;
  margin-right: 20px !important;
}

.navigation-bar-dropdown .dropdown-item:hover {
  background: black;
}

.navigation-bar-dropdown .dropdown-item:after {
  display:block;
  content: '';
  border-bottom: solid 1px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navigation-bar-dropdown .dropdown-item:hover:after {
  transform: scaleX(1);
}

.user-topbar-nav {

}

.user-topbar-nav .dropdown-toggle {
  color: white;
}

.user-topbar-nav .dropdown-toggle:hover {
  color: white;
}

.user-topbar-nav a {
  color: black;
  align-items: center;
  display: flex;
}

.user-topbar-nav a:hover {
  color: black;
}

.user-topbar-nav .dropdown-item {
  justify-content: space-between;
}

.user-topbar-nav .dropdown-item:active {
  background: rgba(0, 0, 0, 0.1);
}

.user-topbar-nav a::after {
  margin-left: 8px;
}

.topbar-icon {
  color: rgba(0, 0, 0, 0.3) !important;
}

.nav-bar-item {
  margin: 0 5%;
}

.user-topbar-item {
  font-size: 14px;
}

.user-topbar-text {
  font-size: 14px;
}

.user-topbar-user-icon {
  margin-left: 8px;
}

.login-container {
  background-color: #f8f9fc;
  /* align-items: center; */
  height: 100vh !important;
}

.login-row {
  height: 100%;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  /* margin-top: 48px; */
}

.login-logo-row {
  background: #5a5c69;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  /* height: 90px; */
}

.login-logo {
  max-height: 8rem;
}

.login-logo-box {
  padding: 16px 0 16px 70px;
}

.register-box {
  background: white;
  height: 400px;
  width: 600px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 20px;
  position: relative;
}

.login-box {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border: none !important;
}

.login-box .row {
  height: 100%;
}

.login-content-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.login-content-col h4 {
  color: #858796;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.login-content-col button {
  margin-top: 20px;
}

.login-content-col .row {
  justify-content: space-around;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.login-navlinks {
  /* position: absolute; */
  width: 100%;
  margin-top: 32px;
  justify-content: space-between;
  display: flex;
}

.login-navlinks a {
  color: #4e73df;
}

.login-navlinks a:hover {
  color: #2e59d9;
}

.login-formcontrol-row {
  justify-content: space-between !important;
  margin-bottom: 10px;
  color: #858796;
  align-items: center;
}

.login-formcontrol-row .form-control {
  width: 70%;
}

.login-header {
  padding: 3rem !important;
  background: #5a5c69;
}

.login-box-padding {
  padding: 0 3rem 0 3rem;
}

.login-welcome .col {
  /* padding: 15px; */
  margin-bottom: 16px;
}

.login-box-col {
  padding: 15px !important;
}

.login-welcome h1 {
  font-weight: 400 !important;
  margin: 0;
}

.login-welcome p {
  margin: 0;
}

.login-formcontrol-box {
  width: 100%;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}

.login-formcontrol-text {
  margin: 11px 7px 0 15px;
  font-size: 13px;
}

.login-formcontrol {
  width: 100% !important;
  border: none !important;
  color: #858796 !important;
}

.login-formcontrol-container {
  margin-bottom: 16px;
}

.login-button-row {
  justify-content: flex-end !important;
}

.common-form-control {
  border: none !important;
  background: #f8f9fc !important;
  color: #858796 !important;
}

.common-form-control::-webkit-input-placeholder {
  color: #858796 !important;
}

.common-form-control::placeholder {
  color: #858796 !important;
}

.common-button {
  background-color: #4e73df !important;
  border: 1px solid #4e73df !important;
}

.common-button:hover {
  background: #2e59d9 !important;
  border: 1px solid #2653d4 !important;
}

.contact-us-col {
  background-image: linear-gradient(to top, rgba(33, 122, 255, 0.85), rgb(10, 47, 180, 0.9));
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 40px;
}

.contact-us-jumbotron {
  height: 100%;
  background-image: url(/static/media/header-background-image.740e3547.png);
  background-color: #11076B;
  background-size: cover;
  margin-top: 90px;
  padding-bottom: 90px;
}

.contact-us-jumbotron .header-text-row {
  margin-bottom: 30px;
}

.contact-us-jumbotron .header-text {
  color: white;
  text-align: center;
  margin-top: 60px;
}

.contact-us-submit-row {
  justify-content: center;
}

.contact-us-form .research-application-form-control {
  min-height: 90px;
}

.contact-us-received-row {
  justify-content: center;
  color: white;
}

.register-background {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-image: none !important;
    background-repeat: no-repeat;
    background-color: #0d064d;
    background-size: 100%;
}
.register-lebel{
    color: black;
    margin-left: 50px;
}

.register-form-container .form-control {
  width: 50%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-bottom: 1px solid #008fff;
  border-radius: 0rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline-width: 0px !important;
}

.register-form-container .form-control:focus {
    color: white;
    background-color: transparent;
    border-color: transparent;
    outline: 0 !important;
    border-bottom: 1px solid #008fff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
}

.register-form-container .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.register-form-container .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.register-form-background{
    background: #f8f9fadb;
}

.register-form-container {
  padding: 30px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.centered-row {
  justify-content: center;
  background: red;
  display: flex;
}

.register-row {
  background: red;
}

.register-col {
  background: orange;
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-buttons-row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    background: red !important;
    /* padding-bottom: 40px; */
}

.recaptcha-col {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.recaptcha {
  /* margin-top: 20px !important; */
  margin-bottom: 20px;
}

.register-control {
  width: 50%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-bottom: 1px solid #495057;
  border-radius: 0rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline-width: 0px !important;
}

.register-control:focus {
    color: #495057;
    background-color: transparent;
    border-color: transparent;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
    border-bottom: 3px solid #0b4d8f;
}

.register-group {
  /* background: red; */
  justify-content: center;
}

.register-group:not(:last-child) {
  margin-bottom: 15px;
}

.disabled-register-button {
}

.home-container {
    background-image: url(/static/media/header-background-image.740e3547.png);
    background-color: #11076B;
    background-size: cover;
}

.header-section {
    margin-top: 15%;
    margin-left: 15px;
    padding: 0px 20px 0px 0px;
}

.header-sub-title h1 {
    font-size: 35px;
    color: white;
    font-style: normal;
    margin-bottom: 40px;
}

.header-sub-title p {
    color: white;
    font-size: 16px;
    margin: 10px 0px 100px;
}

/* FEATURES SECTION */
.features-container {
    background-color: rgba(#11076b, 0);
}

.features-section {
    background-image: linear-gradient(to top, rgba(33, 122, 255, 0.2), rgba(28, 184, 247, 0.5));
    color: white;
    padding: 40px 0px 40px;
}

.features-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.features-section h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.features-section .images-row ul {
    margin: 0;
    padding: 0;
}

.features-section .images-row li {
    width: 556px;
    height: 259px;
    list-style: none;
    float: left;
    position: relative;
    display: flex;
}

.feature-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.features-contents {
    margin-right: 10px;
}

.features-contents ul>li {
    margin: 0px 40px 20px 40px;
    font-size: 14px;
}

.features-interior {
    display: flex;
}

.center-aligned-button {
    display: flex;
    justify-content: center;
}

.apply-now {
    background: transparent;
    color: #81b7ff;
    font-size: 16px;
    border: 1px solid #81b7ff;
    width: 160px;
    height: 35px;
    margin-top: 40px;
}

/* INFO SECTION */
.info-container {
    background-color: #0B0347;
}

.info-section {
    color: white;
    flex-direction: column;
    padding: 40px 0px 40px;
}

.info-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.info-container h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.info-section .images-row {
    display: flex;
}

.info-section .column {
    text-align: center;
    margin: 0;
    padding: 30px 30px 0px 30px;
}

.info-section .images-row .image-block {
    width: 151px;
    height: 151px;
    float: left;
}

.info-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.info-section p {
    display: inline-block;
    text-align: left;
    font-size: 14px;
}

/* OPPORTUNITY SECTION */
.opportunity-container {
    background-color: #11076B;
}

.opportunity-section {
    background-image: linear-gradient(to top, rgba(33, 122, 255, 0.2), rgba(28, 184, 247, 0.5));
    color: white;
    padding: 40px 0px 40px;
}

.opportunity-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.opportunity-section h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.opportunity-section .images-row {
    display: flex;
}

.opportunity-section .column {
    text-align: center;
    margin: 0;
    padding: 0;
}

.opportunity-section .images-row .image-block {
    width: 556px;
    height: 259px;
    float: left;
}

.opportunity-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.opportunity-contents h2 {
    margin-left: 40px;
    font-size: 24px;
}

.opportunity-contents ul>li {
    padding-bottom: 20px;
    margin: 0px 40px 0px 40px;
    font-size: 14px;
}

.features-interior {
    display: flex;
}

/* TIMELINE SECTION */
.timeline-container {
    width: 100%;
    background-color: #0B0347;
    background-image: url(/static/media/timeline-background-copy.c01d05eb.png);
    color: white;
    height: 600px;
    width: 100%;
    background-size: cover;
    padding-top: 40px;
}

.timeline-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.timeline-section h1 {
    text-align: center;
    font-size: 35px;
}

.timeline-section .images-row {
    margin-top: 100px;
    display: flex;
}

.timeline-section .image-block {
    height: 340px;
    float: left;
}

.timeline-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
}

/* TEAM SECTION */
.team-container {
    background-color: #0B0347;
    color: white;
}

/* PARTNERS SECTION */
.partners-container {
    background-color: #11076B;
    background-image: linear-gradient(to top, rgba(33, 122, 255, 0.4), rgba(17, 7, 107, 1));
    padding: 40px 0px 40px;
}

.partners-section {
    color: white;
}

.partners-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right
    position when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.partners-container h1 {
    padding-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.partners-section .images-row {
    display: flex;
}

.partners-section .column {
    text-align: center;
    margin: 0;
    padding: 0;
}

.partners-section .images-row .image-block {
    width: 144px;
    height: 144px;
    float: left;
}

.partners-image {
    max-width: 100% !important;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.partners-section p {
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
}

/* SIDENAV SECTION */
.sidenav-container-homepage {
    z-index: 100000;
    display: table;
    right: 0px;
    position: fixed;
    font-weight: normal;
    margin-right: 5px;
    top: 50%;
}

.sidenav-container-homepage .sidenav-section {
    transition-duration: 0.3s;
    display: table-cell;
    vertical-align: middle;
    margin: 0px;
    color: white;
    font-size: 10pt;
    text-align: right;
}

.sidenav-container-homepage .sidenav-icon {
    margin: 0px 0px 0px 10px;
    background: white;
    display: inline-block;
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

.sidenav-container-homepage .sidenav-text {
    font-size: 9pt;
}

.sidenav-container-homepage .navigation-button {
    margin-bottom: 5px;
}

.sidenav-container-homepage .navigation-button:hover {
    transition: color 1.5s visibility 1s;
    background: #81b7ff;
    cursor: default;
    color: black;
}

.footer {
  background: black;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 8px;
  background-image: url(/static/media/background-image.8a9987d8.png);
  background-size: 100% 100%;
  /* height: 300px; */
}

.footer-row {
  justify-content: center;
  margin-bottom: 32px;
}

.footer-links-container {
  display: flex;
  width: 600px;
  justify-content: space-between;
}

.footer-navlink {
  color: white;
}

.footer-navlink:hover {
  color: #0182E7;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-icon-container {
  width: 400px;
  justify-content: space-between;
  display: flex;
}

.footer-icon {
    font-size: 30px;
    color: white;
}

.footer-icon:hover {
  color: #0182E7;
}

.all-rights-reserved {
  justify-content: center;
  color: white;
}

.pw-reset-row {
  background-image: url(/static/media/header-background-image.740e3547.png);
  background-color: #0d064d;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
}

.pw-reset-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pw-reset-box {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 30px;
}

.pw-reset-box h5 {
  color: white;
}

.pw-reset-box h5 a {
  color: #ff6b00;
}

.pw-reset-success {
  justify-content: center;
}

.pw-reset-form-control {
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-bottom: 1px solid #008fff;
  border-radius: 0rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline-width: 0px !important;
}

.pw-reset-form-control:focus {
    color: white;
    background-color: transparent;
    border-color: transparent;
    outline: 0 !important;
    border-bottom: 1px solid #008fff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
}

.pw-reset-form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
}

.pw-reset-form-control::placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
}

.pw-confirm-button {
  width: 200px;
  margin-bottom: 40px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #1586eb;
  color: #45a7ff;
}

.pw-confirm-button:hover {
  color: white;
  background: linear-gradient(to top, rgb(13, 6, 77, 0.8) 10%, rgb(42, 25, 205, 1) 100%);
  border: 1px solid #008fff;
}

.pw-button-row {
  margin: 0;
  padding: 0;
  justify-content: center;
}

.edit-profile-area {
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
    margin-top: 60px;
}

.save-image {
    margin-top: 20px;
    width: 250px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.uploaded-image {
    width: 300px;
    height: 300px;
    margin-top: 15px;
    border-radius: 2px;
    display: block;
}

.avatar-container div div {
    border-radius: 5px;
    background-size: cover;
}

.konvajs-content {
    width: 300px !important;
    height: 300px !important;
    background-size: cover;
}

.konvajs-content canvas {
    border-radius: 5px !important;
    background-size: cover;
}

.account-info {
    margin-top: 3rem;
}

.user-credential-info {
    width: 500px;
    height: 350px;
    margin-left: 2rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form {
    width: 80%;
}

.contact-form p {
    font-weight: bold;
}

.one-paragraph {
    border: 1px solid silver;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paragraph {
    background-color: silver;
}

.go-to-admin-page {
    margin-left: 2rem;
    font-weight: bold;
    cursor: pointer;
}

.my-account-info-column {
    margin-top: 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: white;
}

.my-account-info-top {
    /* border: 1px solid; */
    padding: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.my-account-info-inner {
    /* border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid; */
    padding: 22px;
}

.my-account-info-inner:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

/* .search-space-section-news {
    width: 100%;
    height: 90px;
    border-radius: 0;
    background-color: rgb(2, 3, 54);
    background-image: url("../../images/HomePage/header-background-image.png");
    position: fixed;
    z-index: 100;
} */

.account-form {
    width: 100%;
    display: inline-block;
}

.edit-account-button {
    border: 1px solid;
    border-radius: 2px;
    width: 25px;
    height: 25px;
    padding: 2px;
    color: royalblue;
    box-shadow: 19px;
}

.admin-link-row {
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 30px;
    /* background: red; */
}

.admin-link {
    margin-right: 3rem;
}

.admin-link:hover {
    text-decoration: underline;
}

.refer-a-friend-row {
  margin-bottom: 30px;
  justify-content: center;
  width: 100%;
}

.refer-a-friend-button {
  background: transparent;
  border: 1px solid royalblue;
  color: royalblue;
  padding: 10px 30px;
}

.refer-a-friend-button:hover {
  background: royalblue;
  color: white;
  border: 1px solid royalblue;
}

.settings-body-section-bottom {
  height: 100%;
  background: rgb(178,195,195);
  background: linear-gradient(0deg, rgba(178,195,195,1) 0%, rgba(255,255,255,1) 100%);
}

#account-image {
  border: none;
}

.center-justified-row {
  justify-content: center;
}

#orange {
  background: orange;
}

#purple {
  background: purple;
}

.refer-a-friend-modal {
  padding-top: 10%;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  border: none !important;
}

.refer-a-friend-modal .modal-content {
  border: none;
}

.refer-a-friend-text-p {
  margin-bottom: 30px;
}

.refer-a-friend-control-row {
  align-items: center;
  margin-bottom: 5px !important;
}

.refer-a-friend-modal-bottom-row {
  margin-top: 20px !important;
}

.refer-a-friend-form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.refer-a-friend-form-control::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.account-settings-container {
  width: 100vw;
  max-width: 100vw;
}

body.modal-open .account-settings-container {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.account-form {
    display: inline-block;
}

.image-uploader-row {
    justify-content: center;
    /* margin-top: 15px !important; */
}

.avatar-container {
    margin-top: 15px;
}

.image-uploader-container {
    position: relative;
}

.image-overlay {
    position: absolute;
    width: 300px;
    height: 300px;
    margin-top: 15px;
    border-radius: 2px;
    top: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: #0182E7;
}

.image-overlay-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.image-uploader-container:hover .image-overlay {
  opacity: 0.8;
  cursor: pointer;
}


.konvajs-content:nth-child(3) {
    border-radius: 5px;
}

.save-image-button-row {
    justify-content: center;
    margin-top: 20px !important;
}

.account-info {
    /* width: 100%; */
  }

  .account-info-form-column {
  }

  .account-info-label {
      text-align: right;
  }

  .my-account-edit-info {
    margin-left: -140px;
    border-bottom: 1px solid;
    padding: 8px;
    width: 75%;
    /* color: slategray; */

  }

  .account-form-button-row {
      display: flex;
      justify-content: flex-end;
  }

  .my-account-edit-form-control-col {

  }

  .uploaded-image {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .account-info {
  }

  .my-account-info-top {
      height: 70px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0;

  }

  .my-account-info-inner {
      height: 70px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0;

  }

  /* .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-bottom: 0px solid #495057;
    border-radius: 0rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline-width: 0px !important;
}
.form-control:focus {
    color: #495057;
    background-color: transparent;
    border-color: transparent;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
    border-bottom: 0px solid #0b4d8f;
}
.modal-input {
    display: block;
    width: 100%;
    height: 0px;
    padding: 1.375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-bottom: 1px solid #495057;
    border-radius: 0rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline-width: 0px !important;
}

.modal-input:focus {
    color: #495057;
    background-color: transparent;
    border-color: transparent;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
    border-bottom: 2px solid #0b4d8f;
} */

.modal-button {
    width: 105px;
    background: cornflowerblue;
    border: cornflowerblue;
    border-radius: 0rem;
}

.account-form-modal {
    border-radius: 0rem;
    box-shadow: 1px 1px 3px 3px rgb(231, 230, 230);
}

.modal-backdrop {
    /* bug fix - no overlay */
    display: none;
}

.every-team-info {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 15px;
}
.document-uploader {
  background-color: #007bff;
  color: white;
  width: 16%;
  text-align: center;
  border-radius: 5%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.document-uploader:hover {
  cursor: pointer;
}

.token-form-sub-section {
  padding: 10px;
  background: rgba(0, 0, 0, 0.17);
  margin-bottom: 10px;
}

.documentDropZone {
  border: 1px dashed blue;
  min-height: 100px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: background 0.15s, color 0.15s;
}

.documentDropZone:hover {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
}

.documentDropZone p {
  margin: 0;
}

.edit-image-load {
  width: 150px;
  height: 150px;
}

.every-team-info {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 15px;
}
.document-uploader {
  background-color: #007bff;
  color: white;
  width: 16%;
  text-align: center;
  border-radius: 5%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.document-uploader:hover {
  cursor: pointer;
}

.suggestion-form {
  color: #3e88c5;
}
.form-submitt-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.suggestion-list {
  margin-left: 80%;
}
.back-suggestion-list {
  font-size: 20px;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.ql-editor {
  min-height: 18em;
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}

/* .research-lookup-container {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}

.research-lookup-section {
  position: fixed;
  background: #cccccc;
  z-index: 2;
  width: 100%;
  display: block !important;
  top: 90px;
}

.research-lookup-form {
  height: 50px;
}

.research-lookup-form-row {
  height: 100%;
  width: 100%;
  margin: 0;
  align-items: center;
}

.research-lookup-label {
  margin: 0;
}

.research-list-section {
  width: 100%;
  display: block !important;
}

.research-list-top-row {
  margin: 0;
  padding-left: 40px;
  width: 100%;
  background: white;
  z-index: 2;
  position: fixed;
  border-bottom: 1px solid black;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2),
    0 6px 20px -20px rgba(0, 0, 0, 0.19);
}

.research-list-items-row {
  margin: 0;
  margin-top: 20px;
  padding-top: 60px;
  position: relative;
}

.research-list-items-col {
  width: 100%;
}

.research-list-research-row {
  margin: 0;
  height: 40px;
  align-items: center;
}

.research-list-research-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.view-full-report-button {
  text-align: center;
  background-color: #e8e4e4;
}
.research-list-username {
  font-weight: bold;
  font-size: 14px;
}

.research-list-status {
  font-size: 14px;
}

.research-list-username:hover {
  color: #0182e7;
  cursor: pointer;
  text-decoration: underline;
}

.research-list-selected-research-col {
  margin-left: 15px;
  margin-top: 40px;
}

.back-to-research-list-button {
  font-weight: bold;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.back-to-research-list-button:hover {
  color: #0182e7;
  cursor: pointer;
  text-decoration: underline;
}

.research-list-selected-research-info-row {
  height: 40px;
}

.research-list-selected-research-info-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.research-list-selected-research-info-row p {
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
}

.research-list-selected-research-info-row label {
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
}

.research-list-button-row {
  height: 100%;
  align-items: center;
  padding-left: 15px;
}

.research-list-toggle-button {
  height: 30px;
  margin-left: 10px;
  padding: 0;
  width: 60px;
} */

.submission-user-selector {
  padding: 0px 18px;
  justify-content: center;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin-bottom: 16px;
  color: #858796;
}

.submission-user-selector:not(:last-child) {
  margin-right: 8px;
}

.submission-user-selector:hover {
  background-color: #36b9cc;
  border: 1px solid #36b9cc;
  color: black;
  cursor: pointer;
}

.submission-user-selector.selected {
  background-color: #36b9cc;
  border: 1px solid #36b9cc;
  color: white;
}

.submission-user-selector.selected:hover {
  background: #2c9faf !important;
  border: 1px solid #2a96a5 !important;
  color: black !important;
}

.admin-create-report-container {
  padding: 0;
  background: white;
}

.create-report-sidebar-col {
  background: rgba(0, 0, 0, 0.1);
  padding: 8px !important;
}

.create-report-sidebar-row {
  margin-bottom: 16px;
}

.create-report-sidebar-label {
  font-size: 10px;
  color: #858796;
  margin: 0;
}

.create-report-form-col {
  padding: 8px !important;
  position: relative;
}

.create-report-topbar-container {
  position: absolute;
  right: 8px;
}

.create-report-button {
  margin-left: 8px;
  height: 20px;
  padding: 0 4px;
}

.admin-create-draft-container {
  justify-content: center;
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
}

.table-button {
  height: 20px;
  padding: 0 4px;
  font-size: 12px;
}

.reset-icon {
  position: absolute;
  right: 4px;
  top: 4px;
}

.no-users-found-row {
  padding-bottom: 16px;
  width: 100%;
  justify-content: center;
  color: #858796;
}

.asset-profile-loading-spinner {
  color: #858796 !important;
}

.logo-image {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.token-item {
  border-bottom: 2px solid #3e88c5;
  width: 100%;
}
.token-info {
  padding-top: 30px;
  padding-bottom: 30px;
}
.edit-token {
  font-size: 25px;
}
.remove-token {
  font-size: 25px;
}
.edit-token:hover {
  color: #3e88c5;
}
.remove-token:hover {
  color: red;
}
.token-image {
  width: 150px;
  height: 150px;
}

.suggestion-item {
  border-bottom: 2px solid #3e88c5;
  margin-top: 30px;
  padding-bottom: 20px;
  width: 100%;
}

.suggestion-sub-info {
  margin-left: 70%;
}

.back-news-items {
  text-align: center;
  width: 4rem;
  color: white;
  background: red;
  margin-left: 90%;
  border: 1px solid red;
}

.suggestion-details {
  border: 1px solid black;
  font-size: 20px;
}

.delete-suggestion {
  font-size: 30px;
}

.delete-suggestion:hover {
  color: red;
}

.detail-page-section {
  margin-top: 30px;
}
.company-logo {
  width: 150px;
  height: 150px;
}
.company-header h5, h3 {
  color: #3e88c5;
}
.description-header {
  color: #3e88c5;
  font-weight: bold;
  font-size: 14px;
}
.token-details-info {
  width: 100%;
}

.detail-tabs {
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  bottom: 0;
  position: absolute;
  list-style: none;
  z-index: 99999;
}

.detail-tabs a {
  position: relative;
  background: #ddd;
  background-image: linear-gradient(to bottom, #fff, #ddd);
  padding: 0.7em 3.5em;
  float: left;
  text-decoration: none;
  color: #444;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  border-radius: 5px 0 0 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.detail-tabs a:hover,
.detail-tabs a:hover::after,
.detail-tabs a:focus,
.detail-tabs a:focus::after {
  background: #fff;
}

.detail-tabs a:focus {
  outline: 0;
}

.detail-tabs a::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: -0.5em;
  bottom: 0;
  width: 1em;
  background: #ddd;
  background-image: linear-gradient(to bottom, #fff, #ddd);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  transform: skew(10deg);
  border-radius: 0 5px 0 0;
}

a[aria-selected='true'],
a[aria-selected='true']::after {
  background: #fff;
  z-index: 3;
}

.details-tabs {
  margin-top: 50px;
  margin-bottom: 50px;
}

#tab-what-is-asset,
#tab-deal-detail,
#tab-market-data,
#tab-sentiment-data,
#tab-news-data {
  padding-top: 50px;
  border: 1px solid #ddd;
}
.sub-token-details-data {
  padding-right: 0;
  padding-left: 0;
}
.link-form {
  width: 100%;
}
.link-form-content {
  width: 100%;
  height: 330px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.link-paragraph {
  border: 1px solid silver;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.link-header-paragraph {
  border: 1px solid silver;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a4278;
  color: white;
  font-weight: bold;
}
.sentiment-tab-content {
  height: 400px;
  overflow-y: scroll;
}
.comment-input {
  box-shadow: none;
}
.see-comments,
.add-comments {
  cursor: pointer;
}
.news-comment {
  margin-top: 20px;
  border-bottom: 1px solid black;
}
.news-header {
  cursor: pointer;
}

.avatar-image {
  width: 100px;
  border-radius: 100%;
}
.reply-comment-avatar-image {
  width: 35px;
  border-radius: 100%;
}

.financials {
    border-style: solid;
    padding: 5px;
    margin-bottom: 5px;
    border-color: #3e88c5;
}

.teams {
    border-style: solid;
    padding: 5px;
    margin-bottom: 5px;
    border-color: #3e88c5;
}

.relevant-links {
    border-style: solid;
    padding: 5px;
    margin-bottom: 5px;
    border-color: #3e88c5;
}

.company-header .description-header {
    display: inline-block;
}

.teams .description-header {
    display: inline-block;
}

.teams .description-header #link {
    background: red;
}

.single-team {
    background: lightgrey;
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;
}

.financials .description-header {
    display: inline-block;
}

.relevant-links .description-header {
    display: inline-block;
}

.description-container {
}

.description-box {
    border-style: solid;
    padding: 5px;
    border-color: #3e88c5;
    width: 600px;
    height: 250px;
}

#application-approval {
  background: green;
  border-color: green;
  width: 80px;
}

#application-deny {
  background: red;
  border-color: red;
  width: 80px;
}

.user-list-selected-user-info-row a {
  /* background: red; */
  font-size: 14px;
  text-decoration: underline;
  display: flex;
  align-items: center !important;
  height: 100%;
}

.admin-contributor-container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.report-list-items-row {
  margin: 0;
  margin-top: 20px;
  padding-top: 60px;
  position: relative;
}

.report-list-items-col {
  width: 100%;
}

.report-list-research-row {
  margin: 0;
  height: 40px;
  align-items: center;
}

.report-list-research-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.report-list-status {
  font-size: 14px;
}

.view-full-report-button {
  text-align: center;
  background-color: #e8e4e4;
}

.contributor-list-section {
  width: 100%;
  display: block !important;
}

.contributor-list-top-row {
  margin: 0;
  padding-left: 40px;
  width: 100%;
  background: white;
  z-index: 2;
  position: fixed;
  border-bottom: 1px solid black;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2),
    0 6px 20px -20px rgba(0, 0, 0, 0.19);
}

.contributor-list-items-row {
  margin: 0;
  margin-top: 20px;
  padding-top: 60px;
  position: relative;
}

.contributor-list-items-col {
  width: 100%;
}

.contributor-list-research-row {
  margin: 0;
  height: 40px;
  align-items: center;
}

.contributor-list-research-row:nth-child(odd) {
  background: rgba(0, 0, 0, 0.2);
}

.contributor-list-status {
  font-size: 14px;
}

.admin-contributor-container .pagination-section {
  margin-top: 10px;
}

.report-title-author-section {
  padding: 30px;
  padding-top: 45px;
  margin: 0px;
  text-align: center;
}
.report-title-section {
  font-weight: bold;
  width: 100%;
}
.report-title-section h4 {
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.report-title-section p {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
  text-align: center;
}
.report-author-section {
  font-weight: bold;
  width: 100%;
}
.report-author-section p {
  margin: 0px;
  text-align: right;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
  font-size: 12px;
}
.report-author-section-image {
  border-radius: 2rem;
  width: 50px;
  height: 50px;
  margin-left: -20px;
  border: 1px solid gray;
}

.report-section-article-image {
  width: 100%;
  height: 300px;
  border: 1px solid gray;
}

.report-section-article-body {
  padding-top: 50px;
  text-align: left;
}
.report-category-section {
  margin-top: 55px;
  font-weight: bold;
  width: 100%;
}
.report-category-section li {
  margin: 0px;
  text-align: left;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
  font-size: 12px;
}
.research-report-unpublish {
  margin-top: 42px;
}


.user-lookup-container {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-bottom: 40px;
}

.user-lookup-section {
    background: white;
    width: 100%;
}

.user-lookup-form {
    height: 50px;
}

.user-lookup-form-row {
    margin: 0;
    align-items: center;
    padding-bottom: 8px;
}


.user-lookup-pagination-row a {
  color: #858796 !important;
}

.user-lookup-formcontrol {
  margin-left: 8px;
  width: 400px;
}

.user-lookup-pagination-row {
  justify-content: center;
  margin-top: 16px;
}

.user-lookup-label {
    margin: 0;
}

.user-list-section {
    width: 100%;
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
}

.user-list-top-row {
    margin: 0;
    padding-left: 40px;
    padding-top: 16px;
    width: 100%;
    background: white;
    z-index: 2;
    position: fixed;
    top: 0;
    border-bottom: 1px solid black;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2), 0 6px 20px -20px rgba(0, 0, 0, 0.19);
}

.user-list-items-row {
    margin: 0;
    /* padding: 0; */
    margin-top: 20px;
    padding-top: 120px;
    position: relative;
}

.user-list-items-col {
    width: 100%;
}

.user-list-users-container {
  padding: 8px !important;
  padding-top: 128px !important;
}

.user-list-user-row {
    margin: 0;
    height: 40px;
    align-items: center;
}

.user-list-user-row:nth-child(even) {
    background: rgba(0, 0, 0, 0.2);
}

.user-list-user-row .col {
}

.user-list-username {
    font-weight: bold;
    font-size: 14px;
}

.user-list-status {
    font-size: 14px;
}

.user-list-username:hover {
    color: #0182E7;
    cursor: pointer;
    text-decoration: underline;
}

.user-list-selected-user-col {
    margin-left: 15px;
    margin-top: 40px;
}

.back-to-user-list-button {
    font-weight: bold;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}

.back-to-user-list-button:hover {
    color: #0182E7;
    cursor: pointer;
    text-decoration: underline;
}

.user-list-selected-user-info-row {
    height: 40px;
}

.user-list-selected-user-info-row:nth-child(even) {
    background: rgba(0, 0, 0, 0.2);
}

.user-list-selected-user-info-row p {
    padding: 0;
    margin: 0;
    height: 100%;
    align-items: center;
    display: flex;
    font-size: 14px;
}

.user-list-selected-user-info-row label {
    padding: 0;
    margin: 0;
    height: 100%;
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: bold;
}

.user-list-button-row {
    height: 100%;
    align-items: center;
    padding-left: 15px;
}

.user-list-toggle-button {
    height: 30px;
    margin-left: 10px;
    padding: 0;
    width: 60px;
}

.table-loading-row {
  justify-content: center;
}

.every-team-info {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 15px;
}
.document-uploader {
  background-color: #007bff;
  color: white;
  width: 16%;
  text-align: center;
  border-radius: 5%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.document-uploader:hover {
  cursor: pointer;
}

.token-form-sub-section {
  padding: 10px;
  background: rgba(0, 0, 0, 0.17);
  margin-bottom: 10px;
}

.documentDropZone {
  border: 1px dashed blue;
  min-height: 100px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: background 0.15s, color 0.15s;
}

.documentDropZone:hover {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
}

.documentDropZone p {
  margin: 0;
}
.form-content .btn {
  margin-bottom: 10px;
}

.admin-portal-content p {
  text-align: center;
}

.add-team-button {
  margin-bottom: 15px;
}


.user-list-pagination-row {
  margin-top: 20px;
}

.token-templates-container {
  margin: 0;
  padding: 0;
  /* background: rgba(0, 0, 0, 0.3); */
  margin-bottom: 40px;
}

.token-templates-header {
  justify-content: center;
  /* font-weight: bold; */
  font-size: 22px;
}

.token-templates-section {
  width: 100%;
}

.individual-token-template {
  align-items: center;
  padding: 0;
  margin: 0;
  padding: 5px;
}

.individual-token-template:nth-child(odd) {
  background: rgba(0, 0, 0, 0.2);
}

.token-template-edit-form {
  padding: 20px;
}

.token-template-asset-type {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.token-template-render-section {
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
  margin: 0;
  padding: 20px 0;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.token-template-render-section-header {
  margin-left: 15px;
  margin-bottom: 15px;
  font-weight: bold;
}

.token-template-individual-field {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  padding: 10px;
}

.token-template-individual-field:not(:last-child) {
  /* margin-right: 10px; */
}

.token-template-individual-field:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.create-new-template-button-row {
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.template-individual-field-column {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.template-individual-field-column:not(:last-child) {
  margin-right: 5px;
}

.template-add-dropdown-row {
  margin: 0;
  padding: 0;
  width: 100%;
}

.template-dropdown-option {
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.template-dropdown-option:not(:last-child) {
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.template-dropdown-option .form-control {
  width: 90%;
}

.token-template-section-buttons-row {
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  padding: 10px;
}

.token-template-add-section-row {
  justify-content: center;
}

.token-edit-icon {
  margin-right: 10px;
}

.token-edit-icon:hover {
  cursor: pointer;
  color: #1586eb;
}

.token-delete-icon:hover {
  cursor: pointer;
  color: fireBrick;
}

.template-form-label-row {
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  width: 100%;
}

.token-template-delete-section-button {
  background: fireBrick;
  border: 1px solid fireBrick;
  margin-left: 5px;
}

.token-template-delete-section-button:hover {
  background: darkRed;
  border: 1px solid darkRed;
}

.template-optional-icon:hover {
  cursor: pointer;
  color: #1586eb;
}

.template-optional-icon-delete:hover {
  cursor: pointer;
  color: fireBrick;
}

.token-template-button-row {
  padding: 0;
  margin: 0;
  justify-content: flex-end;

}

.token-template-section-name-row {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.template-individual-field-delete-column {
  align-items: center;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  display: flex;
}

.template-optional-field-column {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.back-to-templates {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 20px;
}

.back-to-templates:hover {
  color: #1586eb;
  cursor: pointer;
  text-decoration: underline;
}

.template-currently-saving {
  padding: 0 20px;
  font-style: italic;
  color: #1586eb;
  align-items: center;
}

.template-currently-saving-spinner {
  margin-right: 5px;
}

.token-templates-section-header {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  font-weight: bold;
}

.token-templates-pagination-row {
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.token-templates-delete-modal {
  margin-top: -93px;
  background: rgba(0, 0, 0, 0.3);
}

.token-templates-delete-modal .modal-content {
  padding: 10px;
}

.tt-delete-modal-button-row {
  justify-content: center;
  margin-top: 10px;
}

.ap-confirm-delete-button {
  margin-right: 10px;
  background: fireBrick;
  border: 1px solid fireBrick;
}

.ap-confirm-delete-button:hover {
  background: darkRed;
  border: 1px solid darkRed;
}

.tt-delete-modal-text-row {
  justify-content: center;
}

.individual-template-param#published {
  color: green;
}

.individual-template-param#not-published {
  color: red;
}

.token-template-create-row {
  justify-content: flex-end;
  margin-top: 18px;
  align-items: center;
}

.token-template-create-row .individual-form-icon {
  margin-left: 8px;
}

.token-template-create-row button {
  margin-left: 8px;
}

.configure-preview-row {
  /* justify-content: center; */
  margin-top: 8px;
}

.configure-preview-row .form-check:not(:last-child) {
  margin-bottom: 8px;
}

.asset-profiles-container {
  margin: 0;
  padding: 0;
  /* background: rgba(0, 0, 0, 0.3); */
  margin-bottom: 40px;
  padding: 0 15px;
}

.asset-profiles-header {
  justify-content: center;
  font-size: 22px;
  /* margin-bottom: 20px; */
}

.asset-profiles-form {
  margin: 0;
  padding: 0;
}

.asset-profiles-form-header-row {
  margin: 0;
  margin-bottom: 10px;
  align-items: center;
}

.asset-profiles-form-saving-sections {
  margin: 0;
  align-items: center;
}

.asset-profiles-section {
  background: rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 0;
  padding: 15px;
}

.asset-profiles-section-name {
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}

.asset-profiles-required-info-row {
  justify-content: flex-end;
  display: flex;
  font-weight: normal;
  font-style: italic;
}

.asset-profiles-section:not(:last-child) {
  margin-bottom: 15px;
}

.asset-profiles-social-dropdown {
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

.asset-profiles-link-text {
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.asset-profiles-form-control-sub-text {
  font-size: 13px;
  font-style: italic;
}

.asset-profile-subfield {
  margin: 0;
  padding: 0;
}

.asset-profile-subfield:not(:last-child) {
  margin-bottom: 10px;
}

.asset-profile-image-uploader-row {
  margin: 0;
  justify-content: center;
}

.asset-profile-image-uploader .fileContainer {
}

.asset-profile-uploaded-image-container {
  position: relative;
  width: 200px;
  min-height: 173px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.asset-profile-uploaded-image {
  width: 200px;
  object-fit: cover;
  justify-content: center;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.asset-profile-remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border-radius: 100px;
  color: cornflowerblue;
}

.asset-profile-remove-icon:hover {
  cursor: pointer;
  color: white;
  background: cornflowerblue;
}

.asset-profile-loading-container {
  height: 100%;
  width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
}

.asset-profile-document-uploader-row {
  margin: 0;
  width: 100%;
}

.asset-profile-form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.asset-profile-form-control::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.asset-profile-typeahead::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.asset-profile-typeahead::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.asset-profile-document-uploader-row {
  align-items: center;
}

.asset-profile-document-name {
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
  align-items: center;
}

.asset-profile-document-clear-icon {
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
}

.asset-profile-document-clear-icon:hover {
  background: white;
  color: black;
  cursor: pointer;
}

.asset-profile-datepicker .react-date-picker__wrapper {
  background: rgba(0, 0, 0, 0.1);
}

.asset-profile-datepicker .react-date-picker__inputGroup input {
  color: rgba(0, 0, 0, 0.7);
}

.asset-profile-create-new-modal {
  background: rgba(0, 0, 0, 0.4);
  margin-top: -93px;
}

.asset-profiles-asset-type {
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 20px;
}

.asset-profiles-asset-type .form-control {
  color: black !important;
}

.asset-profile-create-asset-button {
  margin-top: 10px;
}

.asset-profiles-list {
  justify-content: center;
  margin-top: 20px;
}

.asset-profile-individual-profile-header {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  font-weight: bold;
  padding: 0 5px;
}

.asset-profile-individual-profile {
  margin: 0;
  padding: 0;
  align-items: center;
  padding: 5px;
}

.asset-profile-individual-profile .col#published {
  color: green;
}

.asset-profile-individual-profile .col#not-published {
  color: red;
}

.asset-profile-individual-profile:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.asset-profile-back-to-templates {
  margin-bottom: 10px;
}

.asset-profile-back-to-templates:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #1586eb;
}

.asset-profiles-create-button-header {
  justify-content: center;
  margin-bottom: 10px;
}

.asset-profile-search-column {
  margin: 0;
  padding: 0;
}

.asset-profile-search-column:not(:last-child) {
  margin-right: 10px;
}

.asset-profile-search-column .form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.asset-profile-search-column .form-control::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.common-image-th {
  width: 100px;
}

.common-table-image {
  max-width: 100px;
  max-height: 30px;
}

.individual-form-icon.up-button {
  margin: 0 !important;
}

.direction-button {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 100px;
  padding: 2px;
}

.direction-button:hover {
  background: rgba(255, 255, 255, 0.4);
}

.direction-button-container {
  margin-left: 8px;
  width: 40px;
  display: flex;
  justify-content: flex-end;
}

.dropdown-checkbox {
  margin-left: 8px;
}

.link-row {
  width: 100%;
}

.disabled-datepicker {
  background-color: #e9ecef !important;
  opacity: 1 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.offering-document-container {
  width: 100%;
}

.offering-document-clear-button {
  margin-left: 16px;
  color: #858796;
}

.offering-document-clear-button:hover {
  color: black;
  cursor: pointer;
}

.create-offering-div {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 300px;
}

.admin-datepicker {
  color: #858796 !important;
  height: 31px !important;
  font-size: 13px !important;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 6px 12px;
  width: 100% !important;
}

.admin-datepicker::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.admin-datepicker::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.admin-datepicker:disabled {
  background-color: #e9ecef;
}

.registration-form-control-column .datepicker-row .react-datepicker-wrapper {
  width: 100%;
}

.offering-details-row {
  margin-bottom: 8px;
}

.offering-details-row .react-datepicker-wrapper {
  width: 100%;
}

.offering-details-col {
  margin-right: 8px;
}

.ap-multifield-subfield-row {
  padding-right: 16px;
  position: relative;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 16px;
  /* background: red; */
}

.floating-icon {
  position: absolute;
  top: 8px;
  right: -4px;
}

.editing-role-form-row {
  justify-content: space-between;
}

.tt-subfield-configure-button-row {
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
}
.crucial-width {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.ap-multifield-subfields-container {
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin-top: 4px;
}

.ap-multifield-subfields-container .registration-form-control-column {
  padding: 0;
  margin-bottom: 4px;
}

.tt-subfield-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.tt-subfield-row .submission-form-control {
  width: 90% !important;
}

.tt-subfield-button-row {
  margin: 8px 0;
  justify-content: flex-end;
}

.tt-subfield-options-row {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.tt-subfield-options-row .submission-form-control {
  width: 45% !important;
}

.tt-subfield-configure-accordion {
  margin-bottom: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  width: 93.5%;
  position: relative;
}

.tt-subfield-configure-accordion .card-header {
  padding: 4px;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.tt-subfield-configure-accordion .card-header:hover {
  color: black;
}

.tt-subfield-configure-accordion .content {
  padding: 4px;
  background: rgba(0, 0, 0, 0.03);
}

.tt-subfield-add-button {
  font-size: 12px;
  padding: 0 4px;
  position: absolute;
  top: 4px;
  right: 4px;
}

.tt-subfield-dropdown-formControl {
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.tt-subfield-dropdown-formControl .submission-form-control {
  width: 95%;
}

.tt-subfield-dropdown-formControl .individual-form-icon-disabled {
  margin: 0;
}

.no-dropdown-options-added {
  text-align: center;
  font-size: 12px;
}

.ap-formcontrol-row {
  -ms-align-items: center;
  align-items: center;
  display: flex;
}

.admin-content-pane-container {
  background: #F8F9FC;
  padding: 0;
}

.forms-table-box {
  background: white;
  font-size: 13px;
  padding: 20px;
  position: relative;
}

.forms-header {
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
  color: #858796;
  margin-bottom: 24px;
  align-items: center;
}

.admin-table {
  background: white;
  font-size: 13px;
  padding: 20px;
  color: #858796;
}

.submissions-search-bar {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: #858796;
}

.submissions-search-bar label {
  margin: 0;
}

.submissions-search-bar .col:not(:last-child) {
  margin-right: 8px;
}

.submission-form-control {
  color: #858796 !important;
}

.submission-form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.submission-form-control::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.submissions-reset-queries-button {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #858796;
}

.submissions-reset-queries-button:hover {
  color: black;
  cursor: pointer;
}

.news-container {
  padding: 0;
}

.news-menu-section {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  padding: 8px 16px;
  width: 100%;
}

.news-feed-section {
  padding: 8px 16px;
  margin-left: 400px;
}

.news-feed-loading {
  width: 100%;
  height: 300px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #858796 !important;
}

.news-search-row {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.news-search-control-container {
  display: flex;
  width: 100%;
  max-width: 900px;
  justify-content: space-between !important;
}

.news-search-datepicker-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
}

.news-search-datepicker-container .news-search-form-control:not(:last-child) {
  margin-right: 8px;
}

.news-search-form-control {
  width: 200px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.13);
  color: #858796 !important;
  padding-left: 8px;
  font-size: 14px;
  border-radius: 3px;
}

.news-search-form-control .react-date-picker {
  color: #858796 !important;
}

.news-search-form-control .react-date-picker .react-date-picker__button svg {
  stroke: #858796 !important;
}

.news-search-form-control .react-date-picker .react-date-picker__button svg:hover {
  stroke: black !important;
}

.news-search-form-control .react-date-picker__wrapper {
  border: none;
}

.news-search-form-control .react-date-picker__wrapper .react-date-picker__clear-button {
  stroke: #858796 !important;
}

.news-nav-row {
  padding: 0 128px;
  align-items: center;
}

.news-nav-link {
  color: #858796;
}

.news-nav-link:hover {
  color: black;
}

.news-nav-switch {
  color: #858796;
}

.news-nav-switch .toggle-cards-button {
  font-size: 20px;
  margin-right: 8px;
}

.news-nav-switch .toggle-cards-button:hover {
  cursor: pointer;
  color: black;
}

.news-nav-switch .toggle-cards-button#active {
  color: black;
}

.compact-news-info-container {
  padding: 8px;
  margin: 32px 16px 32px 400px;
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.contents-row {
  width: 100%;
}

.news-feed-row {
  background: orange;
}

.news-card {
  width: 200px;
  height: 300px;
  margin-right: 8px;
  color: #858796;
  margin-bottom: 8px;
  border: 1px solid transparent;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.news-card img {
  height: 125px;
}

.news-card .card-title {
  font-size: 14px;
  padding: 8px;
}

.news-card a {
  color: #858796;
}

.news-card a:hover {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}

.news-card-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  font-style: italic;
}

.news-card-footer svg {
  font-size: 22px;
}

.news-card-footer:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}

.news-trending-topics {
  background: white;
  position: absolute;
  left: 16px;
  min-height: 300px;
  width: 250px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  padding: 8px;
  color: #858796;
}

.news-trending-topics h5 {
  font-size: 18px;
}

.news-trending-topic {
  justify-content: space-between;
  display: flex;
  padding: 4px;
  font-size: 14px;

}

.news-trending-topic:hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: default;
}

.news-trending-topic .trending-name:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}

.news-trending-topic:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.news-no-trending-topics {
  font-size: 14px;
  text-align: center;
  height: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.news-menu-row {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-top: 8px;
  justify-content: center;
}

.news-menu-control-container {
  display: flex;
  max-width: 1000px;
  width: 100%;
  color: #858796;
  font-size: 14px;
}

.news-menu-control-container .news-menu-item {
  margin-right: 16px;
}

.news-menu-control-container .news-menu-item:hover {
  color: black;
  cursor: pointer;
}

.news-menu-control-container .news-menu-item.icon {
  font-size: 18px;
}

.news-menu-control-container .news-menu-item#active {
  color: black;
}

.news-list-container {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  padding: 8px;
  color: #858796;
  font-size: 14px;
  width: 100%;
  max-width: 1200px;
}

.news-list-item {
  justify-content: space-between;
}

.news-list-item:not(:last-child) {
  padding-bottom: 8px;
}

.news-item-first-half {
  display: flex;
}

.news-item-last-half {
  display: flex;
}

.news-list-item:hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: default;
}

.news-list-item .date-col {
  font-size: 10px;
  width: 80px;
}

.news-list-item .title-col {
  overflow-x: hidden;
}

.news-list-item .title-col a {
  color: #858796;
}

.news-list-item .title-col a:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.news-list-item .publisher-col {
  font-style: italic;
  margin-right: 8px;
  text-align: right;
  font-size: 10px;
}

.common-icon {
  color: #858796;
}

.common-icon:hover {
  cursor: pointer;
  color: black;
}

.pagination-row {
  justify-content: center;
  display: flex;
}

.common-pagination {
  width: 100%;
  justify-content: center;
}

.common-pagination .page-item a {
  border: 1px solid transparent;
  color: #36b9cc;
  font-size: 16px;
}

.common-pagination .page-item a:hover {
  color: black !important;
}

.common-pagination .page-item.active span {
  /* background: #36b9cc; */
  /* border: 1px solid #36b9cc; */
  background: transparent;
  color: #36b9cc;
  font-size: 16px;
  text-decoration: underline;
}

.common-pagination .page-item.active span:hover {
  cursor: pointer;
  color: black;
}

.common-modal {
  color: #858796;
  background: rgba(0, 0, 0, 0.3);
}

.common-modal .modal-content {
  /* padding: 0 10px; */
  border: none;
  border-radius: 2px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.common-modal .modal-body {
  padding: 1px;
}

.common-modal .app-spinner {
  justify-content: center;
}

.news-modal .modal-body {
  width: 500px;
}

.news-modal-image {
  width: 100%;
  max-height: 300px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.news-modal-content-container {
  padding: 8px;
}

.news-modal-title {
  color: #858796;
  font-weight: bold;
  /* margin-bottom: 16px; */
}

.news-modal-title:hover {
  color: black;
}

.news-modal-pub-date {
  display: flex;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  justify-content: space-between;
  color: #a8aaba;
  align-items: center;
  font-size: 12px;
}

.news-modal-publisher {
  font-weight: bold;
}

.news-modal-date {
  font-style: italic;
  margin-left: 4px;
}

.news-modal-content {
  margin-top: 16px;
  font-size: 14px;
  padding-bottom: 32px;
}

.news-modal-read-more {
  position: absolute;
  bottom: 0;
  right: 0;
  font-style: italic;
  color: #a8aaba;
  font-size: 14px;
}

.news-modal-read-more svg {
  font-size: 22px;
}

.news-modal-read-more:hover {
  color: black;
}

.reset-params-icon {
  position: absolute;
  right: 8px;
  color: #858796;
}

.reset-params-icon:hover {
  color: black;
  cursor: pointer;
}

.table-navlink {
  padding: 0;
  color: #858796;
  margin: 0;
}

.terms-of-service {
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
  /* width: 100vw; */
  height: 100%;
  color: white !important;
  font-weight: normal !important;
}

.terms-of-service h1 {
  color: white;
}

.terms-of-service h4 {
  color: white;
}

.terms-of-service h6 {
  color: white !important;
}

.mainbody {
  color: white;
}

.termsb{
    margin-top: 70px;
    color: white;
    }

.termsb h1{

    text-align:center;
    height: 70px;
    color: white !important;
}
.termsb p{
    font-size: 16px;
    font-weight:bold;
    text-align: justify;
    color: white;
}

.mainbody{

    height: auto;
    float: left;
    overflow: hidden;
    margin-top: 10px;
}
.mainbody h6{
    font-size: 18px;
    font-weight:bold;
}

.leftsite{

    width: 400px;
    overflow: hidden;
    float: left;
}
.rightsite{
    width: 700px;
    float: right;
    overflow: hidden;
    text-align: justify;
}
.ol{
padding: 20px;

}

.privacyb{
    margin-top: 70px;
    }

.privacyb h1{
    text-align:center;
    height: 70px;
}
.privacyb p{
    font-size: 18px;
    font-weight:bold;
    text-align: justify;
}

.mainbody{

    height: auto;
    float: left;
    overflow: hidden;
    margin-top: 10px;
}
.mainbody h6{
    font-size: 18px;
    font-weight:bold;
}

.leftsite{

    width: 400px;
    overflow: hidden;
    float: left;
}
.rightsite{
    width: 700px;
    float: right;
    overflow: hidden;
    text-align: justify;
}
.ol{
padding: 20px;

}
.pfooter{

    background-color: #073966 ;
    text-align:center;
    clear: both;
    margin-bottom:25px;
    padding: 5px;
}
.pfooter a{
    color:#3498DB;
}

body::-webkit-scrollbar {
  width: 10px;
  background: rgba(0, 0, 0, 0.5);
}

body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.research-container {
  max-width: 100%;
  height: 100%;
  padding: 0;
}

.research-container .col {
  padding: 0;
}

.research-form-top-bar-container {
  /* position: absolute; */
  /* width: 100%; */
  /* left: 0; */
  /* top: 90px; */
}

.research-form-top-bar-button {

}

.research-form-top-bar-row {
  width: 100%;
  margin: 0;
  /* background: red; */
}

.research-form-saving-col {
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.research-form-buttons-col {
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  display: flex;
}

.research-form-buttons-col#showing-preview {
}

.research-form-top-bar-row .btn-primary {
  margin-right: 10px;
  width: 80px;
  height: 24px;
  padding: 0;
  background: white;
  color: #0182E7;
  border: 1px solid #0182E7;
  border-radius: 2px;
}

.research-form-top-bar-row .btn-primary:hover {
  color: white;
  background: #0182E7;
}

.research-form-top-bar-row .col {
  /* justify-content: center;
  align-items: center;
  display: flex; */
}

.research-top-bar-button {
  height: 30px;
  margin-top: 5px;
  padding: 2px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.research-top-bar-button#preview-button {
  background: transparent;
}

.research-top-bar-button#preview-button:hover {
  background: #1588ee;
}

.research-save-icon {
  margin-right: 5px;
}

.research-form-container {
  padding-top: 90px;
  height: inherit;
}

.research-form-top-row {
  margin: 0;
  padding: 0;
  height: inherit;
}

.research-form-drafts-section {
  background: rgba(0, 0, 0, 0.2);
  /* max-height: 75%; */
  overflow-y: hidden;
}

.user-drafts-section-top-row {
  width: 100%;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.user-drafts-section-top-row .col {
  margin: 0;
  padding: 0;
  justify-content: center;
  display: flex;
}

.user-drafts-section-top-row h4 {
  margin: 0;
  font-weight: bold;
  font-size: 20px;
}

.drafts-create-icon {
  transition: color 0.13s, transform 1s;
  font-size: 14px;
}

.drafts-create-icon:hover {
  color: #0182E7;
  cursor: pointer;
  transform: rotate(360deg);
}

.user-drafts-section-bottom-row {
  width: 100%;
  height: 100%;
  /* background: red; */
}

.user-drafts-col {
  max-height: 50%;
  overflow-y: auto;
}

.user-drafts-col::-webkit-scrollbar {
    width: 5px;
    background: transparent;
}

.user-drafts-col::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
}

.user-drafts-col::-webkit-scrollbar-thumb:hover {
  background: #0182E7;
}

.single-draft {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0px 1px;
  padding: 0px 5px;
  transition: background 0.2s, box-shadow 0.2s;
}

.single-draft:hover {
  background: #0182E7;
  color: white !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.single-draft .draft-col {
  padding: 0;
}

.single-draft-title {
  transition: color 0.2s;
}

.single-draft-title:hover {
  cursor: pointer;
  /* color: orange; */
}

.single-draft-selected {
  padding: 0 5px;
  background: #0182E7;
  color: white !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.single-draft .buttons-col {
  padding: 0;
}

.single-draft .buttons-col .row {
  width: 100%;
  margin: 0;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.single-draft-button {
  margin-left: 10px;
  transition: color 0.2s;
}

.single-draft-button:hover {
  /* color: #0182E7; */
  cursor: pointer;
}

.single-draft-selected {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  /* background: rgba(0, 0, 0, 0.4); */
  /* background: red; */
  margin: 0px 1px;
  /* font-weight: bold; */
}

.single-draft-selected:hover, .single-draft:hover {
  cursor: pointer;
}

.single-draft-selected .draft-col {
  padding: 0;
}

.single-draft-selected .draft-col .single-draft-title {
}

.single-draft-selected .buttons-col {
  padding: 0;
}

.single-draft-selected .buttons-col .row {
  width: 100%;
  margin: 0;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.single-draft-button {
  margin-left: 10px;
  transition: color 0.2s;
}

.single-draft-button:hover {
  cursor: pointer;
}

.research-form-rte-section {
  padding: 0 !important;
  /* background: yellow; */
}

.research-form-criteria-section {
  background: rgba(0, 0, 0, 0.2);
}


.rte-content-container {

}

.rte-content-container .col {
  height: 100%;
  width: 100%;
  padding-top: 40px;
}

.rte-content-container .form-label-container {
   font-weight: bold;
   border-right: 1px solid darkgrey;
   width: 8% !important;
   padding: 0 !important;
   margin: 0 !important;
   padding-right: 10px;
   display: inline-flex;
}

.form-label-container .form-label {
    text-align: right !important;
    display: inline-flex !important;
    align-items: right;
    width: 100% ;
}

.rte-content-container .form-row:last-of-type {
    margin-bottom: 20px;
}

.rte-content-container .form-control {
}

.rte-content-title {
    margin: 0;
    padding: 0;
    font-weight: 400;
    border: none;
    color: black;
    line-height: 48px;
    font-size: 40px;
    height: inherit;
}

.rte-content-title::-webkit-input-placeholder {
    font-size: 40px;
    font-weight: normal !important;
    color: darkgrey;
}

.rte-content-title::placeholder {
    font-size: 40px;
    font-weight: normal !important;
    color: darkgrey;
}

.rte-content-title:focus, .rte-content-summary:focus {
    box-shadow: none !important;
}

.rte-content-summary {
    margin: 0;
    border: none;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 300;
    line-height: 32px;
    font-size: 24px;
    height: inherit;
}

.rte-content-summary::-webkit-input-placeholder {
    font-size: 32px;
    color: darkgrey;
}

.rte-content-summary::placeholder {
    font-size: 32px;
    color: darkgrey;
}

.rte-quill {
  display: inline;
  border: none;
  background: none !important;
  margin: 0 !important;
  width: 100% !important;
  height: 50rem;
}

.ql-editor {
    /* padding: 0 !important; */
    border: none !important;
    height: 100%;
    font-size: 16px;
    /* border-radius: 0 !important; */
    background: white;
}

.ql-editor::-webkit-scrollbar {
  width: 0px;
}

.ql-editor::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.13);
  border-radius: 25px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.ql-container {
  padding: 0 !important;
  margin: 0;
}

.ql-blank {
  background: white;
}

.rte-quill-row {
  padding: 0;
  margin: 0;
  width: 100% !important;
}

.rte-container {
  margin: 0;
  padding: 0;
  justify-content: center;
  display: flex;
}

.rte-container form {
  width: 100%;
}

.rte-title-row {
  width: 100%;
  margin: 0;
  padding: 0;
  padding: 0 10px;
}

.rte-title-row h1 {
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
}

.rte-summary-row {
  width: 100%;
  padding: 0;
  margin: 0;
  padding: 0 10px;
}

.rte-summary-row h2 {
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
}

.rte-summary-row .form-control, .rte-title-row .form-control {
  margin: 0;
  padding: 0;
}

.preview-body-row .rte-quill {
  width: 100%;
  border: none !important;
}

.rte-quill .ql-toolbar {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.rte-quill .ql-container {
  border: none !important;
  height: 90% !important;
  width: inherit !important;
  padding-left: 10px;
}

.rte-container .preview-title-row h1 {
  width: 100%;
}

.rte-container .preview-summary-row h2 {
  width: 100%;
  margin: 0;
  padding: 0;
}

.rte-container .preview-summary-row .form-control {
  padding: 0;
  margin: 0;
}

.rte-container .preview-body-row p {
  width: 100%;
  padding: 0;
}

.research-form-criteria-section {
    background: rgba(0, 0, 0, 0.20);
    display: block;
    z-index: 2;
}

.research-form-criteria .form-control {
    background: #0182E7;
    color: white;
    border-color: #0182E7;
    font-size: 14px;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.research-form-criteria .form-control::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.20);
}

.research-form-criteria .form-control::placeholder {
    color: rgba(0, 0, 0, 0.20);
}

.research-form-criteria .form-row {
    width: 100%;
    padding: inherit;
}

.research-label {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.research-label-italic {
  font-size: 14px;
  /* font-weight: bold; */
  margin: 0;
  padding: 0;
  font-style: italic;
  color: grey;
}

.research-form-criteria .research-tags {
    /* height: 116px; */
}

.research-content-label {
    display: inline-block;
    text-align: right;
}

.react-tagsinput {
  /* overflow: hidden; */
  padding-left: 5px;
  padding-top: 5px;
  background: white;
  border-radius: 2px;
  /* height: 38px; */
}

.react-tagsinput-tag {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
  border-radius: 2px;
  color: white;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0 4px;
}

.react-tagsinput .react-autosuggest__suggestions-container {
}

.react-tagsinput .react-autosuggest__suggestions-container ul {
  list-style-type: none;
  padding: 0;
}

.react-tagsinput .react-autosuggest__suggestions-container li {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
  border-radius: 2px;
  color: white;
  display: inline-block;
  padding: 5px;
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput .react-autosuggest__suggestions-container li::after {
  content: " +";
}

.react-tagsinput .react-autosuggest__suggestions-container li:hover {
  cursor: pointer;
}



.react-tagsinput-remove {
  cursor: pointer;
}

.react-tagsinput-tag a::before {
  content: " x";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  /* width: 80px; */
}

.research-form-tags-column {
}

.research-form-tags-container {
    height: inherit;
    /* max-height: 100px; */
    border: 1px solid #0182E7;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* overflow-y: scroll; */
}

.image-uploader {
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.article-image-uploader {
  display: none;
}

.uploaded-image-div {
  /* background: red; */
}

.no-article-image {
  /* background: orange; */
  height: 100px;
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.15s, color 0.15s, border 0.15s;
}

.no-article-image:hover {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0);
  color: white;
  cursor: pointer;
}

.uploaded-image-container {
  display: flex;
}

.uploaded-article-image {
  height: 100%;
  max-height: 200px;
  max-width: 200px;
  border-radius: 2px;
}

.md-cancel:hover {
  cursor: pointer;
}

.research-form-top-bar-buttons-row {
  padding: 0;
  margin: 0;
  width: 100vw;
  padding-bottom: 5px;
}

.research-form-top-bar-buttons-row#showing-preview {
  background: #062846;
}

.research-form-top-bar-buttons-row .col-sm-2 {
  padding: 0;
  margin: 0;
}

.research-form-top-bar-buttons-col {
  padding: 0;
  margin: 0;
  margin-right: 25px;
}

.no-document {

}

.saving-message {
  color: grey;
  font-style: italic;
}

.saved-message {
  color: grey;
}

.uploaded-report-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: cover;
  border-radius: 2px;
}

.publishing-article-modal {
  background: rgba(0, 0, 0, 0.5);
  padding-top: 15%;
}

.publishing-article-modal .modal-content {
  border: none;
  border-radius: 2px;
  text-align: center;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

.rte-no-drafts {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.rte-no-drafts .row {
  align-items: center;
  display: flex;
}

.rte-no-drafts-button {
  height: 60px;
  background: transparent;
  border: 1px solid #0182E7;
  color: #0182E7;
}

.rte-no-drafts-button:hover {
  cursor: pointer;
  color: white;
  border: 1px solid #0182E7;
  background: #0182E7;
}

.rte-no-drafts-add-icon {
  font-size: 20px;
}

.preview-title-row {
    margin: 0;
    margin-top: 10px;
    /* background: orange; */
}

.preview-title-row h1 {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.84);
    line-height: 48px;
    margin: 0;
    margin-bottom: 12px;
}

.preview-summary-row {
    margin: 0;
    /* background: red; */
}

.preview-summary-row h2 {
    margin: 0;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 300;
    line-height: 32px;
    font-size: 24px;
}

.preview-user-row {
    padding: 0 !important;
    margin: 0;
    margin-bottom: 40px;
    /* background: purple; */

}

.preview-user-image-col {
    padding: 0 !important;
}

.preview-user-image {
    height: 48px;
    width: 48px;
}

.preview-user-info-col {
    padding: 0 !important;
    margin-left: -15px;
}

.preview-name-row {
    height: 24px;
    font-size: 14px;
}

.preview-date-row {
    height: 24px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.preview-body-row {

    margin: 0;
}

.render-article-body-row {
  display: block !important;
}

.render-article-body-row p {
  margin-bottom: 0;
}


.render-article-body-row p:empty {
  margin: 16px;
  background: green;
}

#preview-article-container {
  margin: 0;
  height: 100%;
}

.render-article-name-row p {
  color: rgba(255 ,255, 255, 0.6);
}

.preview-article-row {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100%;
  background: red;
}



.chart-tooltip {
    height: 75px;
    width: 150px;
}

#color-block {
    height: 20px;
    width: 20px;
    border: 1px solid;
}

.bubble-chart-fragment {
    height: 600px;
    background: red;
}


/********************************MISC STYLING**********************************/
.sto-insights-page-container {
  /* background-color: #0d064d; */
  /* margin-top: 93px !important; */
  background: #F8F9FC;
  min-height: 100%;
}

.gradient-background {
    background: linear-gradient(
        135deg,
        rgba(215,215,215,1) 7%,
        rgba(249,249,249,1) 17%,
        rgba(255,255,255,1) 100%
    );
}

.tp-cover-background {
  background-image: url(/static/media/header-background-image.740e3547.png);
  background-repeat: no-repeat;
  background-color: #0d064d;
  background-size: 100%;
  height: 600px;
}

.pagination li {
    color: #0182E7;
    border: none;
    width: 35px !important;
    height: 35px !important;
    font-weight: bold;
    font-size: 15px;
    height: 45px;

}

.pagination li a {
    /* width: 0; */
    color: #0182E7;
}

.press-releases-section .pagination li.active, .news-list-section .pagination li.active {
    background-color: #0182E7 !important;
    border-radius: 5px;
    text-decoration: none;
    width: 35px;
    height: 35px;
}

.pagination li.active a {
    color: white !important;
}

.pagination li a {
    cursor: pointer;
}

.loading-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: auto;
    background-color: rgba(0, 0, 0, .0);
}

.token-alert {
    text-align: center;
    font-weight: bold;
    margin-top: 10%;
}

.upcoming-alert {
    text-align: center;
    font-weight: bold;
    margin-left: 30%;
    margin-top: 10%;
    color: white;
}

/*************************END OF MISC STYLING**********************************/


/*************************SEARCH TOKENS STYLING*******************************/
.search-tokens-container {
    background: transparent;
    height: 700px;
    margin-top: 90px;
}

.search-tokens-section {
    padding-top: 40px;
}

.search-tokens-section h1 {
  color: white;
}

.search-tokens-section .form-row {
    height: 31px;
    margin-bottom: 20px;
}

.search-tokens-section .form-control {
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border-color: #008fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 2px;
}

.search-tokens-section .form-control::-webkit-input-placeholder {
    color: white;
}

.search-tokens-section .form-control::placeholder {
    color: white;
}

.typeahead ul {
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: none;
}

.typeahead ul::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.typeahead ul::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 20px;
}


.typeahead .dropdown-item:hover {
    color: white;
    background: #3590FC;
}

.typeahead a {
    color: white;
    font-size: 14px;
}

.search-tokens-section .token-search-bar {
    background: rgba(0, 0, 0, 0.6);
    color: #008fff;
    height: 31px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: white;
    border-radius: 2px;
}

.search-tokens-section .token-search-bar::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.search-tokens-section .token-search-bar::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.search-tokens-section button {
    height: 31px;
    width: 31px;
    padding: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid #008fff;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 2px;
}

.search-tokens-section button:hover {
  border: 1px solid #008fff;
  background: #008fff;
  color: white;
}

.search-tokens-section button .search-icon {
    font-size: 18px;
}

.search-tokens-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.search-tokens-section h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

.tokens-row {
    justify-content: center;
}

/* Individual Token */
.token-card {
    width: 300px;
    height: 390px;
    margin: 10px 20px 0px 20px;
    display: block;
    overflow: hidden;
    background-color: #0d064d;
    /* background-image: linear-gradient(to top, rgb(13, 6, 77, 0.6), rgb(42, 25, 205, 0.9)); */
    background: linear-gradient(to top, rgb(13, 6, 77, 0.8) 10%, rgb(42, 25, 205, 1) 100%);
    border: 1px solid #008fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.token-header-row {
    padding-top: 10px;
}

.token-image {
    width: 75px;
    height: 75px;
    object-fit: cover;
}

.token-name {
    position: relative;
}

.token-name h4 {
    color: white;
}

.token-name a:hover {
  color: white;
}

.token-location {
    /* align-items: center; */
    color: rgba(255, 255, 255, 0.6);
}

.token-brief {
    font-size: 14px;
    padding: 20px 20px 0px 20px;
    color: rgba(255, 255, 255, 0.8);
}

.token-industry {
    padding-left: 20px;
}

.token-industry-tag {
  background: #260eff;
  background-image: linear-gradient(to top, #0d064d, #260eff);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 25px;
    padding: 0px 10px;
    color: white;
}

.pagination-row {
    margin-top: 40px;
    justify-content: center;
    width: 100%;
}

.pagination-column {
    display: flex;
    justify-content: center;
}
/**********************END OF SEARCH TOKENS STYLING****************************/


/*************************TOKEN INSIGHTS STYLING*******************************/
.token-insights-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.token-insights-container .container {
    /* background-color: #0d064d;
    background: linear-gradient(to bottom, #0d064d 50%, #4536cc 100%); */
    background: rgba(0, 0, 0, 0.2);
    /*backdrop-filter: blur(3px);*/
    max-width: none;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    /* width: 90vw; */
    color: white;
    /* border-radius: 5px; */
}

.token-insights-section {
    padding: 40px 0px 40px;
}

.charts-row {
    color: black;
}

.token-insights-col {
    /* padding: 0; */
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.token-insights-choropleth-col {
    /* margin-left: 10px; */
}

.token-insights-selector-row {
    justify-content: center;
    color: white;
}

.token-insights-selector-row div {
    margin-right: 20px;
    border: 1px solid white;
    width: 200px;
    text-align: center;
    height: 31px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.token-insights-selector-row div:hover {
    cursor: pointer;
}

.pie-chart-section {
    height: 300px;
    z-index: 1;
    width: 300px;
    margin-left: 25px;
    margin-bottom: 10px;
}

.bubble-chart-section {
    height: 300px;
    z-index: 1;
    width: 300px;
    margin-left: 25px;
    border-radius: 5px;
}

.choropleth-section {
    height: 600px;
    z-index: 1;
    padding: 0;
    width: 100%;
}

.choropleth {
    /* width: 100px; */
}

.tooltip-box-choropleth {
    /* right: 700px; */
    /* bottom: 0px; */
    position: absolute;
    border: 1px dotted white;
    width: 20px;
    height: 20px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
    border-radius: 5px;
    z-index: 2;
}

.tooltip-box-choropleth:hover {
    cursor: pointer;
    color: orange;
    border-color: orange;
}

.chart-description-tooltip {
    background-color: rgba(0, 0, 0, .4);
    color: white;
    width: 200px;
    border: none;
    border: 1px solid white;
    z-index: 0;
}

.chart-description-tooltip h3 {
    background: rgba(0, 0, 0, .0);
    color: white;
}

.chart-description-tooltip .popover-body {
    color: white;
}

.token-insights-section h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
}

@media only screen and (max-width: 1900px) {
    .token-insights-container .container {
        width: 100vw;
    }
}
/**********************END OF TOKEN INSIGHTS STYLING***************************/


/*************************UPCOMING STYLING*************************************/
.upcoming-events-container {
  background-color: #0d064d;
  background-image: url(/static/media/timeline-background-copy.c01d05eb.png);
  background-size:  100% 650px;
  background-repeat: no-repeat;
    height: 650px;
    /* margin-bottom: 40px; */
}

.upcoming-events-container .container {
    width: 100%;
}

.upcoming-events-section {
    padding: 40px 0px;
    position: absolute;
    left: 0;
    right: 0;
}

.upcoming-events-column {
    min-height: 480px;
    background: rgba(0, 0, 0, 0.4);
    padding: 20px;
}

.upcoming-events-anchor {
    /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps  the user to exactly the right position
    when they navigate using the sidenav.
    */
    position: absolute;
    padding-top: 130px;
    margin-top: -130px;
}

.upcoming-events-section h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
    color: white;
}

.upcoming-sto-subheader {
    font-weight: bold;
    color: white;
    margin-bottom: 20px

}

.react-calendar {
    border: none;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px;
    margin-top: 40px;
}

.calendar-column {
    padding-left: 8vw;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.react-calendar__tile--active {
    background: #0182E7;
}

.upcoming-events-top-row {
    margin-bottom: -60px;
}

.upcoming-events-row {
    padding-left: 20px;
}

.upcoming-event {
    border-top: 1px solid white;
    /* width: 90%; */
    width: 100%;
    font-size: 14px;
    padding: 5px 0px;
    /* height: 110px; */
}

.event-image-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.event-image-figure {
    width: 80px;
    height: 80px;
    margin: 0;
}

.event-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.event-name-column {
    text-align: left;

}

.event-name-column h5 {
    color: white;
}

.event-name-column a:hover {
  text-decoration: underline;
  color: white;
}

.event-date-column {
    text-align: center;
}

.event-brief-column {
  color: white;
}

.event-date-column {
  color: rgba(255, 255, 255, 0.8);
}
/*************************END OF UPCOMING STYLING******************************/


/**************************PRESS RELEASES STYLING******************************/
.press-releases-container {
  background-image: linear-gradient(to bottom, #0d064d 30%, #2310d1 100%);
  padding: 40px 0;
}

.press-releases-container .container-fluid {
  height: 960px;
}

.press-releases-section h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 35px;
    color: white;
}

.press-release-header {
  justify-content: center;
  color: white;
  margin-bottom: 20px;
}

.press-release {
    border-top: 1px solid #1586eb;
    height: 120px;
    align-items: center;
}

.press-release-image {
    width: 200px;
    height: 100px;
    object-fit: fill;
}

.press-release-details p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.press-release h5 {
    font-weight: bold;
    font-size: 18px;
    color: white;
}

.press-release a:hover {
  color: white;
}
/**************************END OF PRESS RELEASES STYLING***********************/


/**************************SIDENAV STYLING*************************************/
.sidenav-container-token-profile {
    z-index: 100000;
    display: table;
    right: 0px;
    position: fixed;
    font-weight: normal;
    margin-right: 5px;
    top: 50%;
}

.sidenav-container-token-profile .sidenav-section {
    transition-duration: 0.3s;
    display: table-cell;
    vertical-align: middle;
    margin: 0px;
    color: black;
    font-size: 10pt;
    text-align: right;
}

.sidenav-container-token-profile .sidenav-icon {
    margin: 0px 0px 0px 10px;
    background: #224DB7;
    display: inline-block;
    border-radius: 50%;
    height: 12px;
    width: 12px;
}

.sidenav-container-token-profile .sidenav-text {
    font-size: 9pt;
}

.sidenav-container-token-profile .navigation-button {
    margin-bottom: 5px;
}

.sidenav-container-token-profile .navigation-button:hover {
    transition: color 1.5s visibility 1s;
    background: #0B0347;
    cursor: default;
    color: white;
}
/**************************END OF SIDENAV STYLING******************************/

.market-overview-container {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,0.4) 0%, rgba(9,9,121,0.4) 8%, rgba(0,212,255,0.4) 100%);
}

.market-overview-header {
  justify-content: center;
  color: white;
}

.market-overview-content {
  justify-content: center;
  color: white;
}

.assets-by-jurisdiction-column {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assets-by-jurisdiction {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 200px;
  border: 1px solid white;
  border-radius: 2px;
}

.abj-header {
  font-weight: bold;
  border-bottom: 1px solid white;
  width: 100%;
  padding: 10px;
}

.abj-body {
  padding: 10px;
}

.search-assets-container {
  padding: 20px;
  color: #858796;
}

.search-assets-content {
  padding: 16px;
  background: white;
  color: #858796 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.search-assets-query-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.search-assets-query-row svg {
  width: 10%;
  font-size: 60px;
}

.search-assets-query-row .form-control {
  background: transparent;
  border-radius: 0;
  border: none;
  width: 90%;
  font-size: 26px;
  height: 100%;
}

.search-assets-query-row .form-control::-webkit-input-placeholder {
}

.search-assets-query-row .form-control::placeholder {
}

.search-assets-form-row {
}

.search-assets-form-row .form-control {
}

.no-assets-found {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
}

.asset-type-table-image {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  object-fit: contain;
}

.asset-profiles-table {
  border-radius: 2px;
  color: #858796;
}

.asset-profiles-table th {
  border: none;
}

.asset-profiles-table thead {
}

.asset-profiles-table thead th {
  padding: 10px;
}

.asset-profiles-table tbody th {
  padding: 10px;
}

.th-section {
  text-align: right;
}

.asset-data-row {

}

.asset-data-row {
  align-items: center;
}

.asset-data-row:hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.th-number {
  text-align: center;
  width: 40px;
}

.th-name {
  width: 20%;
}

.th-brief {
  width: 40%;
}

.th-assetType {
  width: 20%;
}

.th-section {
  /* width: 100%; */
}

.filters-for-asset-types-row {
  /* justify-content: space-evenly; */
  /* padding: 10px; */
  padding: 8px 0;
}

.individual-filter:not(:last-child) {
  margin-right: 20px;
}

.bar-chart-section {
  width: 90%;
  min-height: 600px;
}

.bar-chart-container {
  background: rgb(2,0,36);
  background: linear-gradient(rgba(2,0,36,0.4) 0%, rgba(9,9,121,0.4) 8%, rgba(0,212,255,0.4) 100%);
}

.assets-container {
  padding-top: 40px;
}

.assets-header {
  color: white;
  justify-content: center;
}

.assets-row {
  justify-content: center;
}

.filter-container {
  font-size: 14px;
  margin-right: 16px;
}

.rendered-asset-filter {
  border-radius: 0 !important;
  width: 200px;
  text-align: center;
  color: #858796;
  align-items: center;
}

.rendered-asset-filter:focus {
  color: #858796;
}

.location-filter .rbt-input-main {
  border-radius: 0 !important;
  width: 200px;
  color: #858796 !important;
}

.location-filter .rbt-menu.dropdown-menu {

}

.rendered-asset-filter.location-filter:focus {
  color: #858796;
}

.loading-bar-row {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  justify-content: center;
}

.loading-spinner {
  color: #858796;
  padding: 60px;
  margin-top: 60px;
}

.asset-loading-row {
  position: absolute;
  left: 50%;
  top: 50%;
}

.asset-data-navlink {
  color: white;
}

.asset-data-navlink:hover {

}

.link-to-search-assets-row {
  margin-top: 20px;
  justify-content: center;
}

.link-to-search-assets {
  color: white;
  font-size: 22px;
}

.link-to-search-assets:hover {
  color: white;
}

.chart-tooltip {
  color: black;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.choropleth-tooltip {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 2px;
}

.responsive-bar-container {
  height: 100%;
  width: 80%;
}

.search-assets-select {
  /* background: #136dbd; */
  /* color: rgba(255, 255, 255, 0.7); */
  color: #858796 !important;
  align-items: center;
  border-radius: 0 !important;
  /* position: relative; */
}

.search-assets-select:hover {
  cursor: pointer;
}

.light-chart-row {
  justify-content: center;
}

.light-chart-info-row {
  color: #858796;
  padding: 20px;
  margin-bottom: 20px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.light-chart-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/****************************** MISC STYLING **********************************/
.utdp-container {
    background: transparent;
    width: 100%;
    min-height: 100%;
    height: 100%;
}

.utdp-loading-container {
    height: 100%;
    padding-top: 100px;
    max-width: 100%;
    min-width: 100%;
}
/****************************** END OF MISC STYLING ***************************/


/****************************** UTDP HEADER STYLING ***************************/
.utdp-header-container {
    /* width: 100% !important; */
    margin-bottom: 20px;
}

.utdp-header-row{
    margin-right:5px;
    margin-left:5px;
}
.utdp-header-column-a {
  background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
    padding: 15px;
}

.utdp-header-image-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.utdp-token-image-container {
  justify-content: center;
  display: flex;
  align-items: center;
  /* width: 300px; */
}

.utdp-token-image {
  width: 100%;
  max-height: 100px;
  /* height: 90%; */
  object-fit: contain;
}

.utdp-header-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.utdp-header-name h2 {
  color: #858796;
}

.utdp-header-industry {
}

.utdp-header-location {
    color: #858796;
    align-items: center;
}

.location-icon {
    margin-right: 5px;
}

.utdp-header-column-b {
    background: rgba(0, 0, 0, 0.1);
}

.utdp-header-button-row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
    font-size: 14px;
}

.utdp-header-button {
    width: 180px;
    font-size: 14px;
    border-radius: 25px;
    margin-right: 40px;
}
/*********************** END OF UTDP HEADER STYLING ***************************/


/****************************** UTDP DETAILS STYLING **************************/
.utdp-details-header-text {
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    text-align: center;
    margin-bottom: 15px;
    color: #858796;
}
/**************************** END OF UTDP DETAILS STYLING *********************/


/************************ UTDP DESCRIPTION STYLING ****************************/
.utdp-description-container {
    width: inherit;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #858796;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-description-section {
    width: 100%;
}

.utdp-description-text span {
  background: transparent !important;
  color: white !important;
  font-size: 16px !important;
}

.utdp-description-header {
    font-weight: bold;
    font-size: 14px;
}

.utdp-description-text {
    font-size: 16px;
    color: #858796;
}

.utdp-description-container#video {
  justify-content: center;
  display: flex;
  align-items: center;
}
/*********************** END OF UTDP DESCRIPTION STYLING **********************/


/**************************** UTDP TEAM STYLING *******************************/
.utdp-team-container {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #858796;
    width: inherit;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-team-section {
    width: inherit;
}

.utdp-individual-team-member {
    border-bottom: 1pt solid #1588ee;
    width: inherit;
    font-size: 14px;
    padding-left: 40px;
}

.utdp-team-member-name {
    font-weight: bold;
    color: #858796;
}

.utdp-team-member-name:hover {
    color: white !important;
}
/*************************** END OF UTDP TEAM STYLING *************************/


/**************************** UTDP SOCIAL MEDIA STYLING ***********************/
.utdp-social-media-container {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #858796;
    width: inherit;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-social-media-section {
    width: inherit;
    height: inherit;
}

.utdp-social-media-icons-row {
}

.utdp-social-media-icons-column {
}

.utdp-social-media-icons-column {
    display: flex;
    justify-content: center;
}

.utdp-social-icon {
    padding-right: 40px;
    font-size: 36px;
    transition: color 0.12s;
    color: #858796;
}

.utdp-social-icon:hover {
  color: #858796;
}
/********************* END OF UTDP SOCIAL MEDIA STYLING ***********************/


/************************* STO DETAILS STYLING ********************************/
.utdp-sto-details-container {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #858796;
    width: inherit;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-sto-details-section {
    width: 100%;
}

.utdp-sto-details-top-row {
    font-size: 13px;
}

.utdp-sto-details-row {
    border-bottom: 1pt solid rgba(0, 0, 0, 0.13);
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
}

.utdp-sto-details-top-column {
}

.utdp-sto-details-column {
    width: 100%;
    padding: 0;
}

.utdp-sto-details-column a {
  color: rgba(255, 255, 255, 0.8);
  justify-content: flex-end;
  display: flex;
  text-decoration: underline;
}

.utdp-sto-details-column a:hover {
  color: #858796;
}

.utdp-title {
    font-weight: bold;
    margin-bottom: 3px;
    color: #858796;
}

.utdp-sto-details-content {
    text-align: right;
    color: rgba(255, 255, 255, 0.8);
}

.utdp-sto-details-document-row {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 10px;
}

/**************************** UTDP NEWS STYLING *******************************/
.utdp-news-header-text {
    text-align: center;
    color: #858796;
}

.utdp-news-section {
    width: inherit;
    margin-bottom: 20px;
    margin-top: 16px;
    margin-left: 0px;
    padding: 20px;
    padding-top: 0;
}

.utdp-individual-news-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin-bottom: 10px;
  padding: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-news-card-header-row {
    margin-bottom: 10px;
}

.utdp-news-card-info {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
}

.utdp-news-card-title {
    font-size: 14px;
    padding: 0px 15px;
    margin-bottom: 15px;
}

.utdp-news-card-title a {
  color: #858796;
}

.utdp-news-card-title a:hover {

}

.utdp-read-more-row {
  /* margin-left: 5px; */
  width: 100%;
  margin-bottom: 10px;
  text-align: right;
}

.utdp-no-news {
  margin-top: 100px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.utdp-nav-bar {
  color: #858796;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
  border: 1px solid transparent;
  border-bottom: 0;
}

.utdp-nav-bar p {
  margin: 0;
}

.utdp-nav-item {
  font-size: 20px;
  justify-content: center;
  display: flex;
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.utdp-nav-item a {
  color: inherit;
}

.utdp-nav-item a:hover {
  text-decoration: none;
}

.utdp-nav-item:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* border-bottom: 1px solid transparent; */
  background: white;
}

.utdp-nav-item#current-position {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid transparent;
  background: white;
  color: black;
}

/* .utdp-nav-item#current-position p {
  border-bottom: 3px solid #2E2E31;
} */

/* .utdp-nav-item p:hover {
  cursor: pointer;
  border-bottom: 3px solid #2E2E31;
} */

.utdp-inner-content-div {
  margin: 0 17px 17px 17px;
  padding: 16px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
}

.utdp-profile-info {

}

.pushed-right {
  /* padding-left: 250px; */
}

.individual-sections-container {
  /* width: 70%; */
}

.utdp-news-row {
  background: white;
  justify-content: center;
  padding: 16px;
}

.utdp-individual-section {
  /* padding: 32px 48px 0 32px; */
  /* width: 100%; */
  margin-bottom: 16px;
  /* background: white; */
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
}

.utdp-individual-section h3 {
  color: #2E2E31;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.utdp-fields-row {
}

.utdp-tile-row {
  overflow-y: hidden;
}

.utdp-tile-paragraph {
  font-size: 14px;
  cursor: pointer;
}

.utdp-tile-paragraph:hover {
  background: rgba(0, 0, 0, 0.1);
}

.utdp-tile-subfield {
  background: white;
  margin-bottom: 8px;
  border-radius: 3px;
  padding: 4px;
}


.utdp-tile-object-container {
}

.utdp-tile-object-image-row {
  justify-content: center;
  margin-bottom: 8px;
}

.utdp-individual-field {
  border-bottom: 1pt solid rgba(0, 0, 0, 0.13);
  margin-bottom: 16px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: 100%;
  padding-bottom: 8px;
}

.utdp-individual-field#hidden {
  display: none;
}

.utdp-tile-image-row {
  justify-content: center;
}

.utdp-tile-modal-image {
  width: 100%;
  object-fit: contain;
}

.utdp-individual-field-label {
  margin: 0;
  color: #2E2E31;
  width: 200px !important;
  height: 100%;
}

.utdp-individual-field-text-content {
  margin: 0;
}

.utdp-profile-sections-container {
  color: black;
  /* width: 15%; */
  /* position: fixed; */
  /* left: 17px; */
  /* max-height: 400px; */
  /* top: 450px; */
  /* overflow-y: scroll; */
  /* top: 100px;
  bottom: 100px; */
}

.is-current-profile-section {
}

.sticky {
  position: fixed;
  top: 0;
}

.utdp-profile-sections-header {
  justify-content: center;
  padding-bottom: 8px;
  font-size: 20px;
}

.utdp-individual-section-for-list {
  width: 100%;
  text-transform: uppercase;
  padding: 20px;
}

.utdp-individual-section-for-list:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.utdp-individual-section-for-list:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.utdp-coming-soon {
  color: #858796;
  justify-content: center;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 20px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-news-container {
  max-width: 1000px;
  min-width: 800px;
  padding: 32px;
}

.utdp-individual-article {
  margin-bottom: 16px;
  width: 100%;
  color: #858796;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.utdp-individual-article a {
  /* color: #253165 !important; */
  color: black !important;
}

.utdp-individual-article a:hover {
}

.utdp-article-info {
  color: #858796;
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.utdp-article-title {
  margin: 0;
  font-size: 22px;
  color: black;
  /* color: #253165; */
}

.utdp-article-summary {
  /* color: black; */
  margin-bottom: 8px;
}

.utdp-article-link {
  color: #253165 !important;
  font-size: 14px;
}

.utdp-article-link button {
  padding: 0;
  font-size: 10px;
  padding: 2px 4px;
}

.date-p {
  margin: 0;
}

.utdp-field-image {
  max-width: 40px;
  max-height: 40px;
}

.utdp-field-image-tooltip {
  width: 100%;
  max-height: 400px;
}

.user-content-container {
  min-height: 100vh;
  position: relative;
  color: #858796;
  /* padding: 36px; */
  justify-content: center;
  display: flex;
  padding: 0;
}

.asset-profile-container {
  /* width: 1000px; */
  /* padding: 0; */
  padding: 0;
}

.utdp-header-section {
  background: white;
  font-size: 13px;
  padding: 16px;
  position: relative;
  margin: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #2E2E31;
}

.utdp-header-desc {
  color: #858796;
}

.button-navlink {
  padding: 0;
  margin-left: 8px;
}

.button-navlink button {
  padding: 0 8px;
}

.utdp-header-name {
  font-size: 48px;
  align-items: center;
}

.utdp-header-info-row {
  margin-top: 16px;
}

.utdp-header-info {
  font-size: 14px;
}

.utdp-header-info .row {
  align-items: center;
}

.utdp-header-info-icon {
  margin-right: 8px;
  height: 100%;
}

.utdp-scrollspy-col {
  justify-content: center;
  margin-right: 16px;
}

.profile-scrollspy {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.individual-section-for-scrollspy {
  width: 100%;
  color: black;
  margin-bottom: 8px;
}

.individual-section-for-scrollspy button {
  width: 100%;
  max-width: 200px;
  height: 100% !important;
}

.individual-section-for-scrollspy:hover {
  /* text-decoration: none;
  background: rgba(0, 0, 0, 0.05) */
}

.individual-section-for-scrollspy:not(:last-child) {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.utdp-see-more-row {
  justify-content: center;
  text-decoration: underline;
  font-size: 20px;
  background: white;
  padding: 32px 0;
}

.utdp-see-more-row a {
  padding: 0;
}

.utdp-image-row {
  width: 100%;
  /* justify-content: center; */
  /* margin-left: 16px; */
  margin-bottom: 8px;
}

.utdp-image {
  max-width: 300px;
}

.utdp-image:hover {
  cursor: pointer;
}

.utdp-video {
  border: none;
  height: 240px;
  width: 360px;
}

.utdp-anchor {
}

.utdp-tile-row {
  /* background: red; */
}

.utdp-tile-row:hover {
  background: rgba(0, 0, 0, 0.1);
}

.utdp-individual-tile-field {
  width: 300px;
  border-radius: 3px;
  margin: 0 16px 16px 16px;
  margin-left: 0;
  padding: 16px;
  overflow-y: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-individual-tile-field label {
  color: black;
}

.utdp-individual-tile-field:not(:last-of-type) {

}

.utdp-individual-tile-field:hover {
  /* background: rgba(0, 0, 0, 0.1); */
}

.utdp-individual-tile-field::-webkit-scrollbar {
  width: 4px;
}

.utdp-individual-tile-field::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 25px;
}

.utdp-individual-tile-field .utdp-image-row {
  justify-content: center !important;
  margin: 0 !important;
}

.utdp-individual-tile-field .utdp-image {
  width: 100%;
}

.utdp-tile-image {
  width: 150px;
  border-radius: 100px;
  height: 150px;
  object-fit: cover;
}

.utdp-tile-image:hover {
  cursor: pointer;
}

.utdp-tile-test {
  justify-content: center;
}

.tile-icon {
  /* font-size: 64px !important; */
}

.utdp-individual-tile-field .utdp-social-icon-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-form-link-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-social-icon-link {
  font-size: 40px;
}

.utdp-individual-tile-field .utdp-doc-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-video {
  height: 100%;
  width: 100%;
}

.utdp-individual-tile-field .utdp-form-link-row {
  /* justify-content: center; */
}

.utdp-offering-wrapper{
  margin-top:10px;
  margin-left: 10%;
  margin-right:10%;
  border-style:solid;
  border-color:#253165;
  border-radius:5px;
}

.utdp-offering-row {
  margin-bottom: 16px;
  /* width: 1000px; */
}

.utdp-offering-header-row {
  background: #B3CBE3;
  padding: 8px;
  height: 45px;
  align-items: center;
  color: #212A5C;
}

.utdp-offering-table-row {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 8px;
  /* background: white; */
}

.utdp-table-title {
  /* width: 200px; */
  display: flex;
  align-items: center;
}

.utdp-offering-table {
  font-size: 14px;
  border: none;
  color: #858796;
  margin: 0;
  /* table-layout: fixed; */
}

.utdp-offering-table tr {
  border: none;
}

.utdp-offering-table th {
  border: none;
  padding: 0;
  border-bottom: 8px solid transparent !important;
  justify-content: flex-end !important;
  width: 200px;
  text-align: center;
  font-size:18px;
}

.utdp-offering-table td {
  border: none;
  padding: 0;
  border-bottom: 8px solid transparent !important;
  color: #212A5C;
  font-size: 18px;
  /* width: 200px; */
  text-align: center;
}

.utdp-table-profile-name {
  color: #212A5C;
  font-size: 28px;
}

.utdp-user-image-icon {
  font-size: 20px;
  margin-right: 8px;
}

.utdp-user-image {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 8px;
}

.utdp-header-offering-row {
  margin-top: 16px;
  justify-content: space-between;
}

.utdp-header-offering-row .row {
  align-items: center;
}

.utdp-header-offering-icon {
  font-size: 64px;
  margin-right: 8px;
}

.utdp-header-offering-label {
   font-size: 20px;
  margin: 0;
}

.utdp-header-data {
  font-size: 32px;
  margin: 0;
}

.utdp-header-info-row {
  margin: 0;
  align-items: center;
  justify-content: space-between;
}

.utdp-header-link {
  color: #1588ee !important;
}

.light-chart-container {
  background: red;
  margin: 16px;
  margin-bottom: 32px;
}

.light-chart-info-label {
  color: #2E2E31;
}


.multifield-subfield-dropdown-container {
  border-bottom: 1pt solid rgba(0, 0, 0, 0.13);
  margin-bottom: 16px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: 100%;
  padding-bottom: 8px;
  display: flex;
}

.multifield-subfield-dropdown-container .fieldName {
  width: 200px;
  color: black;
}

.utdp-string-field {
  margin: 0;
}

.search-assets-top-container {
  background: #F8F9FC;
  min-height: 100vh;
}

.search-assets-form-control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #858796 !important;
}

.search-assets-form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.search-assets-form-control::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.search-assets-table-row {
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.search-assets-table-name {
  font-size: 12px;
}

.search-assets-table-cell {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.search-assets-table-cell.description {
  font-size: 12px;
}

.search-assets-tile-row {
  /* justify-content: center; */
}

.search-assets-tile {
  width: 300px;
  height: 400px;
  padding: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  /* margin-left: 8px; */
}

.search-assets-tile img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.search-assets-tile .header-content {
  height: 100px;
  margin-bottom: 16px;
}

.search-assets-tile .name-location-container {
  width: 60%;
  margin-left: 8px;
}

.search-assets-tile .name {
  width: 100%;
  font-size: 18px;
  color: black;
}

.search-assets-tile .location {
  -ms-align-items: center;
  align-items: center;
  display: flex;
}

.search-assets-tile .name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.not-found-container {
  /* min-height: 100vh; */
  height: 100%;
  background-color: #F8F9FC;
  padding: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.not-found-container.admin-type {
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.not-found-container-card {
  background: white;
  min-height: 300px;
  padding: 16px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.not-found-image-row {
  justify-content: center;
}

.not-found-image {
  height: 100px;
}

.not-found-header-row {
  justify-content: center;
  font-size: 40px;
  color: #858796;
  margin: 32px;
  margin-bottom: 0;
}

.not-found-content-row {
  justify-content: center;
  color: #858796 !important;
  font-size: 20px;
  margin: 32px;
  margin-bottom: 0;
}

.not-found-links-row {
  justify-content: center;
  color: #858796 !important;
  margin: 32px;
  margin-bottom: 0;
}

.not-found-link {
  margin: 0 4px;
}

.research-application-container {
    max-width: 100% !important;
    height: 100%;
    border-radius: 0;
    display: table;
    padding: 0;
    margin: 0;
}

.research-application-section {
  max-width: 70% !important;
  vertical-align: center;
  display: table-cell;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(to top, rgba(33, 122, 255, 0.85), rgb(10, 47, 180, 0.9));
}

.research-application-background {
    margin-top: 90px;
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-image: url(/static/media/header-background-image.740e3547.png);
    background-color: #11076B;
    background-size: cover;
}
/* #0A2FB4
rgb(10,47,180) */

.research-application-header-row {
    justify-content: center;
    margin: 0px 0px 60px 0px;
}

.research-application-header-row h3 {
    color: white;
}

.research-application-form, .register-or-login-section {
    color: white;
    font-size: 14px;
}

.research-application-form .form-control, {
    width: 50%;
    margin-left: 25%;
}

.research-application-form .form-label {
  padding: 15px 0px 0px 15px;
  margin: 0;
}

.research-application-form .form-control {
  margin: 0;
  width: 100%;
}

.research-application-form .col-sm-3, .research-application-form .col-md-3 {
  padding: 15px 15px 0px 0px;
}

.research-application-buttons-row {
    display: flex;
    justify-content: center;
}

.research-application-button {
    margin-left: 15px;
    margin-right: 20px;
    width: 200px;
    margin-bottom: 40px;
}

.research-application-form-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.research-application-form .form-control, .register-or-login-section .form-control {
  font-size: 14px;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-bottom: 1px solid white;
  border-radius: 0rem;
}

/* .research-application-form .form-control:hover {
  cursor: pointer;
} */

.research-application-form .form-control::-webkit-input-placeholder, .register-or-login-section .form-control::-webkit-input-placeholder {
  color: white;
  font-size: 14px;
}

.research-application-form .form-control::placeholder, .register-or-login-section .form-control::placeholder {
  color: white;
  font-size: 14px;
}

.research-application-form-control {
  transition: border 0.15s, border-radius 0.15s, background 0.15s, box-shadow 0.15s;
}

.research-application-form-control:hover {
  /* border: 1px solid #3590FC; */
  /* border-radius: 5px; */
  /* background: #3590FC;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* cursor: pointer; */
}

.research-application-form-control:focus {
  background: transparent;
  color: white;
}

.research-application-form-control option {
  background-color: #0A2FB4;
}

.research-application-form:focus .option {
  color: black;
}

.research-application-form-typeahead  ul {
  background-color: #0A2FB4;
  border: none;
  border-radius: 0;
}

.research-application-form-typeahead .rbt-input-main {
  padding: 6px 12px;
}

.research-application-form-typeahead:hover {
  cursor: pointer !important;
}

.research-application-form-typeahead .dropdown-item:hover {
    color: white;
    background: #3590FC;
    border-radius: 0;
    cursor: pointer;
}

.research-application-form-typeahead a {
    color: white;
    font-size: 14px;
}

.research-application-submit {
    background: transparent;
    color: white;
    font-size: 16px;
    border: 1px solid white;
    width: 160px;
    height: 35px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.research-application-submit:hover {
  background: #3590FC;
  border: 1px solid #3590FC;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.research-status {
  color: white;
  justify-content: center;
  display: flex;
  padding-top: 15px;
}

.research-status a {
  color: orange;
}

#select-files {
  border: 1px solid white;
  border-radius: 5px;
  transition: border 0.15s, background 0.15s, box-shadow 0.15s;
}

#select-files:hover {
  /* border: 1px solid white !important; */
  border: 1px solid #3590FC;
  background: #3590FC;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.clear-field-button-col {
  justify-content: center;
  /* align-items: top; */
  vertical-align: top;
  display: flex;
}

.selected-document-row {
  justify-content: center;
  /* display: inline; */
}

.selected-document-row .col {
  height: 38px;
  /* margin: 0;
  padding: 0; */
}

.selected-document-row .col p {
  padding-left: 15px;
}

#research-clear-col {
  /* padding: 0; */
  margin: 0;
}

#clear-selected-field {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
  /* padding-right: 15px; */
  margin-right: 15px;
}

#clear-selected-field:hover {
  /* background: darkred; */
}

.register-or-login-section {
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(to top, rgba(33, 122, 255, 0.85), rgb(10, 47, 180, 0.9));
  position: relative;
}

.register-login-top-label {
  justify-content: center;
  display: flex;
  font-size: 18px;
  margin-bottom: 20px !important;
}

.rol-bottom-field {
  padding-bottom: 30px !important;
}

.rol-submit-button {
  background: transparent;
  border: 1px solid white;
  margin-bottom: 15px;
}

.rol-submit-button:hover {
  background: #3590FC;
  border: 1px solid #3590FC;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.rol-already-have-an-account:hover {
  text-decoration: underline;
  cursor: pointer;
}

.rol-empty-row {
  height: 38px;
  margin-bottom: 15px;
}

.login-header-text {
  color: white;
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.research-app-info-container {
  margin-top: 90px;
  max-width: 100vw;
  padding: 0;
  /* margin-bottom: 30px; */
}

.research-app-info-top-row {
  padding: 40px 0 !important;
}

.research-app-info-top-row .col-md-6 {
}

.splash-image-col {
  justify-content: flex-end;
  display: flex;
  background-image: url(/static/media/splash-image.e5fe501a.png);
  background-size: cover;
}

.splash-image {
  /* max-width: 80%; */
  object-fit: cover;
}

.splash-text {
  /* background: red; */
}

.splash-text-div {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splash-text h4 {
  color: #75c2de;
  font-size: 32px;
  font-weight: bold;
}

.research-app-info-text-row {
  margin: 0;
  padding: 0;
  margin-top: 30px;
}

.research-app-info-button-row {
  margin: 0;
  padding: 0;
  /* justify-content: center; */
  margin-top: 60px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 100%;
}

.token-listed-link {
  width: 50%;
  justify-content: center;
  display: flex;
}

.token-listed-link:hover {
  text-decoration: none;
}

.research-app-info-button {
  width: 200px;
  font-size: 14px;
  background: transparent;
  color: #1588ee;
  border: 1px solid #1588ee;
  border-radius: 200px;
  font-weight: bold;
}

.research-app-info-button:hover {
  background: #1588ee;
  border: 1px solid #1588ee;
}

.research-app-info-button-row a {
  width: 50%;
  justify-content: center;
  display: flex;
}

.research-app-info-button-row a:hover {
  text-decoration: none;
}

.research-app-text-col h3 {
  text-align: center;
  color: #1963a3;
  /* color: white; */
  margin-bottom: 20px;
  font-weight: bold;
}

.research-app-text-col p {
  color: white;
}

@function set-text-color($color) {
    @if (lightness( $color ) > 50) {
      @return $black;
    }
    @else {
      @return $white;
    }
}

.research-management-portal {
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  background: #F1F2F6;
  /* background-image: url('../../../images/HomePage/header-background-image.png');
  background-color: #11076B;
  background-size: cover; */
}

.research-management-portal .container {
  /* margin-top: 70px; */
  height: 100%;
  max-width: 70%;
  padding: 0;

}

.research-management-portal .header-row {
  width: 100%;
  justify-content: center;
  padding-top: 15px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #0182E7;
}

.research-management-portal .header-row h3 {
  color: black;
  font-size: 35px;
}

.research-management-portal .content-row {
  padding-bottom: 40px !important;
}

.research-management-portal .col {

}

.research-management-portal .row {
  padding: 0;
  margin: 0;
}

.research-management-portal .contributor-stats {
  background: transparent;
  height: 100%;
}

.research-management-portal .contributor-reports {
  background: transparent;
  height: 100%;
}

.contributor-stats {
  padding: 0 !important;
}

.contributor-reports .col {
  padding: 0;
}

.research-management-portal .contributor-stats .info-card, .content-row .info-card {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px 0px;
  padding: 30px;
  font-size: 14px;
  border-radius: 2px;
}

.research-management-portal .contributor-stats .info-card, .content-row .info-card-small {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px 0px;
  padding: 30px;
  font-size: 14px;
  border-radius: 2px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.research-management-portal .contributor-stats .info-card, .content-row .info-card-small .total-followers {
  font-size: 24px;
}

.research-management-portal .contributor-stats .info-card .col {
  padding: 0;
}

.research-management-portal .contributor-reports .row {
  background: transparent;
  margin: 15px 0px;
}

.left-justified-column {

}

.right-justified-column {
  justify-content: flex-end !important;
  display: flex !important;
  padding: 0;
  margin: 0;
}

.research-management-portal .contributor-stats .info-card .category, .content-row .info-card .category {
  padding: 10px 5px;
}

.contributor-profile-right-side {
  justify-content: flex-end;
  display: flex;
}

.research-management-portal .contributor-stats .info-card .category, .content-row .info-card .category:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
}

.content-row .info-card .engagement-stats-button-row {
  margin: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.content-row .info-card .engagement-stats-button-row .engagement-stats-back-button {
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.2s;
}

.content-row .info-card .engagement-stats-button-row .engagement-stats-back-button:hover {
  cursor: pointer;
  color: #0182E7;
}

.payout-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  /* background: #F1F2F6; */
}

.payout-tabs a {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
  width: 25%;
  text-align: center;
  font-weight: bold;
}

.payout-tabs a:hover {
  border: 1px solid rgba(0, 0, 0, 0.11);
  color: #0182E7;
  border: 1px solid transparent;
}

.payout-tabs .active a:hover {
  border-bottom: none;
  color: rgba(0, 0, 0, 0.55);
  border: 1px solid rgba(0, 0, 0, 0.11);
  /* border: 1px solid transparent; */
}

.payout-tabs .active {
  color: rgba(0, 0, 0, 0.55);
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-bottom: none;
}

.payout-tabs .active:hover {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-bottom: none;
  /* border-bottom: 2px solid #0182E7; */
}

.rmp-payout-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  /* background: #F1F2F6; */
}

.rmp-payout-tabs a {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
  width: 25%;
  text-align: center;
  font-weight: bold;
}

.rmp-payout-tabs a:hover {
  border: 1px solid rgba(0, 0, 0, 0.11);
  color: #0182E7;
  border: 1px solid transparent !important;
}

.rmp-payout-tabs .active a:hover {
  border-bottom: none;
  color: rgba(0, 0, 0, 0.55);
  border: 1px solid rgba(0, 0, 0, 0.11);
  background: transparent;
  /* border: 1px solid transparent; */
}

.rmp-payout-tabs .active {
  color: rgba(0, 0, 0, 0.55);
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-bottom: none;
  background: transparent !important;
  border: none !important;
}

.rmp-payout-tabs .active:hover {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-bottom: none;
  /* border-bottom: 2px solid #0182E7; */
  border: none !important;
}

.research-management-portal .contributor-stats .info-card .title {
  font-weight: bold;
}

.research-management-portal .contributor-stats .info-card h4 {
  font-weight: bold;
  font-size: 24px;
}

.research-management-portal .contributor-reports {
  padding: 0;
}

.research-management-portal .contributor-reports form {
  width: 100% !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.research-management-portal .contributor-reports form .search-bar {
  max-width: 100%;
}

.contributor-reports .row {
}

.research-management-portal .contributor-reports .tabs-col {
  padding: 0;
  border-radius: 2px;
}

.contributor-reports .nav-tabs {
  background: #F1F2F6;
  border: none !important;
  /* border-radius: 5px; */
  border: none;
}

.contributor-reports .nav-tabs .active {
  border: none;
}

.contributor-reports-search-bar-row .input-group {
  width: 100%;
  max-width: 100%;
}

.contributor-search-bar {
  border: 1px solid #0182E7;
}

.contributor-search-button {
  background: white;
  color: #0182E7;
}

.contributor-search-button:hover {
  background: #0182E7;
  border: 1px solid #0182E7;
}

.contributor-report-tabs {
  margin-top: 10px;
}

.research-management-portal .contributor-reports .tabs-col .row {
  padding: 0px 16px;
}

/* .research-management-portal .contributor-reports .tabs-col #icon {
  padding: 0 !important;
  justify-content: center !important;
  background: red;
} */

.individual-report {
}

.rendered-individual-report-top-row,
  .rendered-individual-report-col,
    .rendered-individual-report-col .report-title-info-button,
      .rendered-individual-report-col .report-title-col,
        .rendered-individual-report-col .report-info-button-row,
          .report-date {
  margin: 0;
  padding: 0;
}

.rendered-individual-report-col:hover {
  /* cursor: pointer; */
}

.rendered-individual-report-top-row {
  padding: 5px 5px !important;
  border: 1px solid transparent;
  z-index: -1;
}

.rendered-individual-report-top-row:hover {
  /* background: rgba(0, 0, 0, 0.1);
  cursor: pointer; */
}

#active.rendered-individual-report-top-row {
  /* border: 1px solid #0182E7; */
  background: rgba(0, 0, 0, 0.1);
}

#active.rendered-individual-report-top-row
  .report-title-info-button a {
    /* color: #0182E7 !important; */
  }

#active.rendered-individual-report-top-row
  .rendered-individual-report-col
    .report-info-button-row
      .report-info-button {
  /* color: #0182E7; */
}

.rendered-individual-report-top-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  /* margin-bottom: 10px !important; */
}

.rendered-individual-report-col .report-info-button-row {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.report-info-button {
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.2s;
  font-size: 18px;
  z-index: 2;
}

.report-info-button:not(:last-child) {
  margin-left: 10px;
}

.rendered-individual-report-col .report-info-button:not(:last-child) {
  margin-right: 10px;
}

.report-info-button:hover {
  color: #0182E7;
  cursor: pointer;
}

.report-date {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.research-management-portal .contributor-reports .tabs-col .report-content .row
  .research-management-portal .contributor-reports .tabs-col .report-icon-col {
  padding: 0 !important;
  margin: 0 !important;
}

.report-content .date {
  padding: 0;
  margin: 0;
}

.research-management-portal .contributor-reports .tabs-col .report-icon-col svg {
  color: #0182E7;
}

.report-content .info {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
}

.report-content .title {
  font-size: 14px;
}

.date {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  background: red;
}

.individual-report {
  padding: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  padding: 20px 0px !important;
  margin: 0 !important;
}

.report-content .brief {
  font-size: 14px;
}

.research-management-portal .contributor-reports .tabs-col #icon {
  justify-content: center;
  display: flex;
  align-items: center;
}

.tabs-col  {
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 5px;
}

.tabs-col .nav-tabs a {
  width: 50%;
  text-align: center;
  font-weight: bold;
}

.contributor-report-row {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.info-card-disabled {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px 0px;
  padding: 30px;
  font-size: 14px;
  border-radius: 2px;
}

.coming-soon {
  justify-content: center;
  font-weight: bold;
  align-items: center;
  height: 200px;
}

.coming-soon-bubble {
  background: white;
  width: 200px;
  justify-content: center;
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.date-picker-row {
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 0 0 0 4px !important;
  border-radius: 2px;

}

.date-picker-row .col {
  align-items: center;
}

.research-management-portal-container {
  transition: -webkit-filter 0.5s;
  transition: filter 0.5s;
  transition: filter 0.5s, -webkit-filter 0.5s;
  width: 100vw !important;
  max-width: 100vw !important;
  /* padding: 0 40px 0 0 !important; */
}

.rmp-container-row {
  height: 100%;
  /* padding-bottom: 90px !important; */
}

body.modal-open .research-management-portal-container {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.profile-form-modal {
  background: rgba(0, 0, 0, 0.3);
}

.profile-form-modal .modal-body {
  /* min-height: 400px; */
}

.profile-form-modal .modal-dialog {
  min-width: 50%;
  /* background: #F1F2F6; */
}

.profile-form-modal .modal-header, .profile-form-modal .modal-footer {
  border: none;
}

.profile-form-modal .modal-title {
  width: 100%;
  text-align: center;
}

.profile-form-modal .modal-content {
  background: #F1F2F6;
}

.profile-form-modal .image-uploader {
  /* margin-left: 0px;
  max-height: 100%;
  border: none; */
  border: none;
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-uploader-row {
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-uploader-row .avatar-container {
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-uploader-row .avatar-container div {
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-uploader-row .avatar-container div div {
  max-height: 200px;
  max-width: 200px;
  margin: 0;
  padding: 0;
  text-align: center;
  justify-content: center;
}

.profile-form-modal .image-uploader-row .avatar-container div div label {
  margin: 0;
  padding: 0;
  max-height: 200px;
  max-width: 200px;
  justify-content: center;
  align-items: center;
  display: flex !important;
}

.profile-form-modal .image-uploader-row .avatar-container div div div div canvas {
  margin: 0;
  padding: 0;
  max-height: 200px;
  max-width: 200px;
}

.profile-form-modal .image-uploader-container {
  margin: 0;
  padding: 0;
}

.profile-form-modal .uploaded-image {
  object-fit: cover;
  max-width: 200px;
  max-height: 200px;
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-overlay {
  max-width: 200px;
  max-height: 200px;
  margin: 0;
  padding: 0;
}

.account-image-spinner-div {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.account-image-spinner {

}

.profile-form-content {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
  font-size: 14px;
  margin: 0;
  padding: 0;
  padding: 10px;
  margin:  0 15px;
  /* padding-right: 20px; */
}

.profile-form-content .row .small-text {
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  font-size: 12px;
}

.profile-form-content .form-label {
  margin: 0;
  padding: 0;
}



.profile-form-content .contributor-application-form-control:not(:last-child) {
  margin-bottom: 10px;
}

.contributor-application-form-control .form-control {
  margin-bottom: 10px !important;
}

.contributor-application-form-control {
  transition: border 0.15s, border-radius 0.15s, background 0.15s, box-shadow 0.15s;
  border: none;
  border-bottom: 1px solid darkgrey;
  border-radius: 0;
  color: black;
}


.contributor-application-form-control:focus {
  /* background: transparent; */
  /* color: white; */
}

.contributor-application-form-control option {
  /* background-color: #0A2FB4; */
}

.research-application-form:focus .option {
  color: black;
}

.profile-form-content .form-label {
  /* margin-top: 15px !important;
  margin-bottom: 0; */
}

.pencil-div {
  justify-content: flex-end;
  display: flex;
  padding: 0;
}

.pencil-div .edit-account-button {
  transition: color 0.2s, background 0.2s !important;
  /* border: 1px solid #0182E7; */
  color: rgba(0, 0, 0, 0.3);
  border: none;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.pencil-div .edit-account-button:hover {
  cursor: pointer;
  /* background: #0182E7; */
  color: #0182E7;
  border-radius: 2px;
}

.pencil-div .btn-primary {
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
  width: 60px;
  margin-right: 10px;
  border-radius: 2px;
  background: white;
  color: #0182E7;
  border: 1px solid #0182E7;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.pencil-div .btn-primary:hover {
  color: white;
  background: #0182E7;
}

.contributor-profile-edit-form-control {
  font-size: 14px;
  height: 21px;
  width: 100% !important;
  max-width: 100% !important;
}

.missing-reports {
  text-align: center;
  font-size: 14px;
  width: 100%;
  margin-top: 30px;
}

.drafts-link-row {
  text-align: center;
  justify-content: center;
  padding: 10px 0 !important;
}

.drafts-manager-link {
  font-size: 12px;
  text-decoration: underline;
}

.report-stats-window {

}

.selected-report-title {
  margin-left: 15px !important;
  margin-bottom: 15px !important;
  font-size: 32px;
}

#engagement-stats h4 {
  font-size: 20px;
}

.selected-report-unpublish-row {
  margin: 0 15px !important;
  margin-top: 15px !important;
  display: flex;
  justify-content: flex-end;
}

.unpublish-modal .modal-content {
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.unpublish-modal-text-row,
  .unpublish-modal-button-row {
  display: flex;
  justify-content: center;
}

.unpublish-modal-text-row {
  margin-bottom: 10px;
}

.unpublish-button {
  color: red;
  border: 1px solid red;
  border-radius: 2px;
  padding: 0;
  padding: 0 2px;
  font-size: 12px;
  background: transparent;
}

.unpublish-button:hover {
  background: red;
  border: 1px solid red;
  border-radius: 2px;
  color: white;
}

.unpublish-modal-cancel-button {
  background: white;
  color: #0182E7;
  border: 1px solid #0182E7;
  border-radius: 2px;
  margin-left: 10px;
  width: 100px;
}

.unpublish-modal-cancel-button:hover {
  background: #0182E7;
  border: 1px solid #0182E7;
  border-radius: 2px;
}

.selected-report-unpublish-row button,
  .unpublish-modal-unpublish-button {
  color: red;
  border: 1px solid red;
  border-radius: 2px;
  width: 100px;
  background: transparent;
}

.selected-report-unpublish-row button:hover,
  .unpublish-modal-unpublish-button:hover {
  background: red;
  border: 1px solid red;
  border-radius: 2px;
  color: white;
}

.copy-link-control-row {
  align-items: center;
  /* margin-top: 30px !important; */
  margin-bottom: 10px !important;
}

.copy-link-form-control {
  /* width: 80%; */
  /* margin-right: 10px; */
  font-size: 14px;
}

.clipboard-col {
  justify-content: center;
  display: flex;
}

.copy-link-modal {
  padding-top: 20%;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  border: none !important;
}

.copy-link-modal .modal-content {
  border: none;
}

.rmp-sidebar-column {
  background: rgba(0, 0, 0, 0.2);
  justify-content: flex-start;
  /* padding-top: 3px !important; */
}

.rmp-sidebar-listgroup {
  width: 100%;
}

.rmp-sidebar-listgroup .list-group-item {
  background: transparent;
  /* border: none; */
  border: 1px solid transparent;
  border-top: 1px solid slategrey;
  border-bottom: 1px solid slategrey !important;
  /* padding: 5px; */
}

.rmp-sidebar-listgroup .list-group-item{
  /* display: none !important; */
}

#disabledttt {
  background: pink;
}

.rmp-sidebar-listgroup .list-group-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: white;
  cursor: pointer;
}

.rmp-sidebar-listgroup .active {
  background: rgb(1, 130, 231, 0.5);
  color: white;
  border-top: 1px solid #0182E7;
  border-bottom: 1px solid #0182E7 !important;
}


.rmp-sidebar-listgroup .active:hover {
  background: rgba(0, 0, 0, 0.2);
}
.rmp-sidebar-listgroup .active:hover {
  background: rgb(1, 130, 231, 0.5);
}

.rmp-tab-content {
  width: 100%;
}

.responsive-line-graph {

}

.rmp-line-graph {
  width: 100%;
  /* max-height: 50%;
  height: 50%;
  max-height: 450px;
  height: 450px; */
  max-height: 50vh;
  height: 50vh;
}

.rmp-line-graph h4 {
}

.graph-buttons {
  justify-content: center;
  display: flex;
}

.graph-buttons input[type='radio'] {
}

.graph-buttons label {
}

.engagement-column .tabs-col {
  border: none;
  border-radius: 0;
  margin-top: 10px;
  width: 100% !important;
}

.engagement-column .tabs-col .published-tab {
}

.time-frame-button {
  background: white;
  color: #0182E7;
  border: 1px solid #0182E7;
  font-size: 10px;
  /* padding: 0; */
  height: 20px;
  padding: 0 2px;

}

.time-frame-button:hover {
  background: #0182E7;
  border: 1px solid #0182E7;
}

#active.time-frame-button {
  background: #0182E7;
  color: white;
}

.time-frame-button:not(:last-child) {
  margin-right: 5px;
}

.engagement-stats-header-row {
  align-items: center;
  margin-bottom: 10px !important;
}

.total-engagement-stats {
  color: rgba(0, 0, 0, 0.5);
  background: white;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 20px;
  padding: 0 8px;
  border-radius: 2px;
}

.rmp-line-graph

.rmp-line-graph-header-row .col h4 {
  margin: 0;
}

.rmp-line-graph-button-row {
  align-items: center;
}

.total-engagement-stats:hover {
  color: white;
  background: #0182E7;
  border: 1px solid #0182E7;
}

#hidden.total-engagement-stats {
  display: none;
}

.total-engagement-header-row .col h4 {
  margin: 0;
}

.engagement-stats-header-col h4 {
  margin: 0;
}

.rmp-token-data-month {
}

.rmp-graph-tooltip {
  width: 300px;
  background: white;
  height: 20px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  margin: 10px;
  padding: 20px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.rmp-graph-tooltip .row {
  width: 100%;
}

.rmp-refer-a-friend-button, .save-image-button {
  background: transparent;
  border: 1px solid #0182E7;
  color: #0182E7;
  padding: 10px 30px;
  border-radius: 2px;
}

.rmp-refer-a-friend-button:hover, .save-image-button:hover {
  color: white;
  border: 1px solid #0182E7;
  background: #0182E7;
}

.rmp-refer-a-friend-row {
  padding-top: 20px !important;
}

.avatar-editor {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.rmp-engagement-stats {
  padding: 0 !important;
  padding: 5px !important;
  justify-content: center;
  /* display: flex; */
  font-size: 12px !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.engagement-text {
  justify-content: center;
}

.engagement-stat {
  justify-content: center;
  font-size: 22px;
  min-height: 33px;
}

.rmp-engagement-stats:not(:last-child) {
  margin-right: 8px !important;
}

.rmp-all-reports-button-row {
  font-weight: bold;
  height: 50px;
  align-items: center;
}

.rmp-all-reports-button {
  color: rgba(0, 0, 0, 0.5);
  background: white;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 20px;
  padding: 0 8px;
  border-radius: 2px;
}

.rmp-all-reports-button:hover {
  color: white;
  border: 1px solid #0182E7;
  background: #0182E7;
}

.rmp-all-reports-text {
  margin: 0 !important;
}

.line-graph-header-text {
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.55);
  min-height: 24px;
}

.rmp-refer-a-friend-link-text {
  margin: 30px 0 !important;
}

.rmp-invite-header-row {
  font-size: 20px;
  margin-bottom: 15px !important;
}

.spinner-row {
  justify-content: center;
  display: flex;
  margin-top: 100px !important;
}

.bolded-text {
  font-weight: bold;
  margin-top: 10px !important;
}

#active-token-month {
background: rgba(0, 0, 0, 0.1);
/* border: 1px solid #0182E7; */
}

.rmp-token-month {
  border: 1px solid transparent;
  /* padding: 0 5px !important; */
}

.rmp-token-month:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

.temp-token-month {
  align-items: center;
  margin-bottom: 10px !important;
}

.rmp-no-tokens-earned {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.rmp-pie-chart-tooltip {
  /* height: 80px; */
  width: 160px;
  font-size: 12px;
}

.rmp-date-picker-text {
  color: rgba(0, 0, 0, 0.55);
  display: inline-block;
  font-weight: bold;
  padding: 0 !important;
  margin: 0;
}

.rmp-token-data-total-row {
  align-items: center;
}

.rmp-token-data-icon {
  margin-left: 2px;
  color: rgba(0, 0, 0, 0.3);
}

.rmp-pie-chart-info-icon {
}

.rmp-pie-chart-info-icon:hover {
  cursor: pointer;
  color: #0182E7;
}

.rmp-user-profile-banner-row {
  background: red;
}

.rmp-user-profile-banner-image {
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  /* position: absolute; */
}

.rmp-image-uploader .fileContainer {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: 0;
  height: 300px;
  width: 300px;
}

.rmp-uploaded-image {
  height: 300px;
  width: 300px;
  object-fit: cover;
  /* margin-top: 15px; */
  background: rgba(0, 0, 0, 0.3);
}

.rmp-banner-row {
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 240px;
  width: 100%;
}

.rmp-banner-edit-icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.rmp-banner-edit-icon {
  background: white;
  border-radius: 100px;
  padding: 2px;
  width: 20px;
  height: 20px;
  color: #0182E7;
}

.rmp-banner-edit-icon:hover {
  background: #0182E7;
  color: white;
  cursor: pointer;
}

.rmp-banner-image {
  /* height: 240px; */
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
}

.rmp-banner-uploader .fileContainer {
  background: transparent;
  height: 240px;
  width: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.rmp-test-row {
  width: 100%;
}

.rmp-create-asset-profile-section .row {

}

.rmp-create-asset-profile-header {
  border-bottom: 1px solid black;
  margin-bottom: 20px;

}

.rmp-create-asset-profile-header .col {
  justify-content: center;
  display: flex;
}

.rmp-individual-profile {
  padding: 10px !important;
}

.rmp-individual-profile:nth-child(even) {
  background: rgba(0, 0, 0, 0.1);
}

.rmp-individual-profile-icon {
  margin-right: 5px;
}

.rmp-individual-profile-icon:hover {
  cursor: pointer;
  color: #0182E7;
}

.rmp-individual-profile-icon-delete:hover {
  cursor: pointer;
  color: fireBrick;
}

.rmp-individual-profile .col {
  justify-content: center;
  display: flex;
}

.rmp-individual-profile-icon-row {
  align-items: center;
}

.rmp-return-to-asset-profile {
  align-items: center;
  margin-bottom: 20px;
}

.rmp-return-to-asset-profile:hover {
  cursor: pointer;
  color: #0182E7;
  text-decoration: underline;
}

.rmp-ap-form-name-row {
  margin-bottom: 20px !important;
}

.rmp-ap-form-control {
  /* width: 100%;
  max-width: 50%; */
}

.rmp-ap-image-uploader {
  /* width: 100%;
  max-width: 50%; */
}

.rmp-ap-typeahead {
  /* width: 100%;
  max-width: 50%; */
}

.rmp-edit-this-container {
  width: 50%;
}

.rmp-ap-form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.rmp-ap-form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.rmp-asset-profile-overview-tab {
  padding: 20px;
  justify-content: center;
}

.rmp-asset-profile-overview-tab h3 {
  color: black;
  font-size: 22px;
}

.rmp-individual-asset-profile-editor {
  align-items: center;
  /* background: red; */
  width: 300px;
  height: 62px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 10px !important;
  justify-content: space-between;
}

.rmp-individual-asset-profile-editor:not(:last-child) {
  margin-right: 10px;
}

.rmp-individual-asset-profile-editor img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.rmp-editors-container {
  margin-bottom: 20px;
}

.rmp-editors-searchbar-container {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  min-height: 369px;
}

.rmp-editors-searchbar {
  width: 300px;
  margin-bottom: 20px;
}

.rmp-editors-searchbar::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.rmp-editors-searchbar::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.rmp-editors-profile-analytics-container {
  padding-top: 20px;
}

.rmp-individual-search-editor {
  align-items: center;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 10px !important;
  justify-content: space-between;
  display: flex;
}

.rmp-individual-search-editor:not(:last-child) {
  margin-bottom: 5px;
}

.rmp-edit-this-profile-tab {
  padding: 20px;
}

.no-asset-profiles-listed {
  text-align: center;
  padding: 20px;
}

.rmp-submit-for-verification-button {
  /* font-size: 10px; */
  /* padding: 0; */
}

.dashboard-account-info-card {
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  color: #858796;
}

.dashboard-header-row {
  font-size: 16px;
  margin-bottom: 16px;
}

.dashboard-contents-row {
  justify-content: space-between;
  margin-bottom: 16px;
}

.dashboard-contents-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.rmp-dashboard-row {
  margin-top: 16px;
}

.dashboard-account-info-card {

}

.research-homepage-jumbotron {
  padding: 0;
  background: #F8F9FC;
  min-height: 100%;
}

.research-homepage-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.research-homepage-top-row .separation-column {
  margin: 0;
  padding: 0;
  /* background: red; */
}

/************* TRENDING ARTICLES **********************************************/
.research-articles-section {
  margin: 0;
  padding: 0;
  min-height: 10em;
  margin: 0 15px 15px 15px;
}

.research-articles-section .no-reports {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #858796;
  justify-content: center;
}

.discover-loading-spinner-container {
  background: transparent;
  justify-content: center;
  width: 100%;
  display: flex;
  color: #858796;
}

.research-articles-section h4 {
  color: #858796;
  font-size: 24px;
  margin-bottom: 0;
  /* margin-right: 15px; */
}

.research-article-row {
  margin: 0;
  font-size: 14px;
  padding: 10px 20px;
  /* border: 1px solid rgba(0, 0, 0, 0.13); */
  border-radius: 2px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;
  /* height: 150px; */
}

.article-content-title {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.article-content-summary {
  margin: 0;
  padding: 0;
}

.article-image-col {
  justify-content: flex-end;
  display: flex;
  padding: 0;
}

/************* END OF TRENDING ARTICLES ***************************************/
.research-homepage-top-row {
  margin: 0;
  padding: 0;
  padding: 20px;
  justify-content: center;
}

.research-homepage-col {
  border-radius: 2px;
  margin: 0;
  padding: 0;
}

.research-homepage-col .search-bar, .research-homepage-col .search-button {
  margin-top: 20px;
}

.research-homepage-col .render-article-section-pagination {
  border-radius: none;
  justify-content: center;
}

/************* TRENDING TOPICS ******************************************/
.trending-topic {
  border-radius: 2px;
  padding: 2px 10px;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  display: inline-block;
  transition: box-shadow 0.13s;
}

.research-right-col #current-category {
  border: 1px solid white
}

.trending-topic:not(:last-child) {
  margin-right: 20px;
  margin-bottom: 15px;
}

.trending-topic:hover {
  cursor: pointer;
  color: black;
}

.trending-topic-name {
  margin: 0;
  padding: 0;
  /* padding-left: 10px; */
  background: red;
}

.trending-topic-count {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  /* padding-right: 10px; */
  background: purple;
}
/************* END OF TRENDING TOPICS ***********************************/

/************* FEED SECTION ***************************************************/

/************* FEED SECTION ***************************************************/

.research-right-col {
  margin: 0;
}

.research-right-col, .research-left-col {
  align-items: flex-start;
  padding: 0;
  justify-content: flex-start;
}

.research-right-col h4 {
  padding: 0;
  margin: 0;
  padding-bottom: 15px;
  color: #858796;
}

.research-right-col .research-trending-section {
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
}

.research-trending-section .tab-content {
  width: 100%;
  padding-top: 20px;
  display: block;
}

.research-trending-section .tab-content {
}

.research-topics {
  width: 100%;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0;
  margin: 0;
  border-radius: 2px;
  padding: 10px;
  margin-top: 15px;
  color: #858796 !important;
}

.research-topics:not(:last-child) {
  margin-top: 43px;
}

.research-topics-header-row {
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 8px;
  width: 100%;
}

.research-topics-header-row h4 {
  margin: 0;
}

.research-topics-header-row .search-filter-clear-button {
  color: rgba(255, 255, 255, 0.6);
  height: 100%;
  display: block;
  margin-left: auto;
  transition: 0.13s;
}

.research-topics-header-row .search-filter-clear-button:hover {
  cursor: pointer;
  color: #858796;
}

.research-topics .research-search-dropdown-row {
  margin: 0;
  padding: 0;
  width: 100%;
}

.research-topics .research-search-dropdown {
  color: #858796;
  font-size: 14px;
  border: none;
  border-radius: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.research-topics .research-search-dropdown:hover {
  cursor: pointer;
}

.research-topics .research-search-dropdown-row:not(:last-child) {
  margin-bottom: 10px;
}

.research-topics .research-datepicker-label {
  margin: 0;
  padding: 0;
  align-items: flex-start;
  color: #858796;
}

.research-topics .research-datepicker-row {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.research-datepicker-row {
  justify-content: space-between;
  align-items: center;
}

.research-datepicker {
  /* width: max-content; */
  border: none !important;
}

.research-datepicker .react-date-picker__wrapper {
  border: none !important;
}

.research-datepicker .react-date-picker__wrapper .react-date-picker__clear-button {
  stroke: #858796 !important;
}

.research-topics .research-datepicker-row .research-datepicker, .research-topics .research-datepicker-row .research-datepicker input {
  color: #858796;
}

.research-topics .research-datepicker-row:not(:last-child) {
  margin-bottom: 10px;
}

.research-datepicker {
  border: none !important;
}

.research-topics .research-datepicker-col {
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  display: flex;
}

.research-topics h4 {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
}

.research-topics h6 {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.research-see-more {
  font-size: 14px;
  justify-content: flex-end;
  align-items: center;
}

.research-see-more-articles {
  font-size: 14px;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
}

.research-see-more:hover, .research-see-more-articles:hover {
  color: rgba(33, 122, 255, 1);
  cursor: pointer;
  text-decoration: underline;
}

.research-see-more .research-icon, .research-see-more-articles .research-icon {
  margin-right: 5px;
}

.research-search-col {
  /* padding: 10px; */
  margin: 0;
  padding: 0;
  /* padding: 10px; */
}

.research-search-bar {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 14px;
  padding-bottom: 10px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
}

.research-search-bar .col-md-2, .research-search-bar .col-md-10 {
  padding: 0;
}

.research-search-bar .search-bar-form-control {
  width: 85%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: none;
  border-radius: 0;
  color: #858796;
}

.search-bar-form-control::-webkit-input-placeholder {
  color: #858796;
}

.search-bar-form-control::placeholder {
  color: #858796;
}

.research-search-bar .research-search-button {
  height: 31px;
  padding: 0;
  width: 15%;
  font-size: 20px;
  background: transparent;
  color: #858796;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: none;
  border-radius: 0;
  transition: color 0.13s background 0.13s;
}

.research-search-bar .research-search-button:hover {
  color: black !important;
}

.research-search-part {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 16px;
  color: #858796;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  transition: color 0.13s;
}

.research-search-part:not(:last-child) {
  margin-bottom: 10px;
}

.research-left-col #current-category {
  color: black !important;
}

.research-search-part .research-icon {
  margin-right: 10px;
}

.research-search-part:hover {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.article-content-contributor-image-col {
  margin: 0;
  padding: 0;
  display: inline-block !important;
}

.article-content-contributor-image-div {
  width: 60px;
  height: 60px;
  /* margin-right: 10px; */
}

.article-content-col .contributor-image {
  max-width: 100%;
}

.article-content-col {
  margin: 0;
  padding: 0;
}

.article-content-title-summary-col {
  flex-direction: column;
  display: flex;
}

.article-content-title-row a {
  font-weight: bold;
  font-size: 22px;
  /* color: rgba(0, 0, 0, 0.5); */
}

.article-content-title-row a:hover {
  text-decoration: none;
}

.research-trending-section .payout-tabs {
  width: 100%;
  border: 1px solid transparent;
}

.research-trending-section .payout-tabs a {
  background: transparent !important;
  color: #858796;
  font-weight: normal;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.research-trending-section .payout-tabs a.active {
  background: transparent;
  color: black;
  font-weight: normal;
  border-bottom: none;
}

.research-trending-section .payout-tabs a.active:hover {
  border-bottom: none;
}

.research-trending-section .trending-topics-tabs-col {
  color: #858796;
  padding: 0;
  margin: 0;
}

.trending-topic-tab {
  color: #858796;
}

.no-trending-topics {
  text-align: center;
}

.no-padding {
  margin: 0;
  padding: 0;
}

.article-content-contributor-info-div {
  /* justify-content: flex-start; */
}

.article-content-contributor-info-div #display-name {
  color: black;
  font-size: 14px;
  margin: 0;
}

.article-content-contributor-info-div #date-published {
  margin: 0;
}

.vertically-aligned {
  /* justify-content: center;
  display: flex;
  align-items: center; */
}

.article-content-image-div {
  width: 100%;
}

.article-content-image {
  width: 100%;
  max-height: 100px;
  object-fit: cover;
}

.render-insight-container {
  /* justify-content: space-between; */
  margin: 0;
  padding: 0;
  border-radius: 2px;
  padding: 15px;
  display: flex;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 150px;
  max-height: 300px;
}

.render-insight-container:not(:last-child) {
  margin-bottom: 15px;
}

.insight-contributor-information-container {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.insight-contributor-image-container {
  display: block;
}

.insight-contributor-image-container .contributor-image {
  width: 48px;
  height: 48px;
}

.insight-contributor-name-date-container {
  margin-left: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 20px;
}

.insight-contributor-name-date-container a {
  color: #858796;
  /* font-weight: bold; */
  transition: 0.13s;
  font-size: 12px;
}

.insight-article-information-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.insight-article-title-summary-container {
  display: block;
  margin-right: 20px;
}

.insight-article-title-summary-container .article-title {
  /* font-weight: bold; */
}

.insight-article-title-summary-container a {
  transition: color 0.13s;
  color: #858796;
}

.insight-article-title-summary-container a:hover {
}

.insight-article-title-summary-container .article-summary {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.insight-article-image-container {
}

.insight-article-image-container .default-image {

}

.insight-article-image-container .article-image {
  height: 100%;
  width: 200px;
  object-fit: contain;
}

.rounded-circle{
  border-radius:5% !important;
}

.login-modal {
  background: rgba(0, 0, 0, 0.3);
}

.login-modal .modal-content {
  margin-top: 50%;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #136dbd;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  color: #858796;
  border: 1px solid #1588ee;
}

.login-modal .modal-header {
  padding: 0;
  padding-right: 5px;
  border-bottom: none;
}

.login-modal .modal-body {
  padding: 0 40px 24px 40px;
}

.login-modal .sign-up-button {
  background: transparent;
  border-radius: 0;
  color: #ff6b00;
  font-size: 16px;
  border: 1px solid #ff6b00;
  width: 160px;
  height: 60px;
  font-weight: bold;
  transition: box-shadow 0.13s color 0.13s background 0.13s;
}

.login-modal .sign-up-button:hover {
  border: 1px solid #1588ee;
}

.login-modal .login-modal-header {
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

.login-modal .login-modal-bottom-text {
  margin-top: 10px;
  display: inline-flex;
}

.login-modal .login-link {
  color: #ff6b00;
  margin-left: 4px;
  font-weight: bold;
}

.login-modal .login-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.login-modal .sign-up-button:hover {
  background: rgba(33, 122, 255, 1);
  color: #858796;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.login-modal .modal-body .row {
  justify-content: center;
}

.rdisc-current-category-header-text {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.rdisc-current-category-header-text h4 {
  margin-right: 8px;
}

.rdisc-follow-topic-button {
  padding: 0;
  height: auto;
  max-height: 20px;
  font-size: 10px;
  padding: 0 2px;
  background: transparent;
  color: #858796;
  border: 1px solid #858796;
}

.rdisc-follow-topic-button:hover {
  color: black;
  background: rgba(0, 0, 0, 0.13);
  border: 1px solid rgba(0, 0, 0, 0.13);
}

.article-top-level-container {
  margin: 0;
  padding: 0;
  margin-top: 70px;
  height: 100%;
}

.article-row {
  width: 100vw;
  padding: 20px;
}

.article-col {
  padding: 0px 100px;
}

.discussion-col {
  border-left: 1px solid rgba(0, 0, 0, 0.2)
}

.follow-button {
  background: transparent;
  color: rgba(33, 122, 255, 0.85);
  border: 1px solid rgba(33, 122, 255, 0.85);
  font-size: 12px;
  height: 20px;
  padding: 0 8px;
  margin-left: 10px;
}

.follow-button:hover {
  background: rgba(33, 122, 255, 0.85);
  border: 1px solid rgba(33, 122, 255, 0.85);
}

.preview-name-row p {
  height: 100%;
}

.preview-info-row {
  margin: 0;
  padding: 0;
}

.preview-info-row .col {
  padding: 0;
}

.preview-info-row .row {
  padding: 0;
  margin: 0;
  font-size: 12px;
  margin-bottom: 10px;
}

.info-piece {
  margin-left: 10px;
  background: rgba(33, 122, 255, 0.85);
  border-radius: 25px;
  padding: 0 8px;
  min-width: 40px;
  font-size: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

.info-piece:hover {
  cursor: default;
}

.likes-dislikes svg {
  color: rgba(0, 0, 0, 0.3);
  width: 20px;
  height: 20px;
  transition: color 0.13s;
}

.likes-dislikes svg:hover {
  color: rgba(33, 122, 255, 0.85);
  border-radius: 2px;
  cursor: pointer;
}

.article-collections-add-icon {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.6);
}

.article-collections-add-icon:hover {
  color: rgba(33, 122, 255, 0.85);
  cursor: pointer;
}

.likes-dislikes .thumb-up {
  margin-right: 5px;
}

.likes-dislikes .thumb-down {
  margin-left: 15px;
  margin-right: 5px;
}

.likes-dislikes .bookmark {
  margin-left: 15px;
}

.discussion-col {
  margin: 0;
  /* padding: 0;
  padding: 5px; */
  justify-content: flex-start;
}

.discussion-col h3 {
  color: rgba(33, 122, 255, 0.85);
  margin-bottom: 20px;
}

.discussion-comments-row {
  width: 100%;
  padding: 0;
  margin: 0;
}

.discussion-comments-col {
  margin: 0;
  padding: 0;
}

.article-comment-row {
  padding: 0;
  margin: 0;
}

.article-comment-first-col, .article-comment-second-row {
  padding: 0;
  margin: 0;
}

.article-comment-image-col {
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  /* margin-right: 5px; */
}

.article-comment-image-col .preview-user-image {
  margin: 0;
  padding: 0;
  max-height: 100%;
  max-width: 100%;
  padding: 5px;
  object-fit: cover;
}

.article-comment-content-col {
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 10px 10px 0px;
}

.article-comment-content-col .comment-info-row {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.comment-info-row .username {
  font-weight: bold;
  color: rgba(33, 122, 255, 0.85);
  margin: 0;
  padding: 0;
  margin-right: 20px;
}

.comment-info-date-published {
  color: grey;
  font-size: 10px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.comment-content-row {
  padding: 0;
  margin: 0;
  font-size: 12px;
  padding-right: 20px;
}

.comment-likes-dislikes-row {
  margin: 0;
  padding: 0;
  font-size: 12px;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;

}

.comment-likes-dislikes-row svg {
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.13s;
}

.comment-likes-dislikes-row svg:hover {
  color: rgba(33, 122, 255, 0.85);
  cursor: pointer;
}

.comment-likes-dislikes-row .thumb-up {
  margin-right: 5px;
}

.comment-likes-dislikes-row .thumb-down {
  margin-left: 10px;
  margin-right: 5px;
}

.preview-body-row {
  margin-top: 20px;
}

.preview-body-row p {
  padding: 0 !important;
  margin: 0;
}

.loading-spinner-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  position: relative;
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
}

.loading-spinner {
  position: absolute;
  top: 50%;
  bottom: 50%;
}

.related-articles-top-level-row {
  width: 100vw;
  justify-content: center;
}

.related-articles-row {
  /* padding: 0 40px; */
  padding: 0;
  margin-bottom: 40px;
  justify-content: center;
  /* background: red; */
  width: 100vw !important;
  max-width: 100vw !important;
}

.related-article-col {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  padding-bottom: 10px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  border-radius: 2px;
}

.single-report-col {
  margin: 0;
  padding: 0;
  margin: 10px;
  margin-top: 20px;
  padding-bottom: 10px;
  background: white;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.related-article-col:not(:last-child) {
  margin-right: 20px;
}

.related-article-col .row {
  margin: 0;
  padding: 0;
}

.related-article-image-row {
  margin: 0;
  padding: 0;
}

.related-article-navlink-row {
  width: 100%;
}

.related-article-image {
  height: 200px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.related-article-title-row {
  font-weight: bold;
  padding: 0;
  margin: 0;
  padding-left: 10px !important;
  margin-top: 10px !important;
}

.related-article-title-row a {
  color: white;
}

.related-article-title-div:hover {
  color: white;

}

.related-article-info-row {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  padding: 0;
  margin: 0;
  padding-left: 10px !important;
}

.related-article-info-row a {
  color: rgba(255, 255, 255, 0.6);
  margin-right: 5px;
}

.related-article-info-row a:hover {
  color: white;
}

.related-article-info-div {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 3px;
}

.related-articles-header-row {
  margin-top: 10px;
  justify-content: center;
}

.related-articles-header-text {
  color: white;
  font-size: 20px;
}

.related-articles-render-articles-row {
  justify-content: center;
}

.comment-form {
  width: 100%;
}

.comment-form .row {
  margin: 0;
  justify-content: flex-end;
}

.comment-box {
  border-radius: 0;
  background: transparent;
  color: white;
  border: 1px solid #1588ee;
  min-height: 40% !important;
  max-height: 60% !important;
  height: 100px !important;
}

.comment-box:focus {
  background: transparent;
  color: white;
}

.post-button {
  margin: 10px 0;
  padding: 0;
  padding: 0 10px;
  border: 1px solid #1588ee;
  background: transparent;
  color: #1588ee;
  border-radius: 0;
  width: 100px;
}

.post-button:hover {
  background: #1588ee;
  color: white;
  border: 1px solid #1588ee;
}

#related-article {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.preview-report-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.preview-report-image-col {
  justify-content: center;
  vertical-align: center;
  align-items: center;
  display: flex;
}

.preview-contributor-info-col {
}

.article-container {
  color: #858796 !important;
}

.article-container .article-top-row {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.article-container .article-top-row .col-md-2, .article-container .article-top-row .col-md-8, .article-container .article-top-row .col-md-1 {
  padding: 0;
  margin: 0;
}

.article-container .article-top-row .article-first-col {
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  padding: 20px;
}

.render-analytics-row {
  margin: 0;
  padding: 0;
}

.article-container .article-top-row .article-third-col {
}

.article-container .article-top-row .article-second-col {

}

.render-article-top-row {
  margin: 0;
  padding: 0;
  padding-top: 10px;
  /* margin-bottom: 20px; */
}

.render-article-image-col {
  margin: 0;
  padding: 0;
  border-radius: 5px;
}

.render-analytics-likes-dislikes {
  width: 100%;
  margin-top: 50%;
  margin-bottom: 10px;
  position: -webkit-sticky !important;
  position: sticky !important;
}

.render-analytics-likes-dislikes .col {
  margin: 0;
  padding: 0;
  width: 100%;
}

.render-analytics-likes-dislikes .col .row {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
}

.render-analytics-likes-dislikes .thumb-up,
 .render-analytics-likes-dislikes .thumb-down,
  .render-analytics-likes-dislikes .bookmark {
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.13s;
}

.render-analytics-likes-dislikes .thumb-up:hover,
 .render-analytics-likes-dislikes .thumb-down:hover,
  .render-analytics-likes-dislikes .bookmark:hover {

  cursor: pointer;
}

.render-analytics-topics-row {
  /* background: orange; */
  /* border-top: 1px solid rgba(0, 0, 0, 0.13); */
  padding-top: 10px;
  width: 100%;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.54);
}

.render-analytics-topics-row .col {
  margin: 0;
  padding: 0;
}

.render-analytics-topics-row .col .row {
  margin: 0;
  padding: 0;
}

.render-analytics-topics-row .topics-row {
  margin: 0;
  padding: 0;
  width: 100%;
}

.render-analytics-topics-row .topics-row .col {
  margin: 0;
  padding: 0;
}

.render-analytics-topics-row .individual-topic {
  background: rgba(33, 122, 255, 1);
  color: white;
  margin: 0;
  padding: 0;
  padding: 0 15px;
  border-radius: 2px;
  margin-right: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.render-analytics-category-row {
  /* border-top: 1px solid rgba(0, 0, 0, 0.13); */
  padding-top: 10px;
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
}

.render-article-image {
  margin: 0;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.render-article-info-col {
  margin-bottom: 20px;
}

.render-article-title-row h1 {
  color: white;
}

.render-article-summary-row h2 {
  color: rgba(255, 255, 255, 0.6);
}

.render-article-user-image-col {
  margin: 0;
  padding: 0;
}

.render-article-user-image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.render-article-user-info-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.render-article-name-row {
}

.render-article-name-row p {
  font-size: 14px;
  margin: 0;
  /* color: rgba(0, 0, 0, 0.6); */
}

.article-user-name {
  color: rgba(255, 255, 255, 0.6);
}

.article-user-name:hover {
  color: white;
}

.render-article-date-row {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.render-article-date-row .col {
  justify-content: flex-start;
  display: flex;
}

.render-article-body-row {
  margin: 0;
  padding: 0;
}

.render-article-body-row * {
  color: white !important;
  background: transparent !important;
  background-color: transparent !important;
  color: white !important;
  font-size: 16px;
}

.render-article-body-row * a {
  color: #ff6b00 !important;
  background: transparent !important;
  background-color: transparent !important;
}

.render-article-body-row * a * {
  color: #ff6b00 !important;
  background: transparent !important;
  background-color: transparent !important;
}

.render-article-date-row .left-justified-col {
  align-items: flex-start !important;
}

.article-contributor-info-row {
  margin: 0;
  padding: 0;
  width: 100%;
  /* background: red; */
}

.article-contributor-info-row .col-md-8 {
  margin: 0;
  padding: 0;
  /* background: orange; */
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  margin-bottom: 10px;
}

.article-topics-row,
  .article-topics-row .col-md-2,
    .article-topics-row .col-md-8,
      .article-topics .row {
  margin: 0;
  padding: 0;
}

.article-topics a:hover {
  text-decoration: none;
}

.article-topics-row {
  margin-bottom: 20px;
}

.article-topics-row .individual-topic {
  margin: 0;
  padding: 0;
  padding: 2px 10px;
  background: transparent;
  color: rgba(33, 122, 255, 1);
  border: 1px solid rgba(33, 122, 255, 1);
  border-radius: 2px;
  margin-right: 10px;
  transition: background 0.12s color 0.12s;
}

.article-topics-row .individual-topic:hover {
  cursor: pointer;
  background: rgba(33, 122, 255, 1);
  color: white;
}

.article-discussion-row {
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
  padding: 20px 0;
  /* height: 100%; */
}

.render-discussion-col {
  margin: 0;
  padding: 0;
}

.render-discussion-col h3 {
  color: black;
  font-size: 18px;
}

.render-article-comment-row,
  .render-article-comment-first-col,
    .render-article-comment-second-row,
      .render-article-comment-image-col,
        .render-article-comment-content-col,
          .render-comment-info-row,
            .render-comment-info-date-published,
              .render-comment-content-row,
                .render-comment-likes-dislikes-row {
  margin: 0;
  padding: 0;
}

.render-article-comment-row {
  padding: 10px;
  border-radius: 0;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
  color: white;
}

.render-article-comment-second-row {
  margin-bottom: 15px;
}

.render-article-comment-image-col {
}

.render-user-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 0;
}

.render-article-comment-content-col .render-comment-info-row .username {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.render-article-comment-content-col .render-comment-info-row .username:hover {
  font-size: 14px;
  color: white;
  text-decoration: underline;
}

.render-comment-info-date-published {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.render-comment-content-row {
  margin-bottom: 10px;
}

.render-comment-likes-dislikes-row {
  font-size: 14px;
  align-items: center;
}

.render-comment-likes-dislikes-row .thumb-up,
 .render-comment-likes-dislikes-row .thumb-down {
  transition: color 0.13s;
  margin-right: 5px;
}

.render-comment-likes-dislikes-row .thumb-up:hover,
 .render-comment-likes-dislikes-row .thumb-down:hover {
  color: rgba(33, 122, 255, 1);
  cursor: pointer;
}

.render-comment-likes-dislikes-row .thumb-down {
  margin-left: 15px;
}

.render-discussion-show-more-row {
  margin: 0;
  padding: 0;
  justify-content: center;
  width: 100%;
}

.pagination .active {
  margin: 0;
  padding: 0;
  background: transparent !important;
}

.pagination .active .page-link {
  margin: 0;
  padding: 0;
  background: rgba(33, 122, 255, 1);
  border-radius: 5px;
}

.pagination .page-link {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  justify-content: center;
  background: transparent;
  border: none;
  font-size: 12px;
  vertical-align: center;
  align-items: center;
  display: flex;
}

.render-discussion-show-more-row div {
  text-decoration: underline;
  color: rgba(33, 122, 255, 1);
  margin-right: 10px;
}

.render-discussion-show-more-row div:hover {
  cursor: pointer;
  color: rgba(33, 122, 150, 1);
}

.render-analytics-likes-dislikes .col .row,
  .render-analytics-likes-dislikes .col {
  margin: 0;
  padding: 0;
}

.render-analytics-likes-dislikes .icon-col {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.render-analytics-likes-dislikes .analytics-row {
  margin-bottom: 10px !important;
}

.render-analytics-likes-dislikes .values-col {
  justify-content: flex-start;
  display: flex;
  color: white;
  font-size: 14px;
  align-items: center;
}

.render-analytics-likes-dislikes .thumb-up,
  .render-analytics-likes-dislikes .thumb-down,
    .render-analytics-likes-dislikes .bookmark,
      .render-analytics-likes-dislikes .fa-reg-eye {
      margin-right: 5px;
      color: rgba(255, 255, 255, 0.6);
  }

.render-analytics-likes-dislikes #isBookmarked,
  .render-analytics-likes-dislikes #blue-icon {
  color: rgba(33, 122, 255, 1);
  transition: color 0.13s;
}

.render-analytics-likes-dislikes #isBookmarked:hover,
  .render-analytics-likes-dislikes #blue-icon:hover {
    color: white;
  }

.article-related-articles-row, .article-related-articles-col, .related-artcles-row {
  margin: 0;
  padding: 0;
}

.article-related-articles-row {
  background: rgba(0, 0, 0, 0.05);
}

#exclamation-triangle {
  color: rgba(255, 255, 255, 0.6);
}

#exclamation-triangle:hover {
  cursor: pointer;
  color: orange;
}

.report-modal {
  background: rgba(0, 0, 0, 0.3);
  padding-top: 10%;
}

.report-modal .modal-content {
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

.report-modal .modal-header {
  border: none;
}

.report-modal .modal-header button {
  color: white;
  font-weight: normal;
}

.report-modal-button-row {
  justify-content: center;
}

.report-modal-button-row button {
  background: transparent;
  border-radius: 0;
  color: #1588ee;
  border: 1px solid #1588ee;
  width: 100px;
}

.report-modal-button-row button:hover {
  background: transparent;
  border-radius: 0;
  color: white;
  border: 1px solid #1588ee;
  background: #1588ee;
}

.report-modal .form-control {
  color: white;
  background: #083f70;
}

.report-modal-form-control {
  border: 1px solid #1588ee;
  border-radius: 0;
  color: white;
}

.report-modal-form-control-textarea {
  border-radius: 0;
  border: 1px solid #1588ee;
  color: white;
}

.report-not-found-container {
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  margin-top: 90px;
  background-color: rgb(211, 211, 224);
  background-image: url(/static/media/search-tokens-background.fa8af99f.png);
}

.report-not-found-col {
  margin-top: 8%;
}

.report-not-found-row {
  justify-content: center;
  font-size: 32px;
  /* color: white; */
}

.report-not-found-bottom-row {
  margin-top: 20px;
}

.share-buttons-row {
  margin-bottom: 20px;
}

.share-buttons-row .share-button:not(:last-child) {
  margin-right: 2px;
}

.article-collections-add-popover {
  max-width: 250px;
  width: 250px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0;
}

.article-collections-add-popover .popover-header {
  background: transparent;
  border-bottom: none;
}


.article-collections-search-bar {
  height: 28px;
  font-size: 12px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #5db2fe;
  color: white;
}

.article-collections-search-bar:focus {
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

.article-collections-search-bar::-webkit-input-placeholder {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.article-collections-search-bar::placeholder {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.article-collections-search-row {
  align-items: center;
}

.article-collections-search-icon-col {
  justify-content: center;
  display: flex;
}

.article-single-collection {
  font-size: 10px;
  color: white;
}

.article-single-collection:not(:last-child) {
  border-bottom: 1px solid #1588ee;
}

.article-single-collection:hover {
  background: rgba(0, 0, 0, 0.04);
}

.article-collections-row {
  align-items: center;
}

.article-collections-button-row {
  align-items: center;
}

.article-collections-icon {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}


.article-collections-add-icon:hover {
  color: white;
}

.article-collections-add-button {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.article-collections-add-button:hover {
  color: white;
  cursor: pointer;
}

.article-collections-tooltip {
  font-size: 10px;
}

.article-no-collections-found {
  justify-content: center;
  display: flex;
}

.view-article-container {
  height: calc(100% - 70px);
  background: #F8F9FC;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.new-article-container {
  width: 1100px;
  margin: 16px 0;
  background: white;
  padding: 32px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.article-category {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
  color: white;
  font-size: 14px;
  margin-right: 4px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-width: 20px;
  justify-content: center;
  display: flex;
  padding: 2px 8px;
  border-radius: 3px;

}

.discover-article-categories-container {
  display: flex;
  margin-left: 32px;
}

.contributor-profile-container {
  margin: 0;
  padding: 0;
  padding-top: 93px;
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
  max-width: 100%;
  height: 100%;
  position: relative;
}

.cp-profile-container-column {
  /* background-color: #073966; */
  background: transparent;
}

.cp-user-banner {
  background: #073966;
  border-radius: 0;
  height: 240px;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  position: relative;
}

.cp-change-banner-image-edit-icon {
  position: absolute;
  top: 5px;
  border-radius: 100px;
  right: 5px;
  color: #0c5ebe;
  background: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  padding: 2px;
  z-index: 100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cp-change-banner-image-edit-icon:hover {
  color: white;
  background: #0c5ebe;
  cursor: pointer;
}


.cp-user-banner-column {
  position: absolute;
  top: 0;
  left: 0;
}

.cp-user-banner-info-row {
  color: white;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.cp-user-banner-info-name-row {
  background: rgba(0, 0, 0, 0.4);
  /* bottom: 10px; */
  /* position: absolute; */
  width: 100%;
  padding: 5px 10px 5px 60px;
  align-items: center;
  z-index: 99;
}

.cp-user-banner-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cp-user-banner-image-uploader .fileContainer {
  border-radius: 0;
  background: #042038;
  height: 240px;
  color: white;
  justify-content: center;
  vertical-align: top;
  display: flex;
  padding: 0;
  margin: 0;
  z-index: 90;
}

.cp-user-banner-image-uploader .chooseFileButton {
  background: transparent;
  border: 1px solid white;
  margin-bottom: 60px;
  font-size: 12px;
}

.cp-user-banner-image-uploader .chooseFileButton:hover {
  background: #0c5ebe;
  border: 1px solid #0c5ebe;
  color: white;

}

.cp-user-banner-info-column {
}

.cp-user-banner-display-name-row {
  font-size: 28px;
}

.cp-user-banner-coverage-row {
  font-size: 14px;
}

.cp-user-banner-share-column {
  justify-content: center;
  display: flex;
}

.cp-user-banner-share-button {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.8);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 100px;
}

.cp-user-banner-share-button:hover {
  background-color: #0c5ebe;
  border: 1px solid #0c5ebe;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cp-user-content-section {
  /* background: #073966; */
  background: transparent;
  position: relative;
  margin-bottom: 20px;
}

.cp-user-content-about-column {
  height: 580px;
  margin-top: -200px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 92;
  margin-left: 40px;
}

.cp-user-content-user-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.cp-user-content-follow-button-row {
  margin-top: -50px;
  justify-content: center;
}

.cp-user-follow-button {
  background: rgba(255, 255, 255, 0.8);
  color: #073863;
  border: 1px solid #073863;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 12px;
  padding: 4px 30px;
  border-radius: 100px;
}

.cp-user-follow-button:hover {
  background: #073863;
  color: white;
  border: 1px solid #073863;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cp-user-content-about-section {
  margin-top: 15px;
  padding: 15px;
  padding-bottom: 0;
  /* margin-bottom: 20px; */
  /* padding-top: 0; */
}

.cp-user-content-about-text-row {
  height: 140px;
  max-height: 140px;
  overflow-y: scroll;

}

.cp-user-content-about-text-row::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 2px;
}

.cp-user-content-about-text-row::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 10px;
}

.cp-user-content-about-section .col {
  padding-bottom: 10px;
  border-bottom: 1px solid #0b5699;
}

.cp-user-content-about-section h5 {
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
}

.cp-user-content-about-section p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.cp-user-content-stats-section {
  padding: 15px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  /* position: absolute; */
  width: 100%;
  /* bottom: 15px; */

}

.cp-user-stat {
  /* padding-top: 10px; */
  font-size: 14px;
}

.cp-user-stat:not(:last-child) {
  margin-bottom: 5px;
}

.cp-user-content-research-column {
  /* margin-top: 10px;
  margin-left: 50px; */
}

.cp-user-content-research-column h3 {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.cp-published-research-column {
  margin-left: 60px;
}

.cp-individual-article {
  margin-bottom: 20px;
  /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  padding: 10px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 120px;
}

.cp-individual-article .col-md-3 {
  justify-content: center;
  display: flex;
  align-items: center;
}

.cp-individual-article-title a {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 5px;
}

.cp-individual-article-summary {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  overflow-y: scroll;
}

.cp-individual-article-summary::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 2px;
}

.cp-individual-article-summary::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 5px;
}

.cp-individual-article-date-published {
  font-size: 12px;
  color: #158df9;
  /* margin-top: 5px; */
  position: absolute;
  bottom: 0;
}

.cp-individual-article-image {
  width: 100%;
  object-fit: contain;
}

.cp-individual-article-image-no-image {
  background: rgba(0, 0, 0, 0.3);
}

.cp-no-articles-published {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-bottom: 100px;
}


.modal-open {
  overflow-y: inherit !important;
  padding-right: 0 !important;
}

body.modal-open .contributor-profile-container .row {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.cp-share-profile-modal {
  margin-top: -93px;
  background: rgba(0, 0, 0, 0.3);
}

.cp-share-profile-modal .modal-content {
  border: 1px solid #1588ee;
  border-radius: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  color: white;
}

.cp-modal-header-text {
  margin-bottom: 20px;
  justify-content: center;
}

.cp-copy-link-form-control {
  border-radius: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #1588ee;
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

.cp-copy-clipboard-icon {
  color: #1588ee;
}

.cp-copy-clipboard-icon:hover {
  color: white;
  cursor: pointer;
}









.contributor-profile-top-row {
}

.contributor-profile-col-a {
  background: white;
  padding: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.contributor-profile-col-a .row {
  margin: 0;
  padding: 0;
}

.contributor-profile-avatar-row {
  justify-content: center;
}

.contributor-profile-avatar {
  width: 100%;
  min-height: 200px;
  object-fit: contain;
}

.contributor-profile-col-b {
  background: white;
  padding: 10px;
}

.contributor-profile-col-b .row {
  padding: 0;
  margin: 0;
}

.contributor-profile-tabs-col {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.contributor-user-info {
  font-weight: bold;
  font-size: 24px;
  padding: 0;
  margin: 0;
  height: 35%;
}

.contributor-social-row a:not(:last-child) {
  margin-right: 10px;
}

.contributor-social-icon {
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.1s;
}

.contributor-social-icon:hover {
  color: rgba(33, 122, 255, 1);
  cursor: pointer;
}

.contributor-social-icon:not(:last-child) {
  margin-right: 10px;
}

.contributor-user-name-div {
  height: 100%;
  width: 100%;
}

.contributor-user-info .row {
  align-items: center;
}

.contributor-about-section {
  height: 65%;
}

.contributor-about {
  margin: 0;
  height: 50%;
  color: rgba(0, 0, 0, 0.5);
}

.contributor-followers-section {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.border-container {
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 5px;
  border-radius: 2px;
}

.contributor-focus {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.contributor-focus .col {
  margin: 0;
  padding: 0;
}

.contributor-about-label {
  color: rgba(0, 0, 0, 0.5);
}

.contributor-about-stat {
  text-align: right;
}

.payout-tabs .test-tab {
  background: red;
}

.contributor-profile-card {
  background: white;
  margin: 0;
  padding: 0;
  padding: 15px;
  margin-bottom: 15px;
  /* margin-top: 30px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
}

.tab-column {
  margin-top: 15px;
}

.profile-title-text {
  font-size: 32px;
  /* background: red; */
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  /* border-bottom: 1px solid black; */
  /* margin-bottom: 10px; */
}

.top-section {
  margin-top: 15px;
}

.research-section-header {
  text-align: center;
  /* color: rgba(0, 0, 0, 0.5); */
  /* border-bottom: 1px solid rgba(33, 122, 255, 1); */
}

.about-and-research-section-container {
  margin: 0;
  padding: 0;
}

.tab-row {
  margin-top: 10px;
}

.research-tab-column {
  -webkit-columns: 2 auto;
          columns: 2 auto;
}

.contributor-profile-article {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  padding: 5px;
  width: 45%;
}

.contributor-profile-article .date-published {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.contributor-profile-article .article-title {
  font-size: 14px;
  font-weight: bold;
}

.contributor-profile-article .article-summary {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.contributor-profile-article .article-image {
  width: 100%;
  height: 60px;
}

.contributor-article-col {
  margin-bottom: 40px;
}

.cp-user-content-user-image-edit-icon {
  position: absolute;
  top: 5;
  right: 5;
}

.cp-user-avatar-image-uploader .fileContainer {
  border-radius: 0;
  background: #042038;
  height: 300px;
  color: white;
  justify-content: center;
  vertical-align: top;
  display: flex;
  padding: 0;
  margin: 0;
}

.cp-user-avatar-image-uploader .chooseFileButton {
  background: transparent;
  border: 1px solid white;
  margin-bottom: 60px;
  font-size: 12px;
}

.cp-user-avatar-image-uploader .chooseFileButton:hover {
  background: #0c5ebe;
  border: 1px solid #0c5ebe;
  color: white;

}


.token-listed-container {
  height: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  margin-top: 90px;
  background: #064580; /* Old browsers */
  background-image: linear-gradient(to top, #031729, #073966);
}

.token-listed-container-2 {
  height: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  margin-top: 90px;
  background: #064580; /* Old browsers */
  background-image: linear-gradient(to top, #073966, #031729);
}

.top-bar-jumbotron {
  background-image: url(/static/media/splash-image.e5fe501a.png);
  background-size: auto;
  background-color: transparent;
  left: 10%;
  display: block;
  overflow: auto;
  border-radius: 0;
  /* height: 60%; */
  width: 100vw;
  max-width: 100vw;
  /* -webkit-clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%); */
  /* z-index: -99 !important; */
  position: absolute;
}

.token-listed-header-row .header-text {
  z-index: 2;
}

.header-text h3 {
  font-size: 40px;
  font-weight: bold;
  color: #60abed;
}

.header-text .last-item-in-this-list {
  margin-bottom: 20px;
}

.header-text h4 {
  color: white;
}

.header-text p {
  color: white;
  font-size: 16px;
  font-weight: normal;
}

.token-listed-header-row {
  margin-top: 90px;
  padding-top: 60px;
  margin-bottom: 60px;
}

.token-listed-form-box {
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  padding: 20px 20px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 14px;
  border-radius: 2px;
  color: white;
  /* color: #1963a3; */
}

.token-listed-form-box .form-control {
  height: 28px;
  border: none;
  background: transparent;
  border-bottom: 1px solid #1588ee;
  border-radius: 0;
  font-size: 14px;
}

.token-listed-form-box input, .token-listed-form-box select {
  color: white !important;
}

.token-listed-form-box option {
  background: #083f70;
}

.token-listed-form-box .row {
  justify-content: center;
}

.token-listed-form-box .submit-button {
  background: transparent;
  color: #1588ee;
  border: 1px solid #1588ee;
  width: 100px;
  border-radius: 2px;
}

.token-listed-form-box .submit-button:hover {
  background: #1588ee;
  color: white;
}

.token-listed-container .form-row {
  position: absolute;
  top: 154px;
  right: 25%;
  z-index: -100;
}

.token-listed-bottom-row {
  margin-top: 90px;
  padding-bottom: 40px;
}

.token-listed-bottom-row h4 {
  color: #1963a3;
  font-weight: bold;
}

.token-listed-bottom-row li {
}

.token-listed-bottom-row ul {
  margin: 0;
  padding: 0;
  padding-left: 15px;
  margin-bottom: 5px;
  color: white;
}

.token-listing-image {
  width: 150px;
  height: 150px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.token-listed-top-row-text {
  margin-bottom: 20px;
}

.collections-container {
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  padding: 0;
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
}

.collections-container-top-row {
  height: 100%;
}

.collections-spinner-row {
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
}

.collections-spinner {
  color: #1586eb;
  width: 80px;
  height: 80px;
}

.collections-sidebar-column {
  margin: 0;
  padding: 0;
  background: #073966;
  padding-top: 93px;
  height: 100%;
  max-width: 15%;
  transition: max-width 0.5s ease-in-out;
}

.collections-sidebar-column#closed-sidenav {
  max-width: 3%;
  transition: max-width 0.5s ease-in-out;
}

.collections-sidebar {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  border-right: 1px solid #1063ad;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}

.collections-sidebar#open-sidenav .list-group-item {
  visibility: visible;
  opacity: 1;
  transition: opacity 1.2s ease-in;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

.collections-sidebar#closed-sidenav .list-group-item:not(.collections-sidebar-button-row) {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s ease-in, opacity 0.1s ease-in;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
}


.collections-sidebar-button-row {
  justify-content: flex-end;
  display: flex;
}

.collections-sidebar-button-row:hover {
  background: transparent !important;
  cursor: default !important;
}

.collections-sidebar-button:hover {
  cursor: pointer;
  color: white;
}

.collections-sidebar-folder {
  overflow-y: scroll;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.collections-sidebar-folder#open-collection {
  max-height: 500px;
  transition: max-height 0.5s ease-in-out;
}

.collections-sidebar-folder::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0.2);
}

.collections-sidebar-folder::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 20px;
}

.collections-title-row {
  align-items: center;
}

.collections-sidebar-icon {
  margin-right: 10px;
  height: 100%;
}

.collections-sidebar-add-column {
  align-items: center;
}

.collections-sidebar-add {
  margin: 0;
}

.collections-sidebar-add:not(:last-child) {
  margin-right: 10px;
}

.collections-sidebar-arrow {
  transition:         transform 0.5s ease-in-out;
}

.collections-sidebar-arrow:hover {
  color: white;
}

.collections-sidebar-arrow#open-collection {
  transform: rotate(90deg);
}

.collections-sidebar-add:hover {
  cursor: pointer;
  color: white;
}

.collections-sidebar .list-group-item {
  color: #1586eb;
  background: transparent;
  border: 1px solid transparent;
}

.collections-sidebar .list-group-item:hover {
  background: rgb(16, 99, 173, 0.5);
  cursor: pointer;
}

.collections-sidebar-item-my-collections {
  border-bottom: 1px solid #1063ad !important;
}

.collections-sidebar-item-my-collections:hover {
  /* cursor: default !important; */
  /* background: transparent !important; */
}


.collections-sidebar .active {
  background: rgb(16, 99, 173, 0.8);
  color: #58b0ff;
  font-weight: bold;
}

.collections-sidebar-collection-item {
  border-left: 1px solid #1063ad !important;
  margin-left: 20px !important;
}

.collections-sidebar-collection-item .row {
  align-items: center;
}

.collections-sidebar-item-discover {
  margin-bottom: 20px;
}

.collections-sidebar-item-discover:hover {
  cursor: pointer;
}

.collections-sidebar-no-collections {
  border-left: 1px solid #618bb1 !important;
  margin-left: 20px !important;
  color: #618bb1 !important;
}

.collections-sidebar-no-collections:hover {
  background: transparent !important;
  cursor: default !important;
}

.collections-content-column {
  padding: 0;
  padding-top: 93px;
  height: 100%;
}

.collections-info {
  margin: 0;
  color: white;
  background: #0a4478;
  /* height: 180px; */
  padding: 15px 0;
  /* overflow-y: scroll; */
}

.collections-info-icons-column {
  justify-content: flex-end;
  display: flex;
}

.collections-info-image-column {
  justify-content: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.collections-info-image-column img {
  border: none;
}

.collections-info-image {
  background: #093965;
  width: 100%;
  max-width: 100%;
  max-height: 150px;
  height: 150px;
  object-fit: cover;
}

.collections-info-image-no-data {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  background: #093965;
  /* max-width: 260px;
  width: 260px; */
  width: 100%;
  max-width: 100%;
  height: 150px;
  object-fit: cover;
  border: 1px solid transparent;
}

.collections-info-image-no-data p {
  margin: 0;
}

.collections-info-image-no-data:hover {
  text-decoration: underline;
  cursor: pointer;
  border: 1px solid #1586eb;
}

.collections-info-image-static {
  background: #093965;
  /* max-width: 260px;
  width: 260px; */
  width: 100%;
  max-width: 100%;
  height: 150px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.collections-info-image-icon {
  padding: 0;
  margin: 0;
  font-size: 40px;
}

.collections-info-data-column {
}

.collections-info-data-row {
  height: 55%;
}

.collections-info-name-row {
  height: 50%;
  align-items: center;
  font-size: 22px;
}

.collections-info-privacy-column {
  align-items: center;
  justify-content: center;
  display: flex;
}

.collections-details {
  color: white;
  margin: 0;
}

.collections-details-data-column {
  overflow-y: hidden;
}

.collections-details-title {
  justify-content: center;
  color: #1586eb;
  margin-bottom: 10px;
  border-bottom: 1px solid #1586eb;
  font-size: 14px;
}

.collections-details-items {
  justify-content: center;
  overflow-y: hidden;
}

.collections-detail-individual-item {
  background: #1588ee;
  background-image: linear-gradient(to top, #052a41, #0c5ebe);
  padding: 2px 8px;
  font-size: 11px;
  border-radius: 25px;
  /* margin-top: 2px; */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}

.collections-detail-individual-item:not(:last-child) {
  margin-right: 5px;
  /* margin-top: 10px; */
}

.collections-privacy-icon-no-functionality {
  margin-right: 10px;
  color: #1586eb;
}

.collections-privacy-icon {
  margin-right: 10px;
  color: #1586eb;
}

.collections-privacy-icon:hover {
  cursor: pointer;
  color: white;
}

.collections-privacy-icon#no-functionality:hover {
  cursor: default !important;
  color: #1586eb !important;
}

.collections-edit-icon {
  color: #1586eb;
}

.collections-edit-icon:not(:last-child) {
  margin-right: 10px;
}

.collection-no-data-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.collection-no-data-image {
  background: #093965;
  width: 100%;
  max-width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.collection-no-data-image p {
  display: flex;
  align-items: center;
  margin: 0;
}

.collection-no-data-image:hover {
  text-decoration: underline;
  cursor: pointer;
}

.edit-collection-form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #1586eb;
  border-radius: 0;
  caret-color: white;
  color: white;
  font-size: 22px;
  padding-left: 0;
}

.edit-collection-form-control input {
  color: white;
}

.edit-collection-form-control:focus {
  background: transparent;
  color: white;
}

.edit-collection-form-control::-webkit-input-placeholder {
  color: #1586eb;
}

.edit-collection-form-control::placeholder {
  color: #1586eb;
}

.edit-collection-form-control-textarea {
  position: absolute;
  bottom: 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid #1586eb;
  border-radius: 0;
  caret-color: white;
  height: 50%;
  max-height: 50%;
  min-height: 50%;
  color: white;
  resize: none;
  padding-left: 0;
  padding-top: 0;
}

.edit-collection-form-control-textarea::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.edit-collection-form-control-textarea::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 20px;
}

.edit-collection-form-control-textarea:focus {
  background: transparent;
  color: white;
}

.edit-collection-form-control-textarea::-webkit-input-placeholder {
  color: #1586eb;
}

.edit-collection-form-control-textarea::placeholder {
  color: #1586eb;
}

.edit-collection-form-control-textarea input {
  color: white;
}

.collections-info-edit-icons-row {
  height: 100%;
}

.edit-collection-submit-button-column {
  justify-content: center;
  display: flex;
  align-items: center;
}

.edit-collection-submit-button {
  border-radius: 2px;
  background: transparent;
  color: #1586eb;
  border: 1px solid #1586eb;
  font-size: 12px;
  height: 30px;
}

.edit-collection-submit-button:hover {
  background: #1586eb;
  border: 1px solid #1586eb;
}

.collections-edit-icon:hover {
  cursor: pointer;
  color: white;
}

.edit-collection-image-clear-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 18px;
  border-radius: 100px;
  transition: background 0.2s;
}

.edit-collection-image-clear-button:hover {
  background: #1586eb;
  cursor: pointer;
}

.edit-collections-image-uploader .fileContainer {
  background: #093965;
  max-width: 100%;
  width: 100%;
  height: 150px;
  color: white;
  border-radius: 2px;
  padding: 0;
  margin: 0;
}

.edit-collections-image-uploader p {
  padding: 0 10px;
}

.edit-collections-image-uploader .chooseFileButton {
  border-radius: 2px;
  background: transparent;
  color: #1586eb;
  border: 1px solid #1586eb;
  font-size: 12px;
}

.edit-collections-image-uploader .chooseFileButton:hover {
  background: #1586eb;
  border: 1px solid #1586eb;
  color: white;
}

.collections {
  margin: 0;
  color: white;
  height: 100%;
  padding: 12px 0px;
  padding-bottom: 180px;
}

.collections-card-columns {
  width: 100%;
  -webkit-column-count: 5;
          column-count: 5;
}

.no-reports-in-collection {
  width: 100%;
  height: inherit;
  padding-top: 100px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.collection-card {
  border-radius: 2px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-height: 420px;
}

.collection-card-image {
  max-height: 220px;
  object-fit: cover;
}

.collection-card-description {
  max-height: 100px;
  overflow-y: scroll;
  font-size: 12px;
}

.collection-card-description::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.collection-card-description::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 20px;
}

.collection-card-name a {
  color: white;
}

.collection-card-contributor-info-row {
  align-items: center;
  color: #1588ee;
}

.collection-card-contributor-info-row a {
  color: #1588ee;
  font-size: 14px;
}

.collection-card-contributor-image {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 15px;
}

.collection-card-delete-icon {
  color: #1588ee;
  z-index: 100px;
}

.collection-card-delete-icon:hover {
  color: white;
  cursor: pointer;
}

.collections-delete-report-popover {
  background: #1063ad;
  background-image: linear-gradient(to top, #136dbd, #083f70);
  padding: 20px;
  width: 300px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.collections-delete-report-header {
  font-size: 14px;
  color: white;
}

.collections-delete-report-popover-button-row {
  justify-content: center;
  margin-top: 20px;
}

.collections-delete-report-popover-confirm-button {
  background: transparent;
  border: 1px solid #1588ee;
  color: #1588ee;
  border-radius: 2px;
}

.collections-delete-report-popover-confirm-button:hover {
  background: #1588ee;
  border: 1px solid #1588ee;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.collections-delete-icon-div {
  /* height: 0; */
  top: 0;
}

.collections-delete-collection-popover {
  background: #1063ad;
  background-image: linear-gradient(to top, #136dbd, #083f70);
  padding: 10px;
  width: 200px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  font-size: 12px;
}

.collections-delete-collection-header {
  display: flex;
  text-align: center;
  margin-bottom: 10px;
}

.collections-delete-collection-popover-button-row {
  justify-content: center;
}

.collections-delete-collection-popover-confirm-button {
  background: transparent;
  border: 1px solid #1588ee;
  color: #1588ee;
  border-radius: 2px;
}

.collections-delete-collection-popover-confirm-button:hover {
  background: #1588ee;
  border: 1px solid #1588ee;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.collections-please-wait-modal {
  padding-top: -93px;
  background: rgba(0, 0, 0, 0.3);
}

.collections-please-wait-modal .modal-content {
  color: white;
  background: #1063ad;
  background-image: linear-gradient(to top, #136dbd, #083f70);
  padding: 20px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.collections-please-wait-modal-header {
  justify-content: center;
  margin-bottom: 20px;
}

.collections-please-wait-modal-button-row {
  justify-content: center;
}

#publicAndPrivateTooltip {
  background-color: black;
  opacity: 1;
}

#publicAndPrivateTooltip::after {
  border-left-color: black;
}

.feed-container {
  padding: 0;
  /* height: 100%; */
  min-height: 100vh;
  background: #F8F9FC;
}

.discover-banner {
  border-radius: 0;
  background: white;
  color: #858796;
  font-size: 18px;
  padding: 30px 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.discover-banner-header {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}

.discover-banner-filter-row {
  justify-content: center;
  display: flex;
  align-items: center;
}

.discover-banner-dropdown:not(:last-child) {
  margin-right: 20px;
}

.discover-banner-dropdown-toggle {
  background: white !important;
  color: #858796 !important;
  border: 1px solid #858796 !important;
  border-radius: 2px;
  width: 160px;
}

.discover-banner-dropdown-toggle:hover {
  cursor: pointer;
  background: transparent;
  color: #858796;
  border: 1px solid #858796;
}

.discover-banner-dropdown-menu {
  padding: 0;
  margin: 0;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.discover-banner-form-input-radio {
  color: #858796;
}
.discover-banner-form-input-radio input:hover {
  cursor: pointer;
}

.discover-spinner-container {
  padding: 0;
}

.discover-spinner-container .row {
  justify-content: center;
  padding: 32px;
}

.discover-spinner {
  color: #858796;
  width: 80px;
  height: 80px;
}

.discover-content-row {
  margin-top: 20px;
  padding: 5rem;
  padding-top: 0;
  justify-content: center;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
}

.discover-content-column {
  color: white;
  margin: 0;
  padding: 0;
}

.discover-content-card-row {
  justify-content: center;
}

.discover-content-cardcolumns {
  -webkit-column-count: 4;
          column-count: 4;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.discover-card {
  border-radius: 2px;
  background: white;
  max-height: 420px;
}

.discover-report-card-image-link {
  color: #858796 !important;
  width: 100% !important;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.discover-report-card-image-link:hover {
}

.discover-report-card-image {
  border-radius: 0;
  max-height: 200px;
  width: 100%;
  object-fit: contain;
}

.discover-card-title-row {
  margin: 10px 10px 0 10px;
}

.discover-card-title-row a {
}

.discover-report-card-title {
  font-size: 18px;
  color: #858796 !important;
}

.discover-report-card-summary {
  margin: 0 10px 10px 10px;
  color: rgba(255, 255, 255, 0.7);
}

.discover-report-card-info-row {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.discover-report-card-info-row:hover, .discover-contributor-card-info-row:hover {
  cursor: default;
}

.discover-report-card-info-row .col {
  margin: 0;
  padding: 0;
}

.discover-report-card-icon-row {
  justify-content: center;
  align-items: center;
  color: #858796;
  font-size: 12px;
}

.discover-report-card-icon {
  margin-right: 10px;
  font-size: 14px;
}

.discover-card-type-tag-row {
  margin: 0;
  padding: 0;
  margin: 0 0 10px 10px;
}

.discover-card-type-tag {
  color: #858796;
  background: rgba(0, 0, 0, 0.13);
  padding: 2px 10px;
  border-radius: 100px;
  font-size: 10px;
  cursor: default;
}

.discover-card-contributor-col {
  margin: 0;
  padding: 0;
  padding: 10px;
}

.discover-card-contributor-top-row {
  align-items: center;
  margin-bottom: 10px;
}

.discover-card-contributor-image {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 10px;
}

.discover-card-contributor-name {
  font-size: 18px;
  margin-bottom: 10px;
  color: #858796;
}

.discover-card-contributor-name:hover {
  color: #858796;
}

.discover-collection-card-image {
  border-radius: 0;
  max-height: 200px;
  width: 100%;
  object-fit: cover;
}

.discover-collection-card-title {
  font-size: 18px;
  color: #858796 !important;
}

.discover-collection-card-title:hover {
  text-decoration: underline;
  color: #858796 !important;
}

.discover-collection-card-title a {
  color: #858796 !important;
}

.discover-explore-content-icon {
  color: #858796;
  font-size: 30px;
}

.discover-explore-content-icon#active {
  color: black;
}

.discover-explore-content-icon:not(:last-child) {
  margin-right: 10px;
}

.discover-explore-content-icon:hover {
  color: black;
  cursor: pointer;
}

.discover-content-view-column {
  max-width: 500px;
}

.discover-content-view-column-content {
  max-height: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.discover-content-view-column-content::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 2px;
}

.discover-content-view-column-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  height: 20px;
}

.discover-content-view-column h5 {
  text-align: center;
}

.discover-content-view-column:not(:last-child) {
  margin-right: 20px;
}

.discover-content-view-column .card {
  margin-bottom: 10px;
}

.discover-content-title {
  color: #858796;
}

.discover-search-bar {
  width: 300px;
  margin-right: 16px;
  color: #858796 !important;
}

.discover-search-bar::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.discover-search-bar::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.discover-content-col {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.discover-individual-research {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 1000px;
  height: 170px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.discover-article-user-image {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

.discover-article-image {
  /* height: 100px; */
  width: 160px;
  object-fit: cover;
}

.discover-article-image-container {
  align-items: center;
  display: flex;
}

.discover-article-column {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 16px !important;
  color: #858796 !important;
}

.discover-article-column.user-info {
  min-width: 120px;
}

.discover-article-username {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.discover-article-user-image-icon {
  width: 45px;
  height: 45px;
  color: #858796 !important;
}

.discover-article-date {
  width: 100%;
  font-size: 12px;
  color: #a5a8bb !important;
  /* font-style: italic; */
}

.discover-article-title {
  width: 100%;
  font-size: 22px;
  color: #858796 !important;
}

.discover-article-title a {
  color: #858796 !important;
  padding: 0;
}

.discover-article-summary {
  font-size: 14px;
  width: 100%;
  color: #a5a8bb !important;
}

.discover-broad-column {
  display: flex;
}

.nothing-found {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 800px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  color: #858796 !important;
}

.landing-page-2-container {
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
  background-image: url(/static/media/header-background-image.740e3547.png);
  height: 100%;
}

.lp2-header-jumbotron {
  height: 100px;
  border-radius: 0;
  background: transparent;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0;
}

.lp2-form-control-bar {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #1586eb;
  padding-right: 10px;
  width: 300px;
  padding: 0;
  margin: 0;
  border-radius: 2px;
}


.lp2-form-control-icon-container {
  width: 40px;
  height: 40px;
  justify-content: center;
  display: flex;
  font-size: 18px;
  color: white;
  align-items: center;
}

.lp2-form-control-icon-container:hover {
  background: rgb(0, 94, 255, 0.6);
  cursor: pointer;
}

.lp2-header-form-control {
  background: transparent;
  border: none;
  border-radius: 0px;
  color: white;
  width: 258px;
}

.lp2-header-form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);

}

.lp2-header-form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);

}

.lp2-header-form-control:focus {
  background: transparent;
  color: white;
}

.lp2-intro-jumbotron {
  margin: 0;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 80vh;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: white;
  font-size: 24px;
}

.lp2-intro-jumbotron h4 {
  font-size: 36px;
  color: #3d85ff;
  /* font-weight: bold; */
  margin-bottom: 20px;
  letter-spacing: 1.5px;
  /* font-weight: bold; */
}

.lp2-intro-text-group {
}

.lp2-intro-jumbotron a {
  color: #ff6b00 !important;
}

.lp2-intro-jumbotron .row {
}

.lp2-border-box {
  /* background: rgba(0, 0, 0, 0.5); */
  align-items: center;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lp2-border-box-2 {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.lp2-intro-column {
  padding: 0;
  margin: 0;
  /* background: red; */
}

.lp2-intro-column h4 {
  color: #3d85ff;
  /* font-weight: bold; */
  margin-bottom: 20px;
  font-size: 32px;
  text-align: center;
}

.lp2-intro-column p {
  color: white;
  font-size: 24px;
  text-align: center;

}

.lp2-button-row {
  padding-top: 20px;
}

.lp2-button-row .col .row {
  justify-content: center;
  color: white;
}

.lp2-image {
  height: 350px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.lp2-second-jumbotron {
  /* background: transparent; */
  background-color: #0d064d;
  margin: 0 !important;
}

.lp2-button {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border: 1px solid #004fff;
  color: #004fff;
  /* width: 200px; */
  margin-bottom: 10px;
  /* font-weight: bold; */
  font-size: 22px;
}

.lp2-button:hover {
  background: #260eff;
  background-image: linear-gradient(to top, #0d064d, #260eff);
  border-radius: 0;
  border: 1px solid #260eff;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.lp2-button-text {
  font-size: 16px;
}

.lp2-button-text a {
  margin-left: 5px;
  color: #ff6b00;
  font-weight: bold;
}

.lp2-image-column {
  padding: 0;
  margin: 0;
  justify-content: center;
  /* display: flex; */
}

.lp2-invite-modal {
  background: rgba(0, 0, 0, 0.2);
}

.lp2-invite-modal .modal-content {
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0;
}

.lp2-invite-header-row {
  font-size: 22px;
  color: white;
  justify-content: center;
}

.lp2-refer-a-friend-text {
  color: white;
  /* text-align: center; */
}

.lp2-report-info-button {
  color: rgba(255, 255, 255, 0.6);
  margin-left: 10px;
}

.lp2-report-info-button:hover {
  color: white;
  cursor: pointer;
}

.lp2-refer-a-friend-control-row {
  align-items: center;
}

.lp2-invite-form-control:not(:last-child) {
  margin-bottom: 5px;
}

.lp2-copy-link-control-row {
  align-items: center;
}

.lp2-copy-link-control-row:not(:last-child) {
  margin-bottom: 20px;
}

.lp2-refer-a-friend-form-control {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0;
  border: 1px solid #1588ee;
  color: white;
}

.lp2-refer-a-friend-form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.lp2-refer-a-friend-form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.lp2-refer-a-friend-form-control:focus {
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

.lp2-refer-a-friend-link-text {
  color: white;
  margin-bottom: 10px;
}

.lp2-refer-a-friend-button {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #1588ee;
  color: #1588ee;
  border-radius: 0;
}

.lp2-refer-a-friend-button:hover {
  background: #1588ee;
  border: 1px solid #1588ee;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.landing-page-background {
  background-color: #11076b;
  background-image: url(/static/media/header-background-image.740e3547.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.landing-page-intro {
  background: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50%;
}

.landing-page-content-container {
  padding: 32px;
  text-align: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.landing-page-header {
  color: #3d85ff;
  font-size: 36px;
  white-space: nowrap;
}

.landing-page-content-container p {
  color: white;
  font-size: 24px;
}

.landing-page-content-container a {
  color: #ff6b00 !important;
  word-wrap: normal;
  display: inline-block;
  padding: 0 10px;
}

.landing-page-second {
  background-color: #11076b;
  justify-content: center;
  display: flex;
  height: 50%;
  align-items: center;
}

.landing-page-image {
  width: 100%;
  max-width: 600px;
}

.landing-page-second-container {
  padding: 32px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: row;
}

.lp2-border-box-2 h4 {
  color: #3d85ff;
  text-align: center;
  font-size: 32px;
}

.lp2-border-box-2 p {
  color: white;
  text-align: center;
  font-size: 24px;
}

.admin-sidebar {
  background: #0D0D0D;
  width: 224px;
  color: white;
  position: relative !important;
  z-index: 4 !important;
  font-weight: normal !important;
}

.admin-sidebar .col {
  padding: 16px;
}

.admin-sidebar-bottom {
  position: absolute;
  bottom: 16px;
  align-items: center;
  width: 240px;
  justify-content: center;
  display: flex;
}

.admin-sidebar-bottom .admin-sidebar-logout-icon {
  margin-right: 6px;
  color: #858796;
}

.admin-sidebar-bottom:hover {
  cursor: pointer;
}

.admin-sidebar-bottom:hover .admin-sidebar-logout-icon {
  color: white;
}

.admin-logo {
  height: 60px;
}

.admin-logo-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 16px;
  justify-content: center;
  padding-bottom: 16px;
}

.system-header {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.65rem;
  font-weight: 800;
}

.admin-topbar {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  height: 4.375rem;
  padding: 8px 16px;
  z-index: 100;
  position: relative;
}

.admin-topbar-nav {

}

.admin-topbar-nav a {
  color: #858796;
  align-items: center;
  display: flex;
}

.admin-topbar-nav a:hover {
  color: black;
}

.admin-topbar-nav .dropdown-item {
  justify-content: space-between;
}

.admin-topbar-nav .dropdown-item:active {
  background: rgba(0, 0, 0, 0.1);
}

.admin-topbar-nav a svg {
  margin-right: 8px;
  font-size: 20px;
}

.admin-topbar-nav a::after {
  margin-left: 8px;
}

.admin-topbar-row {
  justify-content: space-between;
  align-items: center;
}

.admin-topbar .form-control {
  width: 300px;
}

.admin-search-container {
  display: flex;
  width:  50%;
  align-items: center;
  margin-left: 1rem;
}

.admin-list-group .active {
  /* background: rgba(255, 255, 255, 0.4) !important; */
}

.admin-list-accordion {
}

.admin-list-accordion button {
  background: transparent !important;
  color: white !important;
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  text-align: center;
}

.admin-list-accordion button:focus {
  outline: none !important;
}

.admin-list-accordion-collapse {
  border: none !important;
  background: rgba(255, 255, 255, 0.4) !important;
  border-radius: 3px;
  /* padding: 8px 0; */
  /* margin-top: 16px; */
  margin-bottom: 16px !important;
  position: relative !important;
  width: 100%;
  font-size: 14px !important;
  /* margin-top: 8px; */
}

.admin-list-accordion-collapse ul {
  list-style: none;
  padding: 0;
  margin: 0 8px;
}

.admin-list-accordion-collapse ul li {
  padding: 8px 16px;
}

.admin-list-accordion-collapse ul li:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.admin-list-item {
  font-size: 0.85rem;
  color: white;
  background: transparent !important;
  padding: 16px 0 !important;
  border: none !important;
}

.admin-list-item:hover {
  cursor: pointer;
}

.admin-list-item:hover .admin-list-item-icon {
  color: white;
}

.admin-list-item .row {
  align-items: center;
}

.admin-list-item-icon {
  color: rgba(255, 255, 255, 0.4);
  margin-right: 5px;
}

.admin-list-item-title {
  color: white;
  border: none;
}

.admin-list-item-title:hover {
  color: white;
  text-decoration: none;
}

.admin-sidebar-collapse-toggle-row {
  justify-content: center;
  margin-top: 16px;
}

.admin-sidebar-collapse-toggle-aura {
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.admin-sidebar-collapse-toggle-aura:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}

.admin-sidebar-bottom {
  position: absolute;
  bottom: 16px;
  align-items: center;
  width: 224px;
  justify-content: center;
  display: flex;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-title {
  width: 100%;
  word-break: break-all !important;
  /* text-overflow: clip; */
  /* text-overflow: ellipsis; */
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse.classify-onboarding {
  position: absolute !important;
  left: 100px;
  background: black !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  transform: translate(100px, 0px) !important;
  border: none;
}

.admin-sidebar#collapsed-sidebar .dropdown-toggle::after {
  display: none;
}


.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse ul li {
  padding: 8px 16px;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse.classify-onboarding ul li:hover {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.admin-sidebar#collapsed-sidebar {
  width: 6.5rem !important;
}

.admin-sidebar#collapsed-sidebar .admin-logo {
  height: 40px;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-title {
  font-size: 12px;
  text-align: center;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-title .row {
  justify-content: center;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion .row {
  justify-content: center;
  font-size: 12px;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-icon {
  font-size: 16px !important;
}

.admin-topbar-navdropdown {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 70px;
  width: 200px;
  border-top: 8px solid DarkTurquoise;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin-topbar-navdropdown a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #858796;
}

.admin-topbar-navdropdown a:hover {
  color: black;
}

.admin-topbar-navdropdown .dropdown-toggle::after {
  color: DarkTurquoise !important;
}

.admin-topbar-navdropdown .dropdown-menu {
  border: none;
  padding: 0;
  margin: 0;
  width: 200px;
  border-radius: 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin-topbar-navdropitem {
  height: 70px !important;
  width: 200px !important;
}

.admin-topbar-navdropitem {
  border-top: 8px solid #858796 !important;
}

.admin-topbar-navdropitem:first-child {
  border-top: 8px solid BlueViolet !important;
}

.admin-topbar {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  height: 4.375rem;
  padding: 8px 24px;
  z-index: 100;
  position: relative;
}

.admin-topbar-nav {
  font-size: 13px;
  position: absolute !important;
  top: 0 !important;
  right: 24px;
  height: 70px;
  padding: 0 12px;
}

.admin-topbar-nav a {
}

.admin-topbar-nav:hover {
  color: #b7b9cc !important;
}

.admin-topbar-nav .dropdown-toggle {
  padding: 0;
}

.admin-topbar-nav .dropdown-menu {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 14px;
}

.admin-topbar-nav .dropdown-item {
  color: black !important;
}

.admin-topbar-nav .dropdown-item svg {
  color: #d1d3e2;
  margin: 0;
  margin-right: 10px;
}

.admin-topbar-nav .dropdown-toggle::after {
  color: transparent;
}

.admin-topbar-nav a {
  color: #858796;
  align-items: center;
  display: flex;
  height: 100%;
}

.admin-topbar-nav a:hover {
  color: #b7b9cc;
}

.admin-topbar-nav .dropdown-item:active {
  background: rgba(0, 0, 0, 0.1);
}

.admin-topbar-nav a svg {
  margin-left: 8px;
  font-size: 14px;
}

.admin-topbar-nav a::after {
  margin-left: 8px;
}

.admin-topbar-row {
  justify-content: space-between;
  align-items: center;
}

#mentionedIn-true {
  color: LimeGreen;
}

#mentionedIn-false {
  color: red;
}

.mentionedIn-icon {
  font-size: 16px;
}

.mentionedIn-icon:hover {
  cursor: pointer;
}

.mentionedIn-icon#true {
  color: LimeGreen;
}

.mentionedIn-header {
  width: 100%;
  justify-content: center;
  margin-top: 12px;
  text-decoration: underline;
}

#button-tooltip .tooltip-inner {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  max-width: -webkit-max-content !important;
  max-width: -moz-max-content !important;
  max-width: max-content !important;
}

.right-justified-td {
  justify-content: flex-end;
}

.traded-symbols-card {
  padding: 8px !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.traded-symbols-card:hover {
  background: rgba(0, 0, 0, 0.05);
}

.traded-symbols-table-row {
  width: 100%;
}

.show-article-container {
  padding: 16px !important;
}

.show-article-title-row {
  margin-bottom: 8px;
}

.show-article-title-row h1 {
  margin: 0;
  font-size: 42px;
}

.show-article-summary-row {
  margin-bottom: 8px;
}

.show-article-summary-row h2 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 20px;
}

.show-article-user-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 8px;
}

.show-article-name-row {
  font-size: 14px;
}

.show-article-date-row {
  font-size: 12px;
}

.show-article-user-row {
  /* margin-bottom: 64px; */
  margin-bottom: 16px;
}

.preview-article-modal-header {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.new-article-container {
  color: #858796;
}

.show-article-image-row {
  justify-content: center;
  margin-bottom: 16px;
}

.show-article-image {
  max-height: 400px;
}

.create-report-loading-row {
  height: calc(100% - 70px);
  justify-content: center;
  align-items: center;
}

.create-report-spinner {
  color: #858796 !important;
  width: 100px;
  height: 100px;
}

.contained-width {
  width: 200px;
}

.center-aligned {
  align-items: center;
}

.website-config-label {
  color: #858796;
  margin: 0;
  margin-right: 16px;
}

html {
  scroll-behavior: smooth;
}

.app-content {
  font-family: 'Nunito', sans-serif;
  background: #F8F9FC;
  padding: 0 !important;
}

.app-container {
  padding: 0;
  height: 100%;
  min-height: 100%;
}

.app-row {
  height: 100%;
}

.content-pane {
  height: 100vh;
  /* overflow-y: scroll; */
  z-index: 50;
  position: relative;
}

.content-container {
  padding: 0;
}

.content-pane-container {
  height: 100%;
  padding: 0;
  background: #F8F9FC;
}

.render-loading {
  background: red;
  width: 100% !important;
}

.render-spinner {
  width: 100px;
  height: 100px;
  color: #858796 !important;
}

.test-row {
  justify-content: center;
  height: 100%;
  align-items: center;
}

.extra-small-button {
  font-size: 10px;
  height: 16px;
  padding: 2px 4px;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.teal-button {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
}

.teal-button:hover {
  background: #2c9faf !important;
  border: 1px solid #2a96a5 !important;
  color: black !important;
}

.delete-button {
  background-color: Tomato !important;
  border: 1px solid Tomato !important;
}

.delete-button:hover {
  background-color: FireBrick !important;
  border: 1px solid FireBrick !important;
  color: black !important;
}

#true-content {
  color: MediumSeaGreen !important;
}

#false-content {
  color: red !important;
}

.hidden {
  display: none;
}

.td-row {
  justify-content: space-between;
}

.td-row .individual-form-icon:not(:last-child) {
  margin-right: 4px;
}

.individual-form-icon {
  font-size: 16px;
  color: #858796;
}

.individual-form-icon:hover {
  color: black;
  cursor: pointer;
}

.individual-form-icon-disabled {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 8px;
}

.admin-container {
  padding: 0;
}

.admin-content-pane {
  height: 100vh;
  /* margin-top: -70px; */
  /* padding-top: 4.375rem; */
  overflow-y: scroll;
  z-index: 2;
  position: relative;
}

.admin-content-container {
  padding: 24px !important;
}

.return-to-header {
  align-items: center;
  display: flex;
}

.return-to-header:hover {
  cursor: pointer;
  color: black;
}

.admin-modal {
  color: #858796;
  background: rgba(0, 0, 0, 0.3);
  font-family: 'Nunito', sans-serif;
}

.admin-modal .modal-content {
  /* padding: 0 10px; */
  border: none;
  border-radius: 2px;
}

.confirm-delete-modal-row {
  align-items: center;
  justify-content: center;
  /* max-width: 1000px; */
}

.confirm-delete-modal-row button:not(:last-child) {
  margin-right: 8px;
}

.confirm-delete-modal-row:not(:last-child) {
  margin-bottom: 16px;
}

.template-edit-form {
  color: #858796;
}

.template-edit-form-group {
  align-items: center;
  justify-content: space-between;
  background: red;
}

.template-edit-div {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  background: orange;
}

.template-edit-form-label {
  /* position: absolute; */
  margin: 0;
  margin-right: 8px;
}

.template-edit-form-control {
  max-width: 400px;
}

.admin-edit-content-row {
  color: #858796;
  align-items: center;
}

.admin-edit-content-row:not(:last-child) {
  margin-bottom: 8px;
}

.admin-edit-content-col {
  margin-right: 8px;
}

.submission-form-control-label {
  color: #858796;
  margin: 0;
  width: 100%;
}

.admin-edit-content-section {
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  padding: 8px;
}

.admin-edit-individual-field {
  color: #858796;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
  padding: 4px;
}

.configure-button {
  margin-right: 8px;
}

.admin-edit-configure-modal-header {
  width: 100%;
  justify-content: flex-end;
}

.admin-edit-configure-modal-header button {
  margin-left: 8px;
}

.admin-edit-content-subsection {
  margin-bottom: 4px;
  align-items: center;
}

.no-margin {
  margin: 0 !important;
}

.ap-edit-row:not(:last-child) {
  margin-bottom: 8px;
}

.ap-edit-row .col:not(:last-child) {
  margin-right: 8px;
}

.ap-edit-image-container {
  position: relative;
  padding: 8px;
}

.ap-image {
  max-width: calc(100% - 8px);
}

.ap-image-cancel-icon {
  position: absolute;
  font-size: 16px;
  right: 0;
  color: #858796;
}

.ap-image-cancel-icon:hover {
  color: black;
  cursor: pointer;
}

.ap-edit-formfile-container {
  padding-bottom: 19px;
  padding-right: 8px;
  justify-content: center;
  width: 280px;
  height: 100%;
}

.ap-edit-formfile-interior {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 4px;
}

.ap-edit-formfile {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.common-typeahead input {
  color: #858796 !important;
  height: 31px;
  font-size: 14px;
}

.common-typeahead input:focus {
  color: #858796;
}

.common-typeahead .rbt-input-hint input {
  display: none;
}

.rbt-input-main::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3)!important;
}

.rbt-input-main::placeholder {
  color: rgba(0, 0, 0, 0.3)!important;
}

.common-typeahead .rbt-menu .dropdown-item {
  color: #858796 !important;
  font-size: 14px;
}

.ap-edit-content-section {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 8px;
  background: rgba(0, 0, 0, 0.1);
}

.ap-edit-form-info-row {
  margin-bottom: 8px;
}

.ap-edit-publish-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.ap-edit-tabs {
  display: flex;
}

.ap-edit-tab {
  color: #858796;
  padding: 2px 8px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 14px;
}

.ap-edit-tab:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.ap-edit-tab#current-tab {
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid transparent;
}

.ap-offering-details-row {
  margin-bottom: 12px;
}

.ap-formgroup {
  color: #858796;
}

.ap-edit-section-label {
  color: #858796;
  font-weight: bold;
  font-size: 16px;
}

.ap-edit-section-row {
  align-items: center;
  justify-content: space-between;
}

.ap-edit-required-info {
  font-style: italic;
  color: rgba(0, 0, 0, 0.3);
}

.common-date-picker {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-left: 6px;
  height: 31px;
  background: white;
}

.common-date-picker.invalid {
  border: 1px solid red;
}

.common-date-picker .react-date-picker__wrapper {
  border: none;
  border-radius: 2px;
}

.common-date-picker .react-date-picker__inputGroup input {
  color: #858796 !important;
}

.common-date-picker
  .react-date-picker__clear-button svg,
    .common-date-picker
        .react-date-picker__calendar-button svg {
  stroke: #858796 !important;
}

.common-date-picker
  .react-date-picker__clear-button svg:hover,
    .common-date-picker
        .react-date-picker__calendar-button svg:hover {
  stroke: black !important;
}

.common-date-picker .react-calendar {
  position: absolute;
  z-index: 100 !important;
}

.registration-display-image-container {
  width: 100%;
  position: relative;
}

.registration-display-image {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
}

.registration-display-image-cancel-icon {
  position: absolute;
  font-size: 16px;
  right: 0;
  color: #858796;
}

.registration-display-image-cancel-icon:hover {
  color: black;
  cursor: pointer;
}

.ap-subfield-row {
  margin-bottom: 8px;
}

.registration-form-file-control {
  width: 100%;
  position: relative;
  z-index: 0 !important;
}

.registration-form-file-control label {
  color: rgba(0, 0, 0, 0.3);
}

.ap-image-uploader input {
  height: 100% !important;
  background: red;
}

.justify-right {
  justify-content: flex-end !important;
}

