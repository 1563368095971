.landing-page-2-container {
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
  background-image: url('../../images/HomePage/header-background-image.png');
  height: 100%;
}

.lp2-header-jumbotron {
  height: 100px;
  border-radius: 0;
  background: transparent;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0;
}

.lp2-form-control-bar {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #1586eb;
  padding-right: 10px;
  width: 300px;
  padding: 0;
  margin: 0;
  border-radius: 2px;
}


.lp2-form-control-icon-container {
  width: 40px;
  height: 40px;
  justify-content: center;
  display: flex;
  font-size: 18px;
  color: white;
  align-items: center;
}

.lp2-form-control-icon-container:hover {
  background: rgb(0, 94, 255, 0.6);
  cursor: pointer;
}

.lp2-header-form-control {
  background: transparent;
  border: none;
  border-radius: 0px;
  color: white;
  width: 258px;
}

.lp2-header-form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);

}

.lp2-header-form-control:focus {
  background: transparent;
  color: white;
}

.lp2-intro-jumbotron {
  margin: 0;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 80vh;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: white;
  font-size: 24px;
}

.lp2-intro-jumbotron h4 {
  font-size: 36px;
  color: #3d85ff;
  /* font-weight: bold; */
  margin-bottom: 20px;
  letter-spacing: 1.5px;
  /* font-weight: bold; */
}

.lp2-intro-text-group {
}

.lp2-intro-jumbotron a {
  color: #ff6b00 !important;
}

.lp2-intro-jumbotron .row {
}

.lp2-border-box {
  /* background: rgba(0, 0, 0, 0.5); */
  align-items: center;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lp2-border-box-2 {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.lp2-intro-column {
  padding: 0;
  margin: 0;
  /* background: red; */
}

.lp2-intro-column h4 {
  color: #3d85ff;
  /* font-weight: bold; */
  margin-bottom: 20px;
  font-size: 32px;
  text-align: center;
}

.lp2-intro-column p {
  color: white;
  font-size: 24px;
  text-align: center;

}

.lp2-button-row {
  padding-top: 20px;
}

.lp2-button-row .col .row {
  justify-content: center;
  color: white;
}

.lp2-image {
  height: 350px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.lp2-second-jumbotron {
  /* background: transparent; */
  background-color: #0d064d;
  margin: 0 !important;
}

.lp2-button {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border: 1px solid #004fff;
  color: #004fff;
  /* width: 200px; */
  margin-bottom: 10px;
  /* font-weight: bold; */
  font-size: 22px;
}

.lp2-button:hover {
  background: #260eff;
  background-image: linear-gradient(to top, #0d064d, #260eff);
  border-radius: 0;
  border: 1px solid #260eff;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.lp2-button-text {
  font-size: 16px;
}

.lp2-button-text a {
  margin-left: 5px;
  color: #ff6b00;
  font-weight: bold;
}

.lp2-image-column {
  padding: 0;
  margin: 0;
  justify-content: center;
  /* display: flex; */
}

.lp2-invite-modal {
  background: rgba(0, 0, 0, 0.2);
}

.lp2-invite-modal .modal-content {
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0;
}

.lp2-invite-header-row {
  font-size: 22px;
  color: white;
  justify-content: center;
}

.lp2-refer-a-friend-text {
  color: white;
  /* text-align: center; */
}

.lp2-report-info-button {
  color: rgba(255, 255, 255, 0.6);
  margin-left: 10px;
}

.lp2-report-info-button:hover {
  color: white;
  cursor: pointer;
}

.lp2-refer-a-friend-control-row {
  align-items: center;
}

.lp2-invite-form-control:not(:last-child) {
  margin-bottom: 5px;
}

.lp2-copy-link-control-row {
  align-items: center;
}

.lp2-copy-link-control-row:not(:last-child) {
  margin-bottom: 20px;
}

.lp2-refer-a-friend-form-control {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0;
  border: 1px solid #1588ee;
  color: white;
}

.lp2-refer-a-friend-form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.lp2-refer-a-friend-form-control:focus {
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

.lp2-refer-a-friend-link-text {
  color: white;
  margin-bottom: 10px;
}

.lp2-refer-a-friend-button {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #1588ee;
  color: #1588ee;
  border-radius: 0;
}

.lp2-refer-a-friend-button:hover {
  background: #1588ee;
  border: 1px solid #1588ee;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.landing-page-background {
  background-color: #11076b;
  background-image: url('../../images/HomePage/header-background-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.landing-page-intro {
  background: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50%;
}

.landing-page-content-container {
  padding: 32px;
  text-align: center;
  height: max-content;
}

.landing-page-header {
  color: #3d85ff;
  font-size: 36px;
  white-space: nowrap;
}

.landing-page-content-container p {
  color: white;
  font-size: 24px;
}

.landing-page-content-container a {
  color: #ff6b00 !important;
  word-wrap: normal;
  display: inline-block;
  padding: 0 10px;
}

.landing-page-second {
  background-color: #11076b;
  justify-content: center;
  display: flex;
  height: 50%;
  align-items: center;
}

.landing-page-image {
  width: 100%;
  max-width: 600px;
}

.landing-page-second-container {
  padding: 32px;
  height: max-content;
  display: flex;
  flex-direction: row;
}

.lp2-border-box-2 h4 {
  color: #3d85ff;
  text-align: center;
  font-size: 32px;
}

.lp2-border-box-2 p {
  color: white;
  text-align: center;
  font-size: 24px;
}
