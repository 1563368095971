.logo-image {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.token-item {
  border-bottom: 2px solid #3e88c5;
  width: 100%;
}
.token-info {
  padding-top: 30px;
  padding-bottom: 30px;
}
.edit-token {
  font-size: 25px;
}
.remove-token {
  font-size: 25px;
}
.edit-token:hover {
  color: #3e88c5;
}
.remove-token:hover {
  color: red;
}
.token-image {
  width: 150px;
  height: 150px;
}
