.admin-contributor-container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.report-list-items-row {
  margin: 0;
  margin-top: 20px;
  padding-top: 60px;
  position: relative;
}

.report-list-items-col {
  width: 100%;
}

.report-list-research-row {
  margin: 0;
  height: 40px;
  align-items: center;
}

.report-list-research-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.report-list-status {
  font-size: 14px;
}

.view-full-report-button {
  text-align: center;
  background-color: #e8e4e4;
}

.contributor-list-section {
  width: 100%;
  display: block !important;
}

.contributor-list-top-row {
  margin: 0;
  padding-left: 40px;
  width: 100%;
  background: white;
  z-index: 2;
  position: fixed;
  border-bottom: 1px solid black;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2),
    0 6px 20px -20px rgba(0, 0, 0, 0.19);
}

.contributor-list-items-row {
  margin: 0;
  margin-top: 20px;
  padding-top: 60px;
  position: relative;
}

.contributor-list-items-col {
  width: 100%;
}

.contributor-list-research-row {
  margin: 0;
  height: 40px;
  align-items: center;
}

.contributor-list-research-row:nth-child(odd) {
  background: rgba(0, 0, 0, 0.2);
}

.contributor-list-status {
  font-size: 14px;
}

.admin-contributor-container .pagination-section {
  margin-top: 10px;
}
