.chart-tooltip {
    height: 75px;
    width: 150px;
}

#color-block {
    height: 20px;
    width: 20px;
    border: 1px solid;
}
