.admin-sidebar {
  background: #0D0D0D;
  width: 224px;
  color: white;
  position: relative !important;
  z-index: 4 !important;
  font-weight: normal !important;
}

.admin-sidebar .col {
  padding: 16px;
}

.admin-sidebar-bottom {
  position: absolute;
  bottom: 16px;
  align-items: center;
  width: 240px;
  justify-content: center;
  display: flex;
}

.admin-sidebar-bottom .admin-sidebar-logout-icon {
  margin-right: 6px;
  color: #858796;
}

.admin-sidebar-bottom:hover {
  cursor: pointer;
}

.admin-sidebar-bottom:hover .admin-sidebar-logout-icon {
  color: white;
}

.admin-logo {
  height: 60px;
}

.admin-logo-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 16px;
  justify-content: center;
  padding-bottom: 16px;
}

.system-header {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.65rem;
  font-weight: 800;
}

.admin-topbar {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  height: 4.375rem;
  padding: 8px 16px;
  z-index: 100;
  position: relative;
}

.admin-topbar-nav {

}

.admin-topbar-nav a {
  color: #858796;
  align-items: center;
  display: flex;
}

.admin-topbar-nav a:hover {
  color: black;
}

.admin-topbar-nav .dropdown-item {
  justify-content: space-between;
}

.admin-topbar-nav .dropdown-item:active {
  background: rgba(0, 0, 0, 0.1);
}

.admin-topbar-nav a svg {
  margin-right: 8px;
  font-size: 20px;
}

.admin-topbar-nav a::after {
  margin-left: 8px;
}

.admin-topbar-row {
  justify-content: space-between;
  align-items: center;
}

.admin-topbar .form-control {
  width: 300px;
}

.admin-search-container {
  display: flex;
  width:  50%;
  align-items: center;
  margin-left: 1rem;
}

.admin-list-group .active {
  /* background: rgba(255, 255, 255, 0.4) !important; */
}

.admin-list-accordion {
}

.admin-list-accordion button {
  background: transparent !important;
  color: white !important;
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  text-align: center;
}

.admin-list-accordion button:focus {
  outline: none !important;
}

.admin-list-accordion-collapse {
  border: none !important;
  background: rgba(255, 255, 255, 0.4) !important;
  border-radius: 3px;
  /* padding: 8px 0; */
  /* margin-top: 16px; */
  margin-bottom: 16px !important;
  position: relative !important;
  width: 100%;
  font-size: 14px !important;
  /* margin-top: 8px; */
}

.admin-list-accordion-collapse ul {
  list-style: none;
  padding: 0;
  margin: 0 8px;
}

.admin-list-accordion-collapse ul li {
  padding: 8px 16px;
}

.admin-list-accordion-collapse ul li:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.admin-list-item {
  font-size: 0.85rem;
  color: white;
  background: transparent !important;
  padding: 16px 0 !important;
  border: none !important;
}

.admin-list-item:hover {
  cursor: pointer;
}

.admin-list-item:hover .admin-list-item-icon {
  color: white;
}

.admin-list-item .row {
  align-items: center;
}

.admin-list-item-icon {
  color: rgba(255, 255, 255, 0.4);
  margin-right: 5px;
}

.admin-list-item-title {
  color: white;
  border: none;
}

.admin-list-item-title:hover {
  color: white;
  text-decoration: none;
}

.admin-sidebar-collapse-toggle-row {
  justify-content: center;
  margin-top: 16px;
}

.admin-sidebar-collapse-toggle-aura {
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.admin-sidebar-collapse-toggle-aura:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}

.admin-sidebar-bottom {
  position: absolute;
  bottom: 16px;
  align-items: center;
  width: 224px;
  justify-content: center;
  display: flex;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-title {
  width: 100%;
  word-break: break-all !important;
  /* text-overflow: clip; */
  /* text-overflow: ellipsis; */
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse.classify-onboarding {
  position: absolute !important;
  left: 100px;
  background: black !important;
  width: max-content;
  transform: translate(100px, 0px) !important;
  border: none;
}

.admin-sidebar#collapsed-sidebar .dropdown-toggle::after {
  display: none;
}


.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse ul li {
  padding: 8px 16px;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse.classify-onboarding ul li:hover {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.admin-sidebar#collapsed-sidebar {
  width: 6.5rem !important;
}

.admin-sidebar#collapsed-sidebar .admin-logo {
  height: 40px;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-title {
  font-size: 12px;
  text-align: center;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-title .row {
  justify-content: center;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion .row {
  justify-content: center;
  font-size: 12px;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-icon {
  font-size: 16px !important;
}

.admin-topbar-navdropdown {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 70px;
  width: 200px;
  border-top: 8px solid DarkTurquoise;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin-topbar-navdropdown a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #858796;
}

.admin-topbar-navdropdown a:hover {
  color: black;
}

.admin-topbar-navdropdown .dropdown-toggle::after {
  color: DarkTurquoise !important;
}

.admin-topbar-navdropdown .dropdown-menu {
  border: none;
  padding: 0;
  margin: 0;
  width: 200px;
  border-radius: 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin-topbar-navdropitem {
  height: 70px !important;
  width: 200px !important;
}

.admin-topbar-navdropitem {
  border-top: 8px solid #858796 !important;
}

.admin-topbar-navdropitem:first-child {
  border-top: 8px solid BlueViolet !important;
}

.admin-topbar {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  height: 4.375rem;
  padding: 8px 24px;
  z-index: 100;
  position: relative;
}

.admin-topbar-nav {
  font-size: 13px;
  position: absolute !important;
  top: 0 !important;
  right: 24px;
  height: 70px;
  padding: 0 12px;
}

.admin-topbar-nav a {
}

.admin-topbar-nav:hover {
  color: #b7b9cc !important;
}

.admin-topbar-nav .dropdown-toggle {
  padding: 0;
}

.admin-topbar-nav .dropdown-menu {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 14px;
}

.admin-topbar-nav .dropdown-item {
  color: black !important;
}

.admin-topbar-nav .dropdown-item svg {
  color: #d1d3e2;
  margin: 0;
  margin-right: 10px;
}

.admin-topbar-nav .dropdown-toggle::after {
  color: transparent;
}

.admin-topbar-nav a {
  color: #858796;
  align-items: center;
  display: flex;
  height: 100%;
}

.admin-topbar-nav a:hover {
  color: #b7b9cc;
}

.admin-topbar-nav .dropdown-item:active {
  background: rgba(0, 0, 0, 0.1);
}

.admin-topbar-nav a svg {
  margin-left: 8px;
  font-size: 14px;
}

.admin-topbar-nav a::after {
  margin-left: 8px;
}

.admin-topbar-row {
  justify-content: space-between;
  align-items: center;
}

#mentionedIn-true {
  color: LimeGreen;
}

#mentionedIn-false {
  color: red;
}

.mentionedIn-icon {
  font-size: 16px;
}

.mentionedIn-icon:hover {
  cursor: pointer;
}

.mentionedIn-icon#true {
  color: LimeGreen;
}

.mentionedIn-header {
  width: 100%;
  justify-content: center;
  margin-top: 12px;
  text-decoration: underline;
}

#button-tooltip .tooltip-inner {
  width: max-content !important;
  max-width: max-content !important;
}

.right-justified-td {
  justify-content: flex-end;
}
