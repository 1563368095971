.login-container {
  background-color: #f8f9fc;
  /* align-items: center; */
  height: 100vh !important;
}

.login-row {
  height: 100%;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  /* margin-top: 48px; */
}

.login-logo-row {
  background: #5a5c69;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  /* height: 90px; */
}

.login-logo {
  max-height: 8rem;
}

.login-logo-box {
  padding: 16px 0 16px 70px;
}

.register-box {
  background: white;
  height: 400px;
  width: 600px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 20px;
  position: relative;
}

.login-box {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border: none !important;
}

.login-box .row {
  height: 100%;
}

.login-content-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.login-content-col h4 {
  color: #858796;
  height: max-content;
}

.login-content-col button {
  margin-top: 20px;
}

.login-content-col .row {
  justify-content: space-around;
  height: max-content;
}

.login-navlinks {
  /* position: absolute; */
  width: 100%;
  margin-top: 32px;
  justify-content: space-between;
  display: flex;
}

.login-navlinks a {
  color: #4e73df;
}

.login-navlinks a:hover {
  color: #2e59d9;
}

.login-formcontrol-row {
  justify-content: space-between !important;
  margin-bottom: 10px;
  color: #858796;
  align-items: center;
}

.login-formcontrol-row .form-control {
  width: 70%;
}

.login-header {
  padding: 3rem !important;
  background: #5a5c69;
}

.login-box-padding {
  padding: 0 3rem 0 3rem;
}

.login-welcome .col {
  /* padding: 15px; */
  margin-bottom: 16px;
}

.login-box-col {
  padding: 15px !important;
}

.login-welcome h1 {
  font-weight: 400 !important;
  margin: 0;
}

.login-welcome p {
  margin: 0;
}

.login-formcontrol-box {
  width: 100%;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}

.login-formcontrol-text {
  margin: 11px 7px 0 15px;
  font-size: 13px;
}

.login-formcontrol {
  width: 100% !important;
  border: none !important;
  color: #858796 !important;
}

.login-formcontrol-container {
  margin-bottom: 16px;
}

.login-button-row {
  justify-content: flex-end !important;
}

.common-form-control {
  border: none !important;
  background: #f8f9fc !important;
  color: #858796 !important;
}

.common-form-control::placeholder {
  color: #858796 !important;
}

.common-button {
  background-color: #4e73df !important;
  border: 1px solid #4e73df !important;
}

.common-button:hover {
  background: #2e59d9 !important;
  border: 1px solid #2653d4 !important;
}
