/* .research-lookup-container {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}

.research-lookup-section {
  position: fixed;
  background: #cccccc;
  z-index: 2;
  width: 100%;
  display: block !important;
  top: 90px;
}

.research-lookup-form {
  height: 50px;
}

.research-lookup-form-row {
  height: 100%;
  width: 100%;
  margin: 0;
  align-items: center;
}

.research-lookup-label {
  margin: 0;
}

.research-list-section {
  width: 100%;
  display: block !important;
}

.research-list-top-row {
  margin: 0;
  padding-left: 40px;
  width: 100%;
  background: white;
  z-index: 2;
  position: fixed;
  border-bottom: 1px solid black;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2),
    0 6px 20px -20px rgba(0, 0, 0, 0.19);
}

.research-list-items-row {
  margin: 0;
  margin-top: 20px;
  padding-top: 60px;
  position: relative;
}

.research-list-items-col {
  width: 100%;
}

.research-list-research-row {
  margin: 0;
  height: 40px;
  align-items: center;
}

.research-list-research-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.view-full-report-button {
  text-align: center;
  background-color: #e8e4e4;
}
.research-list-username {
  font-weight: bold;
  font-size: 14px;
}

.research-list-status {
  font-size: 14px;
}

.research-list-username:hover {
  color: #0182e7;
  cursor: pointer;
  text-decoration: underline;
}

.research-list-selected-research-col {
  margin-left: 15px;
  margin-top: 40px;
}

.back-to-research-list-button {
  font-weight: bold;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.back-to-research-list-button:hover {
  color: #0182e7;
  cursor: pointer;
  text-decoration: underline;
}

.research-list-selected-research-info-row {
  height: 40px;
}

.research-list-selected-research-info-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.research-list-selected-research-info-row p {
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
}

.research-list-selected-research-info-row label {
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
}

.research-list-button-row {
  height: 100%;
  align-items: center;
  padding-left: 15px;
}

.research-list-toggle-button {
  height: 30px;
  margin-left: 10px;
  padding: 0;
  width: 60px;
} */

.submission-user-selector {
  padding: 0px 18px;
  justify-content: center;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin-bottom: 16px;
  color: #858796;
}

.submission-user-selector:not(:last-child) {
  margin-right: 8px;
}

.submission-user-selector:hover {
  background-color: #36b9cc;
  border: 1px solid #36b9cc;
  color: black;
  cursor: pointer;
}

.submission-user-selector.selected {
  background-color: #36b9cc;
  border: 1px solid #36b9cc;
  color: white;
}

.submission-user-selector.selected:hover {
  background: #2c9faf !important;
  border: 1px solid #2a96a5 !important;
  color: black !important;
}

.admin-create-report-container {
  padding: 0;
  background: white;
}

.create-report-sidebar-col {
  background: rgba(0, 0, 0, 0.1);
  padding: 8px !important;
}

.create-report-sidebar-row {
  margin-bottom: 16px;
}

.create-report-sidebar-label {
  font-size: 10px;
  color: #858796;
  margin: 0;
}

.create-report-form-col {
  padding: 8px !important;
  position: relative;
}

.create-report-topbar-container {
  position: absolute;
  right: 8px;
}

.create-report-button {
  margin-left: 8px;
  height: 20px;
  padding: 0 4px;
}

.admin-create-draft-container {
  justify-content: center;
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
}

.table-button {
  height: 20px;
  padding: 0 4px;
  font-size: 12px;
}

.reset-icon {
  position: absolute;
  right: 4px;
  top: 4px;
}

.no-users-found-row {
  padding-bottom: 16px;
  width: 100%;
  justify-content: center;
  color: #858796;
}

.asset-profile-loading-spinner {
  color: #858796 !important;
}
