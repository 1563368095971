.research-management-portal {
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  background: #F1F2F6;
  /* background-image: url('../../../images/HomePage/header-background-image.png');
  background-color: #11076B;
  background-size: cover; */
}

.research-management-portal .container {
  /* margin-top: 70px; */
  height: 100%;
  max-width: 70%;
  padding: 0;

}

.research-management-portal .header-row {
  width: 100%;
  justify-content: center;
  padding-top: 15px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #0182E7;
}

.research-management-portal .header-row h3 {
  color: black;
  font-size: 35px;
}

.research-management-portal .content-row {
  padding-bottom: 40px !important;
}

.research-management-portal .col {

}

.research-management-portal .row {
  padding: 0;
  margin: 0;
}

.research-management-portal .contributor-stats {
  background: transparent;
  height: 100%;
}

.research-management-portal .contributor-reports {
  background: transparent;
  height: 100%;
}

.contributor-stats {
  padding: 0 !important;
}

.contributor-reports .col {
  padding: 0;
}

.research-management-portal .contributor-stats .info-card, .content-row .info-card {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px 0px;
  padding: 30px;
  font-size: 14px;
  border-radius: 2px;
}

.research-management-portal .contributor-stats .info-card, .content-row .info-card-small {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px 0px;
  padding: 30px;
  font-size: 14px;
  border-radius: 2px;
  width: max-content;
}

.research-management-portal .contributor-stats .info-card, .content-row .info-card-small .total-followers {
  font-size: 24px;
}

.research-management-portal .contributor-stats .info-card .col {
  padding: 0;
}

.research-management-portal .contributor-reports .row {
  background: transparent;
  margin: 15px 0px;
}

.left-justified-column {

}

.right-justified-column {
  justify-content: flex-end !important;
  display: flex !important;
  padding: 0;
  margin: 0;
}

.research-management-portal .contributor-stats .info-card .category, .content-row .info-card .category {
  padding: 10px 5px;
}

.contributor-profile-right-side {
  justify-content: flex-end;
  display: flex;
}

.research-management-portal .contributor-stats .info-card .category, .content-row .info-card .category:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
}

.content-row .info-card .engagement-stats-button-row {
  margin: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.content-row .info-card .engagement-stats-button-row .engagement-stats-back-button {
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.2s;
}

.content-row .info-card .engagement-stats-button-row .engagement-stats-back-button:hover {
  cursor: pointer;
  color: #0182E7;
}

.payout-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  /* background: #F1F2F6; */
}

.payout-tabs a {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
  width: 25%;
  text-align: center;
  font-weight: bold;
}

.payout-tabs a:hover {
  border: 1px solid rgba(0, 0, 0, 0.11);
  color: #0182E7;
  border: 1px solid transparent;
}

.payout-tabs .active a:hover {
  border-bottom: none;
  color: rgba(0, 0, 0, 0.55);
  border: 1px solid rgba(0, 0, 0, 0.11);
  /* border: 1px solid transparent; */
}

.payout-tabs .active {
  color: rgba(0, 0, 0, 0.55);
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-bottom: none;
}

.payout-tabs .active:hover {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-bottom: none;
  /* border-bottom: 2px solid #0182E7; */
}

.rmp-payout-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  /* background: #F1F2F6; */
}

.rmp-payout-tabs a {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
  width: 25%;
  text-align: center;
  font-weight: bold;
}

.rmp-payout-tabs a:hover {
  border: 1px solid rgba(0, 0, 0, 0.11);
  color: #0182E7;
  border: 1px solid transparent !important;
}

.rmp-payout-tabs .active a:hover {
  border-bottom: none;
  color: rgba(0, 0, 0, 0.55);
  border: 1px solid rgba(0, 0, 0, 0.11);
  background: transparent;
  /* border: 1px solid transparent; */
}

.rmp-payout-tabs .active {
  color: rgba(0, 0, 0, 0.55);
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-bottom: none;
  background: transparent !important;
  border: none !important;
}

.rmp-payout-tabs .active:hover {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-bottom: none;
  /* border-bottom: 2px solid #0182E7; */
  border: none !important;
}

.research-management-portal .contributor-stats .info-card .title {
  font-weight: bold;
}

.research-management-portal .contributor-stats .info-card h4 {
  font-weight: bold;
  font-size: 24px;
}

.research-management-portal .contributor-reports {
  padding: 0;
}

.research-management-portal .contributor-reports form {
  width: 100% !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.research-management-portal .contributor-reports form .search-bar {
  max-width: 100%;
}

.contributor-reports .row {
}

.research-management-portal .contributor-reports .tabs-col {
  padding: 0;
  border-radius: 2px;
}

.contributor-reports .nav-tabs {
  background: #F1F2F6;
  border: none !important;
  /* border-radius: 5px; */
  border: none;
}

.contributor-reports .nav-tabs .active {
  border: none;
}

.contributor-reports-search-bar-row .input-group {
  width: 100%;
  max-width: 100%;
}

.contributor-search-bar {
  border: 1px solid #0182E7;
}

.contributor-search-button {
  background: white;
  color: #0182E7;
}

.contributor-search-button:hover {
  background: #0182E7;
  border: 1px solid #0182E7;
}

.contributor-report-tabs {
  margin-top: 10px;
}

.research-management-portal .contributor-reports .tabs-col .row {
  padding: 0px 16px;
}

/* .research-management-portal .contributor-reports .tabs-col #icon {
  padding: 0 !important;
  justify-content: center !important;
  background: red;
} */

.individual-report {
}

.rendered-individual-report-top-row,
  .rendered-individual-report-col,
    .rendered-individual-report-col .report-title-info-button,
      .rendered-individual-report-col .report-title-col,
        .rendered-individual-report-col .report-info-button-row,
          .report-date {
  margin: 0;
  padding: 0;
}

.rendered-individual-report-col:hover {
  /* cursor: pointer; */
}

.rendered-individual-report-top-row {
  padding: 5px 5px !important;
  border: 1px solid transparent;
  z-index: -1;
}

.rendered-individual-report-top-row:hover {
  /* background: rgba(0, 0, 0, 0.1);
  cursor: pointer; */
}

#active.rendered-individual-report-top-row {
  /* border: 1px solid #0182E7; */
  background: rgba(0, 0, 0, 0.1);
}

#active.rendered-individual-report-top-row
  .report-title-info-button a {
    /* color: #0182E7 !important; */
  }

#active.rendered-individual-report-top-row
  .rendered-individual-report-col
    .report-info-button-row
      .report-info-button {
  /* color: #0182E7; */
}

.rendered-individual-report-top-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  /* margin-bottom: 10px !important; */
}

.rendered-individual-report-col .report-info-button-row {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.report-info-button {
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.2s;
  font-size: 18px;
  z-index: 2;
}

.report-info-button:not(:last-child) {
  margin-left: 10px;
}

.rendered-individual-report-col .report-info-button:not(:last-child) {
  margin-right: 10px;
}

.report-info-button:hover {
  color: #0182E7;
  cursor: pointer;
}

.report-date {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.research-management-portal .contributor-reports .tabs-col .report-content .row
  .research-management-portal .contributor-reports .tabs-col .report-icon-col {
  padding: 0 !important;
  margin: 0 !important;
}

.report-content .date {
  padding: 0;
  margin: 0;
}

.research-management-portal .contributor-reports .tabs-col .report-icon-col svg {
  color: #0182E7;
}

.report-content .info {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
}

.report-content .title {
  font-size: 14px;
}

.date {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  background: red;
}

.individual-report {
  padding: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  padding: 20px 0px !important;
  margin: 0 !important;
}

.report-content .brief {
  font-size: 14px;
}

.research-management-portal .contributor-reports .tabs-col #icon {
  justify-content: center;
  display: flex;
  align-items: center;
}

.tabs-col  {
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 5px;
}

.tabs-col .nav-tabs a {
  width: 50%;
  text-align: center;
  font-weight: bold;
}

.contributor-report-row {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.info-card-disabled {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px 0px;
  padding: 30px;
  font-size: 14px;
  border-radius: 2px;
}

.coming-soon {
  justify-content: center;
  font-weight: bold;
  align-items: center;
  height: 200px;
}

.coming-soon-bubble {
  background: white;
  width: 200px;
  justify-content: center;
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.date-picker-row {
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 0 0 0 4px !important;
  border-radius: 2px;

}

.date-picker-row .col {
  align-items: center;
}

.research-management-portal-container {
  transition: filter 0.5s;
  width: 100vw !important;
  max-width: 100vw !important;
  /* padding: 0 40px 0 0 !important; */
}

.rmp-container-row {
  height: 100%;
  /* padding-bottom: 90px !important; */
}

body.modal-open .research-management-portal-container {
  filter: blur(3px);
}

.profile-form-modal {
  background: rgba(0, 0, 0, 0.3);
}

.profile-form-modal .modal-body {
  /* min-height: 400px; */
}

.profile-form-modal .modal-dialog {
  min-width: 50%;
  /* background: #F1F2F6; */
}

.profile-form-modal .modal-header, .profile-form-modal .modal-footer {
  border: none;
}

.profile-form-modal .modal-title {
  width: 100%;
  text-align: center;
}

.profile-form-modal .modal-content {
  background: #F1F2F6;
}

.profile-form-modal .image-uploader {
  /* margin-left: 0px;
  max-height: 100%;
  border: none; */
  border: none;
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-uploader-row {
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-uploader-row .avatar-container {
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-uploader-row .avatar-container div {
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-uploader-row .avatar-container div div {
  max-height: 200px;
  max-width: 200px;
  margin: 0;
  padding: 0;
  text-align: center;
  justify-content: center;
}

.profile-form-modal .image-uploader-row .avatar-container div div label {
  margin: 0;
  padding: 0;
  max-height: 200px;
  max-width: 200px;
  justify-content: center;
  align-items: center;
  display: flex !important;
}

.profile-form-modal .image-uploader-row .avatar-container div div div div canvas {
  margin: 0;
  padding: 0;
  max-height: 200px;
  max-width: 200px;
}

.profile-form-modal .image-uploader-container {
  margin: 0;
  padding: 0;
}

.profile-form-modal .uploaded-image {
  object-fit: cover;
  max-width: 200px;
  max-height: 200px;
  margin: 0;
  padding: 0;
}

.profile-form-modal .image-overlay {
  max-width: 200px;
  max-height: 200px;
  margin: 0;
  padding: 0;
}

.account-image-spinner-div {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.account-image-spinner {

}

.profile-form-content {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
  font-size: 14px;
  margin: 0;
  padding: 0;
  padding: 10px;
  margin:  0 15px;
  /* padding-right: 20px; */
}

.profile-form-content .row .small-text {
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  font-size: 12px;
}

.profile-form-content .form-label {
  margin: 0;
  padding: 0;
}



.profile-form-content .contributor-application-form-control:not(:last-child) {
  margin-bottom: 10px;
}

.contributor-application-form-control .form-control {
  margin-bottom: 10px !important;
}

.contributor-application-form-control {
  transition: border 0.15s, border-radius 0.15s, background 0.15s, box-shadow 0.15s;
  border: none;
  border-bottom: 1px solid darkgrey;
  border-radius: 0;
  color: black;
}


.contributor-application-form-control:focus {
  /* background: transparent; */
  /* color: white; */
}

.contributor-application-form-control option {
  /* background-color: #0A2FB4; */
}

.research-application-form:focus .option {
  color: black;
}

.profile-form-content .form-label {
  /* margin-top: 15px !important;
  margin-bottom: 0; */
}

.pencil-div {
  justify-content: flex-end;
  display: flex;
  padding: 0;
}

.pencil-div .edit-account-button {
  transition: color 0.2s, background 0.2s !important;
  /* border: 1px solid #0182E7; */
  color: rgba(0, 0, 0, 0.3);
  border: none;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.pencil-div .edit-account-button:hover {
  cursor: pointer;
  /* background: #0182E7; */
  color: #0182E7;
  border-radius: 2px;
}

.pencil-div .btn-primary {
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
  width: 60px;
  margin-right: 10px;
  border-radius: 2px;
  background: white;
  color: #0182E7;
  border: 1px solid #0182E7;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.pencil-div .btn-primary:hover {
  color: white;
  background: #0182E7;
}

.contributor-profile-edit-form-control {
  font-size: 14px;
  height: 21px;
  width: 100% !important;
  max-width: 100% !important;
}

.missing-reports {
  text-align: center;
  font-size: 14px;
  width: 100%;
  margin-top: 30px;
}

.drafts-link-row {
  text-align: center;
  justify-content: center;
  padding: 10px 0 !important;
}

.drafts-manager-link {
  font-size: 12px;
  text-decoration: underline;
}

.report-stats-window {

}

.selected-report-title {
  margin-left: 15px !important;
  margin-bottom: 15px !important;
  font-size: 32px;
}

#engagement-stats h4 {
  font-size: 20px;
}

.selected-report-unpublish-row {
  margin: 0 15px !important;
  margin-top: 15px !important;
  display: flex;
  justify-content: flex-end;
}

.unpublish-modal .modal-content {
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.unpublish-modal-text-row,
  .unpublish-modal-button-row {
  display: flex;
  justify-content: center;
}

.unpublish-modal-text-row {
  margin-bottom: 10px;
}

.unpublish-button {
  color: red;
  border: 1px solid red;
  border-radius: 2px;
  padding: 0;
  padding: 0 2px;
  font-size: 12px;
  background: transparent;
}

.unpublish-button:hover {
  background: red;
  border: 1px solid red;
  border-radius: 2px;
  color: white;
}

.unpublish-modal-cancel-button {
  background: white;
  color: #0182E7;
  border: 1px solid #0182E7;
  border-radius: 2px;
  margin-left: 10px;
  width: 100px;
}

.unpublish-modal-cancel-button:hover {
  background: #0182E7;
  border: 1px solid #0182E7;
  border-radius: 2px;
}

.selected-report-unpublish-row button,
  .unpublish-modal-unpublish-button {
  color: red;
  border: 1px solid red;
  border-radius: 2px;
  width: 100px;
  background: transparent;
}

.selected-report-unpublish-row button:hover,
  .unpublish-modal-unpublish-button:hover {
  background: red;
  border: 1px solid red;
  border-radius: 2px;
  color: white;
}

.copy-link-control-row {
  align-items: center;
  /* margin-top: 30px !important; */
  margin-bottom: 10px !important;
}

.copy-link-form-control {
  /* width: 80%; */
  /* margin-right: 10px; */
  font-size: 14px;
}

.clipboard-col {
  justify-content: center;
  display: flex;
}

.copy-link-modal {
  padding-top: 20%;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  border: none !important;
}

.copy-link-modal .modal-content {
  border: none;
}

.rmp-sidebar-column {
  background: rgba(0, 0, 0, 0.2);
  justify-content: flex-start;
  /* padding-top: 3px !important; */
}

.rmp-sidebar-listgroup {
  width: 100%;
}

.rmp-sidebar-listgroup .list-group-item {
  background: transparent;
  /* border: none; */
  border: 1px solid transparent;
  border-top: 1px solid slategrey;
  border-bottom: 1px solid slategrey !important;
  /* padding: 5px; */
}

.rmp-sidebar-listgroup .list-group-item{
  /* display: none !important; */
}

#disabledttt {
  background: pink;
}

.rmp-sidebar-listgroup .list-group-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: white;
  cursor: pointer;
}

.rmp-sidebar-listgroup .active {
  background: rgb(1, 130, 231, 0.5);
  color: white;
  border-top: 1px solid #0182E7;
  border-bottom: 1px solid #0182E7 !important;
}


.rmp-sidebar-listgroup .active:hover {
  background: rgba(0, 0, 0, 0.2);
}
.rmp-sidebar-listgroup .active:hover {
  background: rgb(1, 130, 231, 0.5);
}

.rmp-tab-content {
  width: 100%;
}

.responsive-line-graph {

}

.rmp-line-graph {
  width: 100%;
  /* max-height: 50%;
  height: 50%;
  max-height: 450px;
  height: 450px; */
  max-height: 50vh;
  height: 50vh;
}

.rmp-line-graph h4 {
}

.graph-buttons {
  justify-content: center;
  display: flex;
}

.graph-buttons input[type='radio'] {
}

.graph-buttons label {
}

.engagement-column .tabs-col {
  border: none;
  border-radius: 0;
  margin-top: 10px;
  width: 100% !important;
}

.engagement-column .tabs-col .published-tab {
}

.time-frame-button {
  background: white;
  color: #0182E7;
  border: 1px solid #0182E7;
  font-size: 10px;
  /* padding: 0; */
  height: 20px;
  padding: 0 2px;

}

.time-frame-button:hover {
  background: #0182E7;
  border: 1px solid #0182E7;
}

#active.time-frame-button {
  background: #0182E7;
  color: white;
}

.time-frame-button:not(:last-child) {
  margin-right: 5px;
}

.engagement-stats-header-row {
  align-items: center;
  margin-bottom: 10px !important;
}

.total-engagement-stats {
  color: rgba(0, 0, 0, 0.5);
  background: white;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 20px;
  padding: 0 8px;
  border-radius: 2px;
}

.rmp-line-graph

.rmp-line-graph-header-row .col h4 {
  margin: 0;
}

.rmp-line-graph-button-row {
  align-items: center;
}

.total-engagement-stats:hover {
  color: white;
  background: #0182E7;
  border: 1px solid #0182E7;
}

#hidden.total-engagement-stats {
  display: none;
}

.total-engagement-header-row .col h4 {
  margin: 0;
}

.engagement-stats-header-col h4 {
  margin: 0;
}

.rmp-token-data-month {
}

.rmp-graph-tooltip {
  width: 300px;
  background: white;
  height: 20px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  margin: 10px;
  padding: 20px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.rmp-graph-tooltip .row {
  width: 100%;
}

.rmp-refer-a-friend-button, .save-image-button {
  background: transparent;
  border: 1px solid #0182E7;
  color: #0182E7;
  padding: 10px 30px;
  border-radius: 2px;
}

.rmp-refer-a-friend-button:hover, .save-image-button:hover {
  color: white;
  border: 1px solid #0182E7;
  background: #0182E7;
}

.rmp-refer-a-friend-row {
  padding-top: 20px !important;
}

.avatar-editor {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.rmp-engagement-stats {
  padding: 0 !important;
  padding: 5px !important;
  justify-content: center;
  /* display: flex; */
  font-size: 12px !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.engagement-text {
  justify-content: center;
}

.engagement-stat {
  justify-content: center;
  font-size: 22px;
  min-height: 33px;
}

.rmp-engagement-stats:not(:last-child) {
  margin-right: 8px !important;
}

.rmp-all-reports-button-row {
  font-weight: bold;
  height: 50px;
  align-items: center;
}

.rmp-all-reports-button {
  color: rgba(0, 0, 0, 0.5);
  background: white;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 20px;
  padding: 0 8px;
  border-radius: 2px;
}

.rmp-all-reports-button:hover {
  color: white;
  border: 1px solid #0182E7;
  background: #0182E7;
}

.rmp-all-reports-text {
  margin: 0 !important;
}

.line-graph-header-text {
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.55);
  min-height: 24px;
}

.rmp-refer-a-friend-link-text {
  margin: 30px 0 !important;
}

.rmp-invite-header-row {
  font-size: 20px;
  margin-bottom: 15px !important;
}

.spinner-row {
  justify-content: center;
  display: flex;
  margin-top: 100px !important;
}

.bolded-text {
  font-weight: bold;
  margin-top: 10px !important;
}

#active-token-month {
background: rgba(0, 0, 0, 0.1);
/* border: 1px solid #0182E7; */
}

.rmp-token-month {
  border: 1px solid transparent;
  /* padding: 0 5px !important; */
}

.rmp-token-month:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

.temp-token-month {
  align-items: center;
  margin-bottom: 10px !important;
}

.rmp-no-tokens-earned {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.rmp-pie-chart-tooltip {
  /* height: 80px; */
  width: 160px;
  font-size: 12px;
}

.rmp-date-picker-text {
  color: rgba(0, 0, 0, 0.55);
  display: inline-block;
  font-weight: bold;
  padding: 0 !important;
  margin: 0;
}

.rmp-token-data-total-row {
  align-items: center;
}

.rmp-token-data-icon {
  margin-left: 2px;
  color: rgba(0, 0, 0, 0.3);
}

.rmp-pie-chart-info-icon {
}

.rmp-pie-chart-info-icon:hover {
  cursor: pointer;
  color: #0182E7;
}

.rmp-user-profile-banner-row {
  background: red;
}

.rmp-user-profile-banner-image {
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  /* position: absolute; */
}

.rmp-image-uploader .fileContainer {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: 0;
  height: 300px;
  width: 300px;
}

.rmp-uploaded-image {
  height: 300px;
  width: 300px;
  object-fit: cover;
  /* margin-top: 15px; */
  background: rgba(0, 0, 0, 0.3);
}

.rmp-banner-row {
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 240px;
  width: 100%;
}

.rmp-banner-edit-icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.rmp-banner-edit-icon {
  background: white;
  border-radius: 100px;
  padding: 2px;
  width: 20px;
  height: 20px;
  color: #0182E7;
}

.rmp-banner-edit-icon:hover {
  background: #0182E7;
  color: white;
  cursor: pointer;
}

.rmp-banner-image {
  /* height: 240px; */
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
}

.rmp-banner-uploader .fileContainer {
  background: transparent;
  height: 240px;
  width: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.rmp-test-row {
  width: 100%;
}

.rmp-create-asset-profile-section .row {

}

.rmp-create-asset-profile-header {
  border-bottom: 1px solid black;
  margin-bottom: 20px;

}

.rmp-create-asset-profile-header .col {
  justify-content: center;
  display: flex;
}

.rmp-individual-profile {
  padding: 10px !important;
}

.rmp-individual-profile:nth-child(even) {
  background: rgba(0, 0, 0, 0.1);
}

.rmp-individual-profile-icon {
  margin-right: 5px;
}

.rmp-individual-profile-icon:hover {
  cursor: pointer;
  color: #0182E7;
}

.rmp-individual-profile-icon-delete:hover {
  cursor: pointer;
  color: fireBrick;
}

.rmp-individual-profile .col {
  justify-content: center;
  display: flex;
}

.rmp-individual-profile-icon-row {
  align-items: center;
}

.rmp-return-to-asset-profile {
  align-items: center;
  margin-bottom: 20px;
}

.rmp-return-to-asset-profile:hover {
  cursor: pointer;
  color: #0182E7;
  text-decoration: underline;
}

.rmp-ap-form-name-row {
  margin-bottom: 20px !important;
}

.rmp-ap-form-control {
  /* width: 100%;
  max-width: 50%; */
}

.rmp-ap-image-uploader {
  /* width: 100%;
  max-width: 50%; */
}

.rmp-ap-typeahead {
  /* width: 100%;
  max-width: 50%; */
}

.rmp-edit-this-container {
  width: 50%;
}

.rmp-ap-form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.rmp-asset-profile-overview-tab {
  padding: 20px;
  justify-content: center;
}

.rmp-asset-profile-overview-tab h3 {
  color: black;
  font-size: 22px;
}

.rmp-individual-asset-profile-editor {
  align-items: center;
  /* background: red; */
  width: 300px;
  height: 62px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 10px !important;
  justify-content: space-between;
}

.rmp-individual-asset-profile-editor:not(:last-child) {
  margin-right: 10px;
}

.rmp-individual-asset-profile-editor img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.rmp-editors-container {
  margin-bottom: 20px;
}

.rmp-editors-searchbar-container {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  min-height: 369px;
}

.rmp-editors-searchbar {
  width: 300px;
  margin-bottom: 20px;
}

.rmp-editors-searchbar::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.rmp-editors-profile-analytics-container {
  padding-top: 20px;
}

.rmp-individual-search-editor {
  align-items: center;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 10px !important;
  justify-content: space-between;
  display: flex;
}

.rmp-individual-search-editor:not(:last-child) {
  margin-bottom: 5px;
}

.rmp-edit-this-profile-tab {
  padding: 20px;
}

.no-asset-profiles-listed {
  text-align: center;
  padding: 20px;
}

.rmp-submit-for-verification-button {
  /* font-size: 10px; */
  /* padding: 0; */
}

.dashboard-account-info-card {
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  color: #858796;
}

.dashboard-header-row {
  font-size: 16px;
  margin-bottom: 16px;
}

.dashboard-contents-row {
  justify-content: space-between;
  margin-bottom: 16px;
}

.dashboard-contents-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.rmp-dashboard-row {
  margin-top: 16px;
}

.dashboard-account-info-card {

}
