.report-title-author-section {
  padding: 30px;
  padding-top: 45px;
  margin: 0px;
  text-align: center;
}
.report-title-section {
  font-weight: bold;
  width: 100%;
}
.report-title-section h4 {
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.report-title-section p {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
  text-align: center;
}
.report-author-section {
  font-weight: bold;
  width: 100%;
}
.report-author-section p {
  margin: 0px;
  text-align: right;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
  font-size: 12px;
}
.report-author-section-image {
  border-radius: 2rem;
  width: 50px;
  height: 50px;
  margin-left: -20px;
  border: 1px solid gray;
}

.report-section-article-image {
  width: 100%;
  height: 300px;
  border: 1px solid gray;
}

.report-section-article-body {
  padding-top: 50px;
  text-align: left;
}
.report-category-section {
  margin-top: 55px;
  font-weight: bold;
  width: 100%;
}
.report-category-section li {
  margin: 0px;
  text-align: left;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
  font-size: 12px;
}
.research-report-unpublish {
  margin-top: 42px;
}
