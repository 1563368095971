.search-assets-top-container {
  background: #F8F9FC;
  min-height: 100vh;
}

.search-assets-form-control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #858796 !important;
}

.search-assets-form-control::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.search-assets-table-row {
  align-items: center;
  width: max-content;
}

.search-assets-table-name {
  font-size: 12px;
}

.search-assets-table-cell {
  width: max-content;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.search-assets-table-cell.description {
  font-size: 12px;
}

.search-assets-tile-row {
  /* justify-content: center; */
}

.search-assets-tile {
  width: 300px;
  height: 400px;
  padding: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  /* margin-left: 8px; */
}

.search-assets-tile img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.search-assets-tile .header-content {
  height: 100px;
  margin-bottom: 16px;
}

.search-assets-tile .name-location-container {
  width: 60%;
  margin-left: 8px;
}

.search-assets-tile .name {
  width: 100%;
  font-size: 18px;
  color: black;
}

.search-assets-tile .location {
  -ms-align-items: center;
  align-items: center;
  display: flex;
}

.search-assets-tile .name:hover {
  text-decoration: underline;
  cursor: pointer;
}
