.not-found-container {
  /* min-height: 100vh; */
  height: 100%;
  background-color: #F8F9FC;
  padding: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.not-found-container.admin-type {
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.not-found-container-card {
  background: white;
  min-height: 300px;
  padding: 16px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.not-found-image-row {
  justify-content: center;
}

.not-found-image {
  height: 100px;
}

.not-found-header-row {
  justify-content: center;
  font-size: 40px;
  color: #858796;
  margin: 32px;
  margin-bottom: 0;
}

.not-found-content-row {
  justify-content: center;
  color: #858796 !important;
  font-size: 20px;
  margin: 32px;
  margin-bottom: 0;
}

.not-found-links-row {
  justify-content: center;
  color: #858796 !important;
  margin: 32px;
  margin-bottom: 0;
}

.not-found-link {
  margin: 0 4px;
}
