.contact-us-col {
  background-image: linear-gradient(to top, rgba(33, 122, 255, 0.85), rgb(10, 47, 180, 0.9));
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 40px;
}

.contact-us-jumbotron {
  height: 100%;
  background-image: url('../../images/HomePage/header-background-image.png');
  background-color: #11076B;
  background-size: cover;
  margin-top: 90px;
  padding-bottom: 90px;
}

.contact-us-jumbotron .header-text-row {
  margin-bottom: 30px;
}

.contact-us-jumbotron .header-text {
  color: white;
  text-align: center;
  margin-top: 60px;
}

.contact-us-submit-row {
  justify-content: center;
}

.contact-us-form .research-application-form-control {
  min-height: 90px;
}

.contact-us-received-row {
  justify-content: center;
  color: white;
}
