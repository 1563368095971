.edit-profile-area {
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
    margin-top: 60px;
}

.save-image {
    margin-top: 20px;
    width: 250px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.uploaded-image {
    width: 300px;
    height: 300px;
    margin-top: 15px;
    border-radius: 2px;
    display: block;
}

.avatar-container div div {
    border-radius: 5px;
    background-size: cover;
}

.konvajs-content {
    width: 300px !important;
    height: 300px !important;
    background-size: cover;
}

.konvajs-content canvas {
    border-radius: 5px !important;
    background-size: cover;
}

.account-info {
    margin-top: 3rem;
}

.user-credential-info {
    width: 500px;
    height: 350px;
    margin-left: 2rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form {
    width: 80%;
}

.contact-form p {
    font-weight: bold;
}

.one-paragraph {
    border: 1px solid silver;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paragraph {
    background-color: silver;
}

.go-to-admin-page {
    margin-left: 2rem;
    font-weight: bold;
    cursor: pointer;
}

.my-account-info-column {
    margin-top: 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: white;
}

.my-account-info-top {
    /* border: 1px solid; */
    padding: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.my-account-info-inner {
    /* border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid; */
    padding: 22px;
}

.my-account-info-inner:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

/* .search-space-section-news {
    width: 100%;
    height: 90px;
    border-radius: 0;
    background-color: rgb(2, 3, 54);
    background-image: url("../../images/HomePage/header-background-image.png");
    position: fixed;
    z-index: 100;
} */

.account-form {
    width: 100%;
    display: inline-block;
}

.edit-account-button {
    border: 1px solid;
    border-radius: 2px;
    width: 25px;
    height: 25px;
    padding: 2px;
    color: royalblue;
    box-shadow: 19px;
}

.admin-link-row {
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 30px;
    /* background: red; */
}

.admin-link {
    margin-right: 3rem;
}

.admin-link:hover {
    text-decoration: underline;
}

.refer-a-friend-row {
  margin-bottom: 30px;
  justify-content: center;
  width: 100%;
}

.refer-a-friend-button {
  background: transparent;
  border: 1px solid royalblue;
  color: royalblue;
  padding: 10px 30px;
}

.refer-a-friend-button:hover {
  background: royalblue;
  color: white;
  border: 1px solid royalblue;
}

.settings-body-section-bottom {
  height: 100%;
  background: rgb(178,195,195);
  background: linear-gradient(0deg, rgba(178,195,195,1) 0%, rgba(255,255,255,1) 100%);
}

#account-image {
  border: none;
}

.center-justified-row {
  justify-content: center;
}

#orange {
  background: orange;
}

#purple {
  background: purple;
}

.refer-a-friend-modal {
  padding-top: 10%;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  border: none !important;
}

.refer-a-friend-modal .modal-content {
  border: none;
}

.refer-a-friend-text-p {
  margin-bottom: 30px;
}

.refer-a-friend-control-row {
  align-items: center;
  margin-bottom: 5px !important;
}

.refer-a-friend-modal-bottom-row {
  margin-top: 20px !important;
}

.refer-a-friend-form-control::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.account-settings-container {
  width: 100vw;
  max-width: 100vw;
}

body.modal-open .account-settings-container {
  filter: blur(3px);
}
