.news-container {
  padding: 0;
}

.news-menu-section {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  padding: 8px 16px;
  width: 100%;
}

.news-feed-section {
  padding: 8px 16px;
  margin-left: 400px;
}

.news-feed-loading {
  width: 100%;
  height: 300px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #858796 !important;
}

.news-search-row {
  width: 100%;
  height: max-content;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.news-search-control-container {
  display: flex;
  width: 100%;
  max-width: 900px;
  justify-content: space-between !important;
}

.news-search-datepicker-container {
  width: max-content;
  display: flex;
}

.news-search-datepicker-container .news-search-form-control:not(:last-child) {
  margin-right: 8px;
}

.news-search-form-control {
  width: 200px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.13);
  color: #858796 !important;
  padding-left: 8px;
  font-size: 14px;
  border-radius: 3px;
}

.news-search-form-control .react-date-picker {
  color: #858796 !important;
}

.news-search-form-control .react-date-picker .react-date-picker__button svg {
  stroke: #858796 !important;
}

.news-search-form-control .react-date-picker .react-date-picker__button svg:hover {
  stroke: black !important;
}

.news-search-form-control .react-date-picker__wrapper {
  border: none;
}

.news-search-form-control .react-date-picker__wrapper .react-date-picker__clear-button {
  stroke: #858796 !important;
}

.news-nav-row {
  padding: 0 128px;
  align-items: center;
}

.news-nav-link {
  color: #858796;
}

.news-nav-link:hover {
  color: black;
}

.news-nav-switch {
  color: #858796;
}

.news-nav-switch .toggle-cards-button {
  font-size: 20px;
  margin-right: 8px;
}

.news-nav-switch .toggle-cards-button:hover {
  cursor: pointer;
  color: black;
}

.news-nav-switch .toggle-cards-button#active {
  color: black;
}

.compact-news-info-container {
  padding: 8px;
  margin: 32px 16px 32px 400px;
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.contents-row {
  width: 100%;
}

.news-feed-row {
  background: orange;
}

.news-card {
  width: 200px;
  height: 300px;
  margin-right: 8px;
  color: #858796;
  margin-bottom: 8px;
  border: 1px solid transparent;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.news-card img {
  height: 125px;
}

.news-card .card-title {
  font-size: 14px;
  padding: 8px;
}

.news-card a {
  color: #858796;
}

.news-card a:hover {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}

.news-card-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  font-style: italic;
}

.news-card-footer svg {
  font-size: 22px;
}

.news-card-footer:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}

.news-trending-topics {
  background: white;
  position: absolute;
  left: 16px;
  min-height: 300px;
  width: 250px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  padding: 8px;
  color: #858796;
}

.news-trending-topics h5 {
  font-size: 18px;
}

.news-trending-topic {
  justify-content: space-between;
  display: flex;
  padding: 4px;
  font-size: 14px;

}

.news-trending-topic:hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: default;
}

.news-trending-topic .trending-name:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}

.news-trending-topic:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.news-no-trending-topics {
  font-size: 14px;
  text-align: center;
  height: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.news-menu-row {
  width: 100%;
  height: max-content;
  margin-top: 8px;
  justify-content: center;
}

.news-menu-control-container {
  display: flex;
  max-width: 1000px;
  width: 100%;
  color: #858796;
  font-size: 14px;
}

.news-menu-control-container .news-menu-item {
  margin-right: 16px;
}

.news-menu-control-container .news-menu-item:hover {
  color: black;
  cursor: pointer;
}

.news-menu-control-container .news-menu-item.icon {
  font-size: 18px;
}

.news-menu-control-container .news-menu-item#active {
  color: black;
}

.news-list-container {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  padding: 8px;
  color: #858796;
  font-size: 14px;
  width: 100%;
  max-width: 1200px;
}

.news-list-item {
  justify-content: space-between;
}

.news-list-item:not(:last-child) {
  padding-bottom: 8px;
}

.news-item-first-half {
  display: flex;
}

.news-item-last-half {
  display: flex;
}

.news-list-item:hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: default;
}

.news-list-item .date-col {
  font-size: 10px;
  width: 80px;
}

.news-list-item .title-col {
  overflow-x: hidden;
}

.news-list-item .title-col a {
  color: #858796;
}

.news-list-item .title-col a:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.news-list-item .publisher-col {
  font-style: italic;
  margin-right: 8px;
  text-align: right;
  font-size: 10px;
}

.common-icon {
  color: #858796;
}

.common-icon:hover {
  cursor: pointer;
  color: black;
}

.pagination-row {
  justify-content: center;
  display: flex;
}

.common-pagination {
  width: 100%;
  justify-content: center;
}

.common-pagination .page-item a {
  border: 1px solid transparent;
  color: #36b9cc;
  font-size: 16px;
}

.common-pagination .page-item a:hover {
  color: black !important;
}

.common-pagination .page-item.active span {
  /* background: #36b9cc; */
  /* border: 1px solid #36b9cc; */
  background: transparent;
  color: #36b9cc;
  font-size: 16px;
  text-decoration: underline;
}

.common-pagination .page-item.active span:hover {
  cursor: pointer;
  color: black;
}

.common-modal {
  color: #858796;
  background: rgba(0, 0, 0, 0.3);
}

.common-modal .modal-content {
  /* padding: 0 10px; */
  border: none;
  border-radius: 2px;
  width: max-content;
  height: max-content;
}

.common-modal .modal-body {
  padding: 1px;
}

.common-modal .app-spinner {
  justify-content: center;
}

.news-modal .modal-body {
  width: 500px;
}

.news-modal-image {
  width: 100%;
  max-height: 300px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.news-modal-content-container {
  padding: 8px;
}

.news-modal-title {
  color: #858796;
  font-weight: bold;
  /* margin-bottom: 16px; */
}

.news-modal-title:hover {
  color: black;
}

.news-modal-pub-date {
  display: flex;
  width: max-content;
  justify-content: space-between;
  color: #a8aaba;
  align-items: center;
  font-size: 12px;
}

.news-modal-publisher {
  font-weight: bold;
}

.news-modal-date {
  font-style: italic;
  margin-left: 4px;
}

.news-modal-content {
  margin-top: 16px;
  font-size: 14px;
  padding-bottom: 32px;
}

.news-modal-read-more {
  position: absolute;
  bottom: 0;
  right: 0;
  font-style: italic;
  color: #a8aaba;
  font-size: 14px;
}

.news-modal-read-more svg {
  font-size: 22px;
}

.news-modal-read-more:hover {
  color: black;
}

.reset-params-icon {
  position: absolute;
  right: 8px;
  color: #858796;
}

.reset-params-icon:hover {
  color: black;
  cursor: pointer;
}

.table-navlink {
  padding: 0;
  color: #858796;
  margin: 0;
}
