.admin-content-pane-container {
  background: #F8F9FC;
  padding: 0;
}

.forms-table-box {
  background: white;
  font-size: 13px;
  padding: 20px;
  position: relative;
}

.forms-header {
  height: max-content !important;
  color: #858796;
  margin-bottom: 24px;
  align-items: center;
}

.admin-table {
  background: white;
  font-size: 13px;
  padding: 20px;
  color: #858796;
}

.submissions-search-bar {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: #858796;
}

.submissions-search-bar label {
  margin: 0;
}

.submissions-search-bar .col:not(:last-child) {
  margin-right: 8px;
}

.submission-form-control {
  color: #858796 !important;
}

.submission-form-control::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.submissions-reset-queries-button {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #858796;
}

.submissions-reset-queries-button:hover {
  color: black;
  cursor: pointer;
}
