.research-homepage-jumbotron {
  padding: 0;
  background: #F8F9FC;
  min-height: 100%;
}

.research-homepage-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.research-homepage-top-row .separation-column {
  margin: 0;
  padding: 0;
  /* background: red; */
}

/************* TRENDING ARTICLES **********************************************/
.research-articles-section {
  margin: 0;
  padding: 0;
  min-height: 10em;
  margin: 0 15px 15px 15px;
}

.research-articles-section .no-reports {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #858796;
  justify-content: center;
}

.discover-loading-spinner-container {
  background: transparent;
  justify-content: center;
  width: 100%;
  display: flex;
  color: #858796;
}

.research-articles-section h4 {
  color: #858796;
  font-size: 24px;
  margin-bottom: 0;
  /* margin-right: 15px; */
}

.research-article-row {
  margin: 0;
  font-size: 14px;
  padding: 10px 20px;
  /* border: 1px solid rgba(0, 0, 0, 0.13); */
  border-radius: 2px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;
  /* height: 150px; */
}

.article-content-title {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.article-content-summary {
  margin: 0;
  padding: 0;
}

.article-image-col {
  justify-content: flex-end;
  display: flex;
  padding: 0;
}

/************* END OF TRENDING ARTICLES ***************************************/
.research-homepage-top-row {
  margin: 0;
  padding: 0;
  padding: 20px;
  justify-content: center;
}

.research-homepage-col {
  border-radius: 2px;
  margin: 0;
  padding: 0;
}

.research-homepage-col .search-bar, .research-homepage-col .search-button {
  margin-top: 20px;
}

.research-homepage-col .render-article-section-pagination {
  border-radius: none;
  justify-content: center;
}

/************* TRENDING TOPICS ******************************************/
.trending-topic {
  border-radius: 2px;
  padding: 2px 10px;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  display: inline-block;
  transition: box-shadow 0.13s;
}

.research-right-col #current-category {
  border: 1px solid white
}

.trending-topic:not(:last-child) {
  margin-right: 20px;
  margin-bottom: 15px;
}

.trending-topic:hover {
  cursor: pointer;
  color: black;
}

.trending-topic-name {
  margin: 0;
  padding: 0;
  /* padding-left: 10px; */
  background: red;
}

.trending-topic-count {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  /* padding-right: 10px; */
  background: purple;
}
/************* END OF TRENDING TOPICS ***********************************/

/************* FEED SECTION ***************************************************/

/************* FEED SECTION ***************************************************/

.research-right-col {
  margin: 0;
}

.research-right-col, .research-left-col {
  align-items: flex-start;
  padding: 0;
  justify-content: flex-start;
}

.research-right-col h4 {
  padding: 0;
  margin: 0;
  padding-bottom: 15px;
  color: #858796;
}

.research-right-col .research-trending-section {
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
}

.research-trending-section .tab-content {
  width: 100%;
  padding-top: 20px;
  display: block;
}

.research-trending-section .tab-content {
}

.research-topics {
  width: 100%;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0;
  margin: 0;
  border-radius: 2px;
  padding: 10px;
  margin-top: 15px;
  color: #858796 !important;
}

.research-topics:not(:last-child) {
  margin-top: 43px;
}

.research-topics-header-row {
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 8px;
  width: 100%;
}

.research-topics-header-row h4 {
  margin: 0;
}

.research-topics-header-row .search-filter-clear-button {
  color: rgba(255, 255, 255, 0.6);
  height: 100%;
  display: block;
  margin-left: auto;
  transition: 0.13s;
}

.research-topics-header-row .search-filter-clear-button:hover {
  cursor: pointer;
  color: #858796;
}

.research-topics .research-search-dropdown-row {
  margin: 0;
  padding: 0;
  width: 100%;
}

.research-topics .research-search-dropdown {
  color: #858796;
  font-size: 14px;
  border: none;
  border-radius: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.research-topics .research-search-dropdown:hover {
  cursor: pointer;
}

.research-topics .research-search-dropdown-row:not(:last-child) {
  margin-bottom: 10px;
}

.research-topics .research-datepicker-label {
  margin: 0;
  padding: 0;
  align-items: flex-start;
  color: #858796;
}

.research-topics .research-datepicker-row {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.research-datepicker-row {
  justify-content: space-between;
  align-items: center;
}

.research-datepicker {
  /* width: max-content; */
  border: none !important;
}

.research-datepicker .react-date-picker__wrapper {
  border: none !important;
}

.research-datepicker .react-date-picker__wrapper .react-date-picker__clear-button {
  stroke: #858796 !important;
}

.research-topics .research-datepicker-row .research-datepicker, .research-topics .research-datepicker-row .research-datepicker input {
  color: #858796;
}

.research-topics .research-datepicker-row:not(:last-child) {
  margin-bottom: 10px;
}

.research-datepicker {
  border: none !important;
}

.research-topics .research-datepicker-col {
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  display: flex;
}

.research-topics h4 {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
}

.research-topics h6 {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.research-see-more {
  font-size: 14px;
  justify-content: flex-end;
  align-items: center;
}

.research-see-more-articles {
  font-size: 14px;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
}

.research-see-more:hover, .research-see-more-articles:hover {
  color: rgba(33, 122, 255, 1);
  cursor: pointer;
  text-decoration: underline;
}

.research-see-more .research-icon, .research-see-more-articles .research-icon {
  margin-right: 5px;
}

.research-search-col {
  /* padding: 10px; */
  margin: 0;
  padding: 0;
  /* padding: 10px; */
}

.research-search-bar {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 14px;
  padding-bottom: 10px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
}

.research-search-bar .col-md-2, .research-search-bar .col-md-10 {
  padding: 0;
}

.research-search-bar .search-bar-form-control {
  width: 85%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: none;
  border-radius: 0;
  color: #858796;
}

.search-bar-form-control::placeholder {
  color: #858796;
}

.research-search-bar .research-search-button {
  height: 31px;
  padding: 0;
  width: 15%;
  font-size: 20px;
  background: transparent;
  color: #858796;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: none;
  border-radius: 0;
  transition: color 0.13s background 0.13s;
}

.research-search-bar .research-search-button:hover {
  color: black !important;
}

.research-search-part {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 16px;
  color: #858796;
  align-items: center;
  user-select: none;
  transition: color 0.13s;
}

.research-search-part:not(:last-child) {
  margin-bottom: 10px;
}

.research-left-col #current-category {
  color: black !important;
}

.research-search-part .research-icon {
  margin-right: 10px;
}

.research-search-part:hover {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.article-content-contributor-image-col {
  margin: 0;
  padding: 0;
  display: inline-block !important;
}

.article-content-contributor-image-div {
  width: 60px;
  height: 60px;
  /* margin-right: 10px; */
}

.article-content-col .contributor-image {
  max-width: 100%;
}

.article-content-col {
  margin: 0;
  padding: 0;
}

.article-content-title-summary-col {
  flex-direction: column;
  display: flex;
}

.article-content-title-row a {
  font-weight: bold;
  font-size: 22px;
  /* color: rgba(0, 0, 0, 0.5); */
}

.article-content-title-row a:hover {
  text-decoration: none;
}

.research-trending-section .payout-tabs {
  width: 100%;
  border: 1px solid transparent;
}

.research-trending-section .payout-tabs a {
  background: transparent !important;
  color: #858796;
  font-weight: normal;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.research-trending-section .payout-tabs a.active {
  background: transparent;
  color: black;
  font-weight: normal;
  border-bottom: none;
}

.research-trending-section .payout-tabs a.active:hover {
  border-bottom: none;
}

.research-trending-section .trending-topics-tabs-col {
  color: #858796;
  padding: 0;
  margin: 0;
}

.trending-topic-tab {
  color: #858796;
}

.no-trending-topics {
  text-align: center;
}

.no-padding {
  margin: 0;
  padding: 0;
}

.article-content-contributor-info-div {
  /* justify-content: flex-start; */
}

.article-content-contributor-info-div #display-name {
  color: black;
  font-size: 14px;
  margin: 0;
}

.article-content-contributor-info-div #date-published {
  margin: 0;
}

.vertically-aligned {
  /* justify-content: center;
  display: flex;
  align-items: center; */
}

.article-content-image-div {
  width: 100%;
}

.article-content-image {
  width: 100%;
  max-height: 100px;
  object-fit: cover;
}

.render-insight-container {
  /* justify-content: space-between; */
  margin: 0;
  padding: 0;
  border-radius: 2px;
  padding: 15px;
  display: flex;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 150px;
  max-height: 300px;
}

.render-insight-container:not(:last-child) {
  margin-bottom: 15px;
}

.insight-contributor-information-container {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.insight-contributor-image-container {
  display: block;
}

.insight-contributor-image-container .contributor-image {
  width: 48px;
  height: 48px;
}

.insight-contributor-name-date-container {
  margin-left: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 20px;
}

.insight-contributor-name-date-container a {
  color: #858796;
  /* font-weight: bold; */
  transition: 0.13s;
  font-size: 12px;
}

.insight-article-information-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.insight-article-title-summary-container {
  display: block;
  margin-right: 20px;
}

.insight-article-title-summary-container .article-title {
  /* font-weight: bold; */
}

.insight-article-title-summary-container a {
  transition: color 0.13s;
  color: #858796;
}

.insight-article-title-summary-container a:hover {
}

.insight-article-title-summary-container .article-summary {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.insight-article-image-container {
}

.insight-article-image-container .default-image {

}

.insight-article-image-container .article-image {
  height: 100%;
  width: 200px;
  object-fit: contain;
}

.rounded-circle{
  border-radius:5% !important;
}

.login-modal {
  background: rgba(0, 0, 0, 0.3);
}

.login-modal .modal-content {
  margin-top: 50%;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #136dbd;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  color: #858796;
  border: 1px solid #1588ee;
}

.login-modal .modal-header {
  padding: 0;
  padding-right: 5px;
  border-bottom: none;
}

.login-modal .modal-body {
  padding: 0 40px 24px 40px;
}

.login-modal .sign-up-button {
  background: transparent;
  border-radius: 0;
  color: #ff6b00;
  font-size: 16px;
  border: 1px solid #ff6b00;
  width: 160px;
  height: 60px;
  font-weight: bold;
  transition: box-shadow 0.13s color 0.13s background 0.13s;
}

.login-modal .sign-up-button:hover {
  border: 1px solid #1588ee;
}

.login-modal .login-modal-header {
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

.login-modal .login-modal-bottom-text {
  margin-top: 10px;
  display: inline-flex;
}

.login-modal .login-link {
  color: #ff6b00;
  margin-left: 4px;
  font-weight: bold;
}

.login-modal .login-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.login-modal .sign-up-button:hover {
  background: rgba(33, 122, 255, 1);
  color: #858796;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.login-modal .modal-body .row {
  justify-content: center;
}

.rdisc-current-category-header-text {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.rdisc-current-category-header-text h4 {
  margin-right: 8px;
}

.rdisc-follow-topic-button {
  padding: 0;
  height: auto;
  max-height: 20px;
  font-size: 10px;
  padding: 0 2px;
  background: transparent;
  color: #858796;
  border: 1px solid #858796;
}

.rdisc-follow-topic-button:hover {
  color: black;
  background: rgba(0, 0, 0, 0.13);
  border: 1px solid rgba(0, 0, 0, 0.13);
}
