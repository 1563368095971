#application-approval {
  background: green;
  border-color: green;
  width: 80px;
}

#application-deny {
  background: red;
  border-color: red;
  width: 80px;
}

.user-list-selected-user-info-row a {
  /* background: red; */
  font-size: 14px;
  text-decoration: underline;
  display: flex;
  align-items: center !important;
  height: 100%;
}
