body::-webkit-scrollbar {
  width: 10px;
  background: rgba(0, 0, 0, 0.5);
}

body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.research-container {
  max-width: 100%;
  height: 100%;
  padding: 0;
}

.research-container .col {
  padding: 0;
}

.research-form-top-bar-container {
  /* position: absolute; */
  /* width: 100%; */
  /* left: 0; */
  /* top: 90px; */
}

.research-form-top-bar-button {

}

.research-form-top-bar-row {
  width: 100%;
  margin: 0;
  /* background: red; */
}

.research-form-saving-col {
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.research-form-buttons-col {
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  display: flex;
}

.research-form-buttons-col#showing-preview {
}

.research-form-top-bar-row .btn-primary {
  margin-right: 10px;
  width: 80px;
  height: 24px;
  padding: 0;
  background: white;
  color: #0182E7;
  border: 1px solid #0182E7;
  border-radius: 2px;
}

.research-form-top-bar-row .btn-primary:hover {
  color: white;
  background: #0182E7;
}

.research-form-top-bar-row .col {
  /* justify-content: center;
  align-items: center;
  display: flex; */
}

.research-top-bar-button {
  height: 30px;
  margin-top: 5px;
  padding: 2px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.research-top-bar-button#preview-button {
  background: transparent;
}

.research-top-bar-button#preview-button:hover {
  background: #1588ee;
}

.research-save-icon {
  margin-right: 5px;
}

.research-form-container {
  padding-top: 90px;
  height: inherit;
}

.research-form-top-row {
  margin: 0;
  padding: 0;
  height: inherit;
}

.research-form-drafts-section {
  background: rgba(0, 0, 0, 0.2);
  /* max-height: 75%; */
  overflow-y: hidden;
}

.user-drafts-section-top-row {
  width: 100%;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.user-drafts-section-top-row .col {
  margin: 0;
  padding: 0;
  justify-content: center;
  display: flex;
}

.user-drafts-section-top-row h4 {
  margin: 0;
  font-weight: bold;
  font-size: 20px;
}

.drafts-create-icon {
  transition: color 0.13s, transform 1s;
  font-size: 14px;
}

.drafts-create-icon:hover {
  color: #0182E7;
  cursor: pointer;
  transform: rotate(360deg);
}

.user-drafts-section-bottom-row {
  width: 100%;
  height: 100%;
  /* background: red; */
}

.user-drafts-col {
  max-height: 50%;
  overflow-y: auto;
}

.user-drafts-col::-webkit-scrollbar {
    width: 5px;
    background: transparent;
}

.user-drafts-col::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
}

.user-drafts-col::-webkit-scrollbar-thumb:hover {
  background: #0182E7;
}

.single-draft {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0px 1px;
  padding: 0px 5px;
  transition: background 0.2s, box-shadow 0.2s;
}

.single-draft:hover {
  background: #0182E7;
  color: white !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.single-draft .draft-col {
  padding: 0;
}

.single-draft-title {
  transition: color 0.2s;
}

.single-draft-title:hover {
  cursor: pointer;
  /* color: orange; */
}

.single-draft-selected {
  padding: 0 5px;
  background: #0182E7;
  color: white !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.single-draft .buttons-col {
  padding: 0;
}

.single-draft .buttons-col .row {
  width: 100%;
  margin: 0;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.single-draft-button {
  margin-left: 10px;
  transition: color 0.2s;
}

.single-draft-button:hover {
  /* color: #0182E7; */
  cursor: pointer;
}

.single-draft-selected {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  /* background: rgba(0, 0, 0, 0.4); */
  /* background: red; */
  margin: 0px 1px;
  /* font-weight: bold; */
}

.single-draft-selected:hover, .single-draft:hover {
  cursor: pointer;
}

.single-draft-selected .draft-col {
  padding: 0;
}

.single-draft-selected .draft-col .single-draft-title {
}

.single-draft-selected .buttons-col {
  padding: 0;
}

.single-draft-selected .buttons-col .row {
  width: 100%;
  margin: 0;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.single-draft-button {
  margin-left: 10px;
  transition: color 0.2s;
}

.single-draft-button:hover {
  cursor: pointer;
}

.research-form-rte-section {
  padding: 0 !important;
  /* background: yellow; */
}

.research-form-criteria-section {
  background: rgba(0, 0, 0, 0.2);
}


.rte-content-container {

}

.rte-content-container .col {
  height: 100%;
  width: 100%;
  padding-top: 40px;
}

.rte-content-container .form-label-container {
   font-weight: bold;
   border-right: 1px solid darkgrey;
   width: 8% !important;
   padding: 0 !important;
   margin: 0 !important;
   padding-right: 10px;
   display: inline-flex;
}

.form-label-container .form-label {
    text-align: right !important;
    display: inline-flex !important;
    align-items: right;
    width: 100% ;
}

.rte-content-container .form-row:last-of-type {
    margin-bottom: 20px;
}

.rte-content-container .form-control {
}

.rte-content-title {
    margin: 0;
    padding: 0;
    font-weight: 400;
    border: none;
    color: black;
    line-height: 48px;
    font-size: 40px;
    height: inherit;
}

.rte-content-title::placeholder {
    font-size: 40px;
    font-weight: normal !important;
    color: darkgrey;
}

.rte-content-title:focus, .rte-content-summary:focus {
    box-shadow: none !important;
}

.rte-content-summary {
    margin: 0;
    border: none;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 300;
    line-height: 32px;
    font-size: 24px;
    height: inherit;
}

.rte-content-summary::placeholder {
    font-size: 32px;
    color: darkgrey;
}

.rte-quill {
  display: inline;
  border: none;
  background: none !important;
  margin: 0 !important;
  width: 100% !important;
  height: 50rem;
}

.ql-editor {
    /* padding: 0 !important; */
    border: none !important;
    height: 100%;
    font-size: 16px;
    /* border-radius: 0 !important; */
    background: white;
}

.ql-editor::-webkit-scrollbar {
  width: 0px;
}

.ql-editor::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.13);
  border-radius: 25px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.ql-container {
  padding: 0 !important;
  margin: 0;
}

.ql-blank {
  background: white;
}

.rte-quill-row {
  padding: 0;
  margin: 0;
  width: 100% !important;
}

.rte-container {
  margin: 0;
  padding: 0;
  justify-content: center;
  display: flex;
}

.rte-container form {
  width: 100%;
}

.rte-title-row {
  width: 100%;
  margin: 0;
  padding: 0;
  padding: 0 10px;
}

.rte-title-row h1 {
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
}

.rte-summary-row {
  width: 100%;
  padding: 0;
  margin: 0;
  padding: 0 10px;
}

.rte-summary-row h2 {
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
}

.rte-summary-row .form-control, .rte-title-row .form-control {
  margin: 0;
  padding: 0;
}

.preview-body-row .rte-quill {
  width: 100%;
  border: none !important;
}

.rte-quill .ql-toolbar {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.rte-quill .ql-container {
  border: none !important;
  height: 90% !important;
  width: inherit !important;
  padding-left: 10px;
}

.rte-container .preview-title-row h1 {
  width: 100%;
}

.rte-container .preview-summary-row h2 {
  width: 100%;
  margin: 0;
  padding: 0;
}

.rte-container .preview-summary-row .form-control {
  padding: 0;
  margin: 0;
}

.rte-container .preview-body-row p {
  width: 100%;
  padding: 0;
}

.research-form-criteria-section {
    background: rgba(0, 0, 0, 0.20);
    display: block;
    z-index: 2;
}

.research-form-criteria .form-control {
    background: #0182E7;
    color: white;
    border-color: #0182E7;
    font-size: 14px;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.research-form-criteria .form-control::placeholder {
    color: rgba(0, 0, 0, 0.20);
}

.research-form-criteria .form-row {
    width: 100%;
    padding: inherit;
}

.research-label {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.research-label-italic {
  font-size: 14px;
  /* font-weight: bold; */
  margin: 0;
  padding: 0;
  font-style: italic;
  color: grey;
}

.research-form-criteria .research-tags {
    /* height: 116px; */
}

.research-content-label {
    display: inline-block;
    text-align: right;
}

.react-tagsinput {
  /* overflow: hidden; */
  padding-left: 5px;
  padding-top: 5px;
  background: white;
  border-radius: 2px;
  /* height: 38px; */
}

.react-tagsinput-tag {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
  border-radius: 2px;
  color: white;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0 4px;
}

.react-tagsinput .react-autosuggest__suggestions-container {
}

.react-tagsinput .react-autosuggest__suggestions-container ul {
  list-style-type: none;
  padding: 0;
}

.react-tagsinput .react-autosuggest__suggestions-container li {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
  border-radius: 2px;
  color: white;
  display: inline-block;
  padding: 5px;
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput .react-autosuggest__suggestions-container li::after {
  content: " +";
}

.react-tagsinput .react-autosuggest__suggestions-container li:hover {
  cursor: pointer;
}



.react-tagsinput-remove {
  cursor: pointer;
}

.react-tagsinput-tag a::before {
  content: " x";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  /* width: 80px; */
}

.research-form-tags-column {
}

.research-form-tags-container {
    height: inherit;
    /* max-height: 100px; */
    border: 1px solid #0182E7;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* overflow-y: scroll; */
}

.image-uploader {
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.article-image-uploader {
  display: none;
}

.uploaded-image-div {
  /* background: red; */
}

.no-article-image {
  /* background: orange; */
  height: 100px;
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.15s, color 0.15s, border 0.15s;
}

.no-article-image:hover {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0);
  color: white;
  cursor: pointer;
}

.uploaded-image-container {
  display: flex;
}

.uploaded-article-image {
  height: 100%;
  max-height: 200px;
  max-width: 200px;
  border-radius: 2px;
}

.md-cancel:hover {
  cursor: pointer;
}

.research-form-top-bar-buttons-row {
  padding: 0;
  margin: 0;
  width: 100vw;
  padding-bottom: 5px;
}

.research-form-top-bar-buttons-row#showing-preview {
  background: #062846;
}

.research-form-top-bar-buttons-row .col-sm-2 {
  padding: 0;
  margin: 0;
}

.research-form-top-bar-buttons-col {
  padding: 0;
  margin: 0;
  margin-right: 25px;
}

.no-document {

}

.saving-message {
  color: grey;
  font-style: italic;
}

.saved-message {
  color: grey;
}

.uploaded-report-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: cover;
  border-radius: 2px;
}

.publishing-article-modal {
  background: rgba(0, 0, 0, 0.5);
  padding-top: 15%;
}

.publishing-article-modal .modal-content {
  border: none;
  border-radius: 2px;
  text-align: center;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

.rte-no-drafts {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.rte-no-drafts .row {
  align-items: center;
  display: flex;
}

.rte-no-drafts-button {
  height: 60px;
  background: transparent;
  border: 1px solid #0182E7;
  color: #0182E7;
}

.rte-no-drafts-button:hover {
  cursor: pointer;
  color: white;
  border: 1px solid #0182E7;
  background: #0182E7;
}

.rte-no-drafts-add-icon {
  font-size: 20px;
}
