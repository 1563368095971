.every-team-info {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 15px;
}
.document-uploader {
  background-color: #007bff;
  color: white;
  width: 16%;
  text-align: center;
  border-radius: 5%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.document-uploader:hover {
  cursor: pointer;
}

.token-form-sub-section {
  padding: 10px;
  background: rgba(0, 0, 0, 0.17);
  margin-bottom: 10px;
}

.documentDropZone {
  border: 1px dashed blue;
  min-height: 100px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: background 0.15s, color 0.15s;
}

.documentDropZone:hover {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
}

.documentDropZone p {
  margin: 0;
}
.form-content .btn {
  margin-bottom: 10px;
}

.admin-portal-content p {
  text-align: center;
}

.add-team-button {
  margin-bottom: 15px;
}
