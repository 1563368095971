html {
  scroll-behavior: smooth;
}

.app-content {
  font-family: 'Nunito', sans-serif;
  background: #F8F9FC;
  padding: 0 !important;
}

.app-container {
  padding: 0;
  height: 100%;
  min-height: 100%;
}

.app-row {
  height: 100%;
}

.content-pane {
  height: 100vh;
  /* overflow-y: scroll; */
  z-index: 50;
  position: relative;
}

.content-container {
  padding: 0;
}

.content-pane-container {
  height: 100%;
  padding: 0;
  background: #F8F9FC;
}

.render-loading {
  background: red;
  width: 100% !important;
}

.render-spinner {
  width: 100px;
  height: 100px;
  color: #858796 !important;
}

.test-row {
  justify-content: center;
  height: 100%;
  align-items: center;
}

.extra-small-button {
  font-size: 10px;
  height: 16px;
  padding: 2px 4px;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.teal-button {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
}

.teal-button:hover {
  background: #2c9faf !important;
  border: 1px solid #2a96a5 !important;
  color: black !important;
}

.delete-button {
  background-color: Tomato !important;
  border: 1px solid Tomato !important;
}

.delete-button:hover {
  background-color: FireBrick !important;
  border: 1px solid FireBrick !important;
  color: black !important;
}

#true-content {
  color: MediumSeaGreen !important;
}

#false-content {
  color: red !important;
}

.hidden {
  display: none;
}

.td-row {
  justify-content: space-between;
}

.td-row .individual-form-icon:not(:last-child) {
  margin-right: 4px;
}

.individual-form-icon {
  font-size: 16px;
  color: #858796;
}

.individual-form-icon:hover {
  color: black;
  cursor: pointer;
}

.individual-form-icon-disabled {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 8px;
}

.admin-container {
  padding: 0;
}

.admin-content-pane {
  height: 100vh;
  /* margin-top: -70px; */
  /* padding-top: 4.375rem; */
  overflow-y: scroll;
  z-index: 2;
  position: relative;
}

.admin-content-container {
  padding: 24px !important;
}

.return-to-header {
  align-items: center;
  display: flex;
}

.return-to-header:hover {
  cursor: pointer;
  color: black;
}

.admin-modal {
  color: #858796;
  background: rgba(0, 0, 0, 0.3);
  font-family: 'Nunito', sans-serif;
}

.admin-modal .modal-content {
  /* padding: 0 10px; */
  border: none;
  border-radius: 2px;
}

.confirm-delete-modal-row {
  align-items: center;
  justify-content: center;
  /* max-width: 1000px; */
}

.confirm-delete-modal-row button:not(:last-child) {
  margin-right: 8px;
}

.confirm-delete-modal-row:not(:last-child) {
  margin-bottom: 16px;
}

.template-edit-form {
  color: #858796;
}

.template-edit-form-group {
  align-items: center;
  justify-content: space-between;
  background: red;
}

.template-edit-div {
  width: max-content !important;
  background: orange;
}

.template-edit-form-label {
  /* position: absolute; */
  margin: 0;
  margin-right: 8px;
}

.template-edit-form-control {
  max-width: 400px;
}

.admin-edit-content-row {
  color: #858796;
  align-items: center;
}

.admin-edit-content-row:not(:last-child) {
  margin-bottom: 8px;
}

.admin-edit-content-col {
  margin-right: 8px;
}

.submission-form-control-label {
  color: #858796;
  margin: 0;
  width: 100%;
}

.admin-edit-content-section {
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  padding: 8px;
}

.admin-edit-individual-field {
  color: #858796;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
  padding: 4px;
}

.configure-button {
  margin-right: 8px;
}

.admin-edit-configure-modal-header {
  width: 100%;
  justify-content: flex-end;
}

.admin-edit-configure-modal-header button {
  margin-left: 8px;
}

.admin-edit-content-subsection {
  margin-bottom: 4px;
  align-items: center;
}

.no-margin {
  margin: 0 !important;
}

.ap-edit-row:not(:last-child) {
  margin-bottom: 8px;
}

.ap-edit-row .col:not(:last-child) {
  margin-right: 8px;
}

.ap-edit-image-container {
  position: relative;
  padding: 8px;
}

.ap-image {
  max-width: calc(100% - 8px);
}

.ap-image-cancel-icon {
  position: absolute;
  font-size: 16px;
  right: 0;
  color: #858796;
}

.ap-image-cancel-icon:hover {
  color: black;
  cursor: pointer;
}

.ap-edit-formfile-container {
  padding-bottom: 19px;
  padding-right: 8px;
  justify-content: center;
  width: 280px;
  height: 100%;
}

.ap-edit-formfile-interior {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 4px;
}

.ap-edit-formfile {
  width: max-content !important;
}

.common-typeahead input {
  color: #858796 !important;
  height: 31px;
  font-size: 14px;
}

.common-typeahead input:focus {
  color: #858796;
}

.common-typeahead .rbt-input-hint input {
  display: none;
}

.rbt-input-main::placeholder {
  color: rgba(0, 0, 0, 0.3)!important;
}

.common-typeahead .rbt-menu .dropdown-item {
  color: #858796 !important;
  font-size: 14px;
}

.ap-edit-content-section {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 8px;
  background: rgba(0, 0, 0, 0.1);
}

.ap-edit-form-info-row {
  margin-bottom: 8px;
}

.ap-edit-publish-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.ap-edit-tabs {
  display: flex;
}

.ap-edit-tab {
  color: #858796;
  padding: 2px 8px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 14px;
}

.ap-edit-tab:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.ap-edit-tab#current-tab {
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid transparent;
}

.ap-offering-details-row {
  margin-bottom: 12px;
}

.ap-formgroup {
  color: #858796;
}

.ap-edit-section-label {
  color: #858796;
  font-weight: bold;
  font-size: 16px;
}

.ap-edit-section-row {
  align-items: center;
  justify-content: space-between;
}

.ap-edit-required-info {
  font-style: italic;
  color: rgba(0, 0, 0, 0.3);
}

.common-date-picker {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-left: 6px;
  height: 31px;
  background: white;
}

.common-date-picker.invalid {
  border: 1px solid red;
}

.common-date-picker .react-date-picker__wrapper {
  border: none;
  border-radius: 2px;
}

.common-date-picker .react-date-picker__inputGroup input {
  color: #858796 !important;
}

.common-date-picker
  .react-date-picker__clear-button svg,
    .common-date-picker
        .react-date-picker__calendar-button svg {
  stroke: #858796 !important;
}

.common-date-picker
  .react-date-picker__clear-button svg:hover,
    .common-date-picker
        .react-date-picker__calendar-button svg:hover {
  stroke: black !important;
}

.common-date-picker .react-calendar {
  position: absolute;
  z-index: 100 !important;
}

.registration-display-image-container {
  width: 100%;
  position: relative;
}

.registration-display-image {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
}

.registration-display-image-cancel-icon {
  position: absolute;
  font-size: 16px;
  right: 0;
  color: #858796;
}

.registration-display-image-cancel-icon:hover {
  color: black;
  cursor: pointer;
}

.ap-subfield-row {
  margin-bottom: 8px;
}

.registration-form-file-control {
  width: 100%;
  position: relative;
  z-index: 0 !important;
}

.registration-form-file-control label {
  color: rgba(0, 0, 0, 0.3);
}

.ap-image-uploader input {
  height: 100% !important;
  background: red;
}

.justify-right {
  justify-content: flex-end !important;
}
