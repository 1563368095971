.preview-title-row {
    margin: 0;
    margin-top: 10px;
    /* background: orange; */
}

.preview-title-row h1 {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.84);
    line-height: 48px;
    margin: 0;
    margin-bottom: 12px;
}

.preview-summary-row {
    margin: 0;
    /* background: red; */
}

.preview-summary-row h2 {
    margin: 0;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 300;
    line-height: 32px;
    font-size: 24px;
}

.preview-user-row {
    padding: 0 !important;
    margin: 0;
    margin-bottom: 40px;
    /* background: purple; */

}

.preview-user-image-col {
    padding: 0 !important;
}

.preview-user-image {
    height: 48px;
    width: 48px;
}

.preview-user-info-col {
    padding: 0 !important;
    margin-left: -15px;
}

.preview-name-row {
    height: 24px;
    font-size: 14px;
}

.preview-date-row {
    height: 24px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.preview-body-row {

    margin: 0;
}

.render-article-body-row {
  display: block !important;
}

.render-article-body-row p {
  margin-bottom: 0;
}


.render-article-body-row p:empty {
  margin: 16px;
  background: green;
}

#preview-article-container {
  margin: 0;
  height: 100%;
}

.render-article-name-row p {
  color: rgba(255 ,255, 255, 0.6);
}

.preview-article-row {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100%;
  background: red;
}
