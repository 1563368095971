.collections-container {
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  padding: 0;
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
}

.collections-container-top-row {
  height: 100%;
}

.collections-spinner-row {
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
}

.collections-spinner {
  color: #1586eb;
  width: 80px;
  height: 80px;
}

.collections-sidebar-column {
  margin: 0;
  padding: 0;
  background: #073966;
  padding-top: 93px;
  height: 100%;
  max-width: 15%;
  transition: max-width 0.5s ease-in-out;
}

.collections-sidebar-column#closed-sidenav {
  max-width: 3%;
  transition: max-width 0.5s ease-in-out;
}

.collections-sidebar {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  border-right: 1px solid #1063ad;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.collections-sidebar#open-sidenav .list-group-item {
  visibility: visible;
  opacity: 1;
  transition: opacity 1.2s ease-in;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

.collections-sidebar#closed-sidenav .list-group-item:not(.collections-sidebar-button-row) {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s ease-in, opacity 0.1s ease-in;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
}


.collections-sidebar-button-row {
  justify-content: flex-end;
  display: flex;
}

.collections-sidebar-button-row:hover {
  background: transparent !important;
  cursor: default !important;
}

.collections-sidebar-button:hover {
  cursor: pointer;
  color: white;
}

.collections-sidebar-folder {
  overflow-y: scroll;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.collections-sidebar-folder#open-collection {
  max-height: 500px;
  transition: max-height 0.5s ease-in-out;
}

.collections-sidebar-folder::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0.2);
}

.collections-sidebar-folder::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 20px;
}

.collections-title-row {
  align-items: center;
}

.collections-sidebar-icon {
  margin-right: 10px;
  height: 100%;
}

.collections-sidebar-add-column {
  align-items: center;
}

.collections-sidebar-add {
  margin: 0;
}

.collections-sidebar-add:not(:last-child) {
  margin-right: 10px;
}

.collections-sidebar-arrow {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -moz-transition:    -moz-transform 0.5s ease-in-out;
  -o-transition:      -o-transform 0.5s ease-in-out;
  -ms-transition:     -ms-transform 0.5s ease-in-out;
  transition:         transform 0.5s ease-in-out;
}

.collections-sidebar-arrow:hover {
  color: white;
}

.collections-sidebar-arrow#open-collection {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.collections-sidebar-add:hover {
  cursor: pointer;
  color: white;
}

.collections-sidebar .list-group-item {
  color: #1586eb;
  background: transparent;
  border: 1px solid transparent;
}

.collections-sidebar .list-group-item:hover {
  background: rgb(16, 99, 173, 0.5);
  cursor: pointer;
}

.collections-sidebar-item-my-collections {
  border-bottom: 1px solid #1063ad !important;
}

.collections-sidebar-item-my-collections:hover {
  /* cursor: default !important; */
  /* background: transparent !important; */
}


.collections-sidebar .active {
  background: rgb(16, 99, 173, 0.8);
  color: #58b0ff;
  font-weight: bold;
}

.collections-sidebar-collection-item {
  border-left: 1px solid #1063ad !important;
  margin-left: 20px !important;
}

.collections-sidebar-collection-item .row {
  align-items: center;
}

.collections-sidebar-item-discover {
  margin-bottom: 20px;
}

.collections-sidebar-item-discover:hover {
  cursor: pointer;
}

.collections-sidebar-no-collections {
  border-left: 1px solid #618bb1 !important;
  margin-left: 20px !important;
  color: #618bb1 !important;
}

.collections-sidebar-no-collections:hover {
  background: transparent !important;
  cursor: default !important;
}

.collections-content-column {
  padding: 0;
  padding-top: 93px;
  height: 100%;
}

.collections-info {
  margin: 0;
  color: white;
  background: #0a4478;
  /* height: 180px; */
  padding: 15px 0;
  /* overflow-y: scroll; */
}

.collections-info-icons-column {
  justify-content: flex-end;
  display: flex;
}

.collections-info-image-column {
  justify-content: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.collections-info-image-column img {
  border: none;
}

.collections-info-image {
  background: #093965;
  width: 100%;
  max-width: 100%;
  max-height: 150px;
  height: 150px;
  object-fit: cover;
}

.collections-info-image-no-data {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  background: #093965;
  /* max-width: 260px;
  width: 260px; */
  width: 100%;
  max-width: 100%;
  height: 150px;
  object-fit: cover;
  border: 1px solid transparent;
}

.collections-info-image-no-data p {
  margin: 0;
}

.collections-info-image-no-data:hover {
  text-decoration: underline;
  cursor: pointer;
  border: 1px solid #1586eb;
}

.collections-info-image-static {
  background: #093965;
  /* max-width: 260px;
  width: 260px; */
  width: 100%;
  max-width: 100%;
  height: 150px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.collections-info-image-icon {
  padding: 0;
  margin: 0;
  font-size: 40px;
}

.collections-info-data-column {
}

.collections-info-data-row {
  height: 55%;
}

.collections-info-name-row {
  height: 50%;
  align-items: center;
  font-size: 22px;
}

.collections-info-privacy-column {
  align-items: center;
  justify-content: center;
  display: flex;
}

.collections-details {
  color: white;
  margin: 0;
}

.collections-details-data-column {
  overflow-y: hidden;
}

.collections-details-title {
  justify-content: center;
  color: #1586eb;
  margin-bottom: 10px;
  border-bottom: 1px solid #1586eb;
  font-size: 14px;
}

.collections-details-items {
  justify-content: center;
  overflow-y: hidden;
}

.collections-detail-individual-item {
  background: #1588ee;
  background-image: linear-gradient(to top, #052a41, #0c5ebe);
  padding: 2px 8px;
  font-size: 11px;
  border-radius: 25px;
  /* margin-top: 2px; */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.collections-detail-individual-item:not(:last-child) {
  margin-right: 5px;
  /* margin-top: 10px; */
}

.collections-privacy-icon-no-functionality {
  margin-right: 10px;
  color: #1586eb;
}

.collections-privacy-icon {
  margin-right: 10px;
  color: #1586eb;
}

.collections-privacy-icon:hover {
  cursor: pointer;
  color: white;
}

.collections-privacy-icon#no-functionality:hover {
  cursor: default !important;
  color: #1586eb !important;
}

.collections-edit-icon {
  color: #1586eb;
}

.collections-edit-icon:not(:last-child) {
  margin-right: 10px;
}

.collection-no-data-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.collection-no-data-image {
  background: #093965;
  width: 100%;
  max-width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.collection-no-data-image p {
  display: flex;
  align-items: center;
  margin: 0;
}

.collection-no-data-image:hover {
  text-decoration: underline;
  cursor: pointer;
}

.edit-collection-form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #1586eb;
  border-radius: 0;
  caret-color: white;
  color: white;
  font-size: 22px;
  padding-left: 0;
}

.edit-collection-form-control input {
  color: white;
}

.edit-collection-form-control:focus {
  background: transparent;
  color: white;
}

.edit-collection-form-control::placeholder {
  color: #1586eb;
}

.edit-collection-form-control-textarea {
  position: absolute;
  bottom: 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid #1586eb;
  border-radius: 0;
  caret-color: white;
  height: 50%;
  max-height: 50%;
  min-height: 50%;
  color: white;
  resize: none;
  padding-left: 0;
  padding-top: 0;
}

.edit-collection-form-control-textarea::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.edit-collection-form-control-textarea::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 20px;
}

.edit-collection-form-control-textarea:focus {
  background: transparent;
  color: white;
}

.edit-collection-form-control-textarea::placeholder {
  color: #1586eb;
}

.edit-collection-form-control-textarea input {
  color: white;
}

.collections-info-edit-icons-row {
  height: 100%;
}

.edit-collection-submit-button-column {
  justify-content: center;
  display: flex;
  align-items: center;
}

.edit-collection-submit-button {
  border-radius: 2px;
  background: transparent;
  color: #1586eb;
  border: 1px solid #1586eb;
  font-size: 12px;
  height: 30px;
}

.edit-collection-submit-button:hover {
  background: #1586eb;
  border: 1px solid #1586eb;
}

.collections-edit-icon:hover {
  cursor: pointer;
  color: white;
}

.edit-collection-image-clear-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 18px;
  border-radius: 100px;
  transition: background 0.2s;
}

.edit-collection-image-clear-button:hover {
  background: #1586eb;
  cursor: pointer;
}

.edit-collections-image-uploader .fileContainer {
  background: #093965;
  max-width: 100%;
  width: 100%;
  height: 150px;
  color: white;
  border-radius: 2px;
  padding: 0;
  margin: 0;
}

.edit-collections-image-uploader p {
  padding: 0 10px;
}

.edit-collections-image-uploader .chooseFileButton {
  border-radius: 2px;
  background: transparent;
  color: #1586eb;
  border: 1px solid #1586eb;
  font-size: 12px;
}

.edit-collections-image-uploader .chooseFileButton:hover {
  background: #1586eb;
  border: 1px solid #1586eb;
  color: white;
}

.collections {
  margin: 0;
  color: white;
  height: 100%;
  padding: 12px 0px;
  padding-bottom: 180px;
}

.collections-card-columns {
  width: 100%;
  column-count: 5;
}

.no-reports-in-collection {
  width: 100%;
  height: inherit;
  padding-top: 100px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.collection-card {
  border-radius: 2px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-height: 420px;
}

.collection-card-image {
  max-height: 220px;
  object-fit: cover;
}

.collection-card-description {
  max-height: 100px;
  overflow-y: scroll;
  font-size: 12px;
}

.collection-card-description::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.collection-card-description::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 20px;
}

.collection-card-name a {
  color: white;
}

.collection-card-contributor-info-row {
  align-items: center;
  color: #1588ee;
}

.collection-card-contributor-info-row a {
  color: #1588ee;
  font-size: 14px;
}

.collection-card-contributor-image {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 15px;
}

.collection-card-delete-icon {
  color: #1588ee;
  z-index: 100px;
}

.collection-card-delete-icon:hover {
  color: white;
  cursor: pointer;
}

.collections-delete-report-popover {
  background: #1063ad;
  background-image: linear-gradient(to top, #136dbd, #083f70);
  padding: 20px;
  width: 300px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.collections-delete-report-header {
  font-size: 14px;
  color: white;
}

.collections-delete-report-popover-button-row {
  justify-content: center;
  margin-top: 20px;
}

.collections-delete-report-popover-confirm-button {
  background: transparent;
  border: 1px solid #1588ee;
  color: #1588ee;
  border-radius: 2px;
}

.collections-delete-report-popover-confirm-button:hover {
  background: #1588ee;
  border: 1px solid #1588ee;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.collections-delete-icon-div {
  /* height: 0; */
  top: 0;
}

.collections-delete-collection-popover {
  background: #1063ad;
  background-image: linear-gradient(to top, #136dbd, #083f70);
  padding: 10px;
  width: 200px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  font-size: 12px;
}

.collections-delete-collection-header {
  display: flex;
  text-align: center;
  margin-bottom: 10px;
}

.collections-delete-collection-popover-button-row {
  justify-content: center;
}

.collections-delete-collection-popover-confirm-button {
  background: transparent;
  border: 1px solid #1588ee;
  color: #1588ee;
  border-radius: 2px;
}

.collections-delete-collection-popover-confirm-button:hover {
  background: #1588ee;
  border: 1px solid #1588ee;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.collections-please-wait-modal {
  padding-top: -93px;
  background: rgba(0, 0, 0, 0.3);
}

.collections-please-wait-modal .modal-content {
  color: white;
  background: #1063ad;
  background-image: linear-gradient(to top, #136dbd, #083f70);
  padding: 20px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.collections-please-wait-modal-header {
  justify-content: center;
  margin-bottom: 20px;
}

.collections-please-wait-modal-button-row {
  justify-content: center;
}

#publicAndPrivateTooltip {
  background-color: black;
  opacity: 1;
}

#publicAndPrivateTooltip::after {
  border-left-color: black;
}
