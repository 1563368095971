.traded-symbols-card {
  padding: 8px !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.traded-symbols-card:hover {
  background: rgba(0, 0, 0, 0.05);
}

.traded-symbols-table-row {
  width: 100%;
}
