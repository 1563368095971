.contained-width {
  width: 200px;
}

.center-aligned {
  align-items: center;
}

.website-config-label {
  color: #858796;
  margin: 0;
  margin-right: 16px;
}
