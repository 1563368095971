
.token-listed-container {
  height: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  margin-top: 90px;
  background: #064580; /* Old browsers */
  background-image: linear-gradient(to top, #031729, #073966);
}

.token-listed-container-2 {
  height: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  margin-top: 90px;
  background: #064580; /* Old browsers */
  background-image: linear-gradient(to top, #073966, #031729);
}

.top-bar-jumbotron {
  background-image: url('../../images/ResearchApplication/splash-image.png');
  background-size: auto;
  background-color: transparent;
  left: 10%;
  display: block;
  overflow: auto;
  border-radius: 0;
  /* height: 60%; */
  width: 100vw;
  max-width: 100vw;
  /* -webkit-clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%); */
  /* z-index: -99 !important; */
  position: absolute;
}

.token-listed-header-row .header-text {
  z-index: 2;
}

.header-text h3 {
  font-size: 40px;
  font-weight: bold;
  color: #60abed;
}

.header-text .last-item-in-this-list {
  margin-bottom: 20px;
}

.header-text h4 {
  color: white;
}

.header-text p {
  color: white;
  font-size: 16px;
  font-weight: normal;
}

.token-listed-header-row {
  margin-top: 90px;
  padding-top: 60px;
  margin-bottom: 60px;
}

.token-listed-form-box {
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  padding: 20px 20px;
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 14px;
  border-radius: 2px;
  color: white;
  /* color: #1963a3; */
}

.token-listed-form-box .form-control {
  height: 28px;
  border: none;
  background: transparent;
  border-bottom: 1px solid #1588ee;
  border-radius: 0;
  font-size: 14px;
}

.token-listed-form-box input, .token-listed-form-box select {
  color: white !important;
}

.token-listed-form-box option {
  background: #083f70;
}

.token-listed-form-box .row {
  justify-content: center;
}

.token-listed-form-box .submit-button {
  background: transparent;
  color: #1588ee;
  border: 1px solid #1588ee;
  width: 100px;
  border-radius: 2px;
}

.token-listed-form-box .submit-button:hover {
  background: #1588ee;
  color: white;
}

.token-listed-container .form-row {
  position: absolute;
  top: 154px;
  right: 25%;
  z-index: -100;
}

.token-listed-bottom-row {
  margin-top: 90px;
  padding-bottom: 40px;
}

.token-listed-bottom-row h4 {
  color: #1963a3;
  font-weight: bold;
}

.token-listed-bottom-row li {
}

.token-listed-bottom-row ul {
  margin: 0;
  padding: 0;
  padding-left: 15px;
  margin-bottom: 5px;
  color: white;
}

.token-listing-image {
  width: 150px;
  height: 150px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.token-listed-top-row-text {
  margin-bottom: 20px;
}
